import { User } from 'src/interfaces/auth'
import { ICommonGetParams, ICommonGetSuccess } from './app'
import {
  ETeacherRequestStatus,
  TResponseData,
} from 'src/pages/teacher-request-detail/fill-in-teacher-information/types'
import { ERequestStatus } from 'src/interfaces/teacher-request-management'
import { ERequest } from '@configs'

export interface ICourseParams extends ICommonGetParams {
  status?: string | number
  type?: string | number
  isDrafted?: boolean
  isCheckRequest?: boolean
}

export interface IGetCourseSuccessData extends ICommonGetSuccess {
  totalHighlight: number
  items: ICourseItem[]
}
export enum ELessonType {
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  ASSIGNMENT_TEST = 'ASSIGNMENT_TEST',
  ASSIGNMENT_EXERCISE = 'ASSIGNMENT_EXERCISE',
  FINAL_TEST = 'FINAL_TEST',
}
export interface ICourseItem {
  id?: number
  title: string
  description: string
  userId: number
  type: string
  eurPrice: number
  usdPrice: number
  difficulty: string
  isActived: boolean
  isDrafted: boolean
  deletedAt: string
  isTrial: boolean
  thumbnailId: number
  demoVideoId: number
  hasCertificated: boolean
  user: User
  categories: Category[]
  thumbnail: Thumbnail
  isHighlighted: boolean
  haveInactiveRequest?: boolean
  haveActiveRequest?: boolean
}

export interface Category {
  id: number
  name: string
}

export interface SubCategory {
  id: number
  name: string
}

export interface IResponseUploadThumbnail {
  dataValues: Thumbnail
  _previousDataValues: Thumbnail
  uniqno: number
  _changed: Changed
  _options: Options
  isNewRecord: boolean
  url: string
  baseUrl: string
  message: string
  id: number
  original?: string
}

export interface Changed {}

export interface Options {
  isNewRecord: boolean
  _schema: null
  _schemaDelimiter: string
}

export interface Thumbnail {
  id: number
  userId: number
  bucket: string
  path: string
  type: string
  createdAt: string
  updatedAt: string
  url: string
  cloudfrontUrl: string
}

export interface IResponseGetCourseDetail {
  id?: number
  title: string
  description: string
  titleInDutch: string
  descriptionInDutch: string
  userId: number
  type: string
  eurPrice: number
  usdPrice: number
  difficulty: string
  isActived: boolean
  isDrafted: boolean
  isTrial: boolean
  thumbnailId: number
  demoVideoId: number
  hasCertificated: boolean
  user: User
  categories: Category[]
  subCategories: Category[]
  thumbnail: Thumbnail
  demoVideo: Thumbnail
  nationalLanguage: string
  subTitle?: string | null
  seoConfig: SeoConfig
  hasFinalTest: boolean
  targetUser: boolean
  isBestSeller: boolean
  packages: {
    id: number
    name: string
    nameInDutch: string
  }[]
  userCreationFrom: string
  userCreationTo: string
  requests: IRequest[]
}

export interface IRequest {
  id: number
  status: ETeacherRequestStatus
  responseData: TResponseData
  type: ERequest
}

export interface SeoConfig {
  slug: string
}

export interface IAddNewCourse {
  title: string
  description: string
  titleInDutch: string
  descriptionInDutch: string
  userId: number | undefined
  type: string
  eurPrice: number
  usdPrice: number
  difficulty: string
  isActived?: boolean
  isDrafted?: boolean
  // isTrial: boolean
  thumbnailId: number
  demoVideoId: number
  // hasCertificated: boolean
  user: User
  categories: Category[]
  subCategories: SubCategory[]
  categoriesIds: number[]
  subCategoriesIds: number[]
  slug: string
  nationalLanguage: string
  subTitle?: string | null
  hasFinalTest: boolean
  targetUser: boolean
  isBestSeller: boolean
  packageIds: number[]
  userCreationFrom: string
  userCreationTo: string
}

export interface IResponseCreateCourse {
  message: string
  newCourse: {
    id?: number
    title: string
    description: string
    userId: number
    type: string
    eurPrice: number
    usdPrice: number
    difficulty: string
    thumbnailId: number
    demoVideoId: number
    categories: Category[]
    subCategories: Category[]
    seoConfig: SeoConfig
    isActived?: boolean
    isDrafted?: boolean
    nationalLanguage: string
    hasFinalTest: boolean
    targetUser: boolean
    packages: {
      id: number
      name: string
      nameInDutch: string
    }[]
    userCreationFrom: string
    userCreationTo: string
    isBestSeller: string
    subTitle: string
  }
}
export interface ILesson {
  id: number
  title: string
  type: ELessonType
  isTrial: boolean
}
export interface ICourseSections {
  title: string
  description: string
  titleInDutch: string
  descriptionInDutch: string
  isFinal: boolean
  id: number
  lessons: ILesson[]
}

export interface ITrailLesson {
  id: number
  isTrial: boolean
}

export interface ICourseLessons {
  title: string
  description: string
  id: number
}

export enum EMessageType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  FILE = 'FILE',
}

export interface IMedia {
  id: number
  cloudfrontUrl: string
  url: string
  userId: number
  bucket: string
  path: string
  type: string
  name: string
  category: string
  createdAt: string | null
  updatedAt: string | null
}

export interface IMessageUser {
  id: number | string
  name: string
  profilePhoto: IMedia
}

export interface IMessageContent {
  userId: number | string
  roomId: number | string
  type: EMessageType
  content: string
  isRead: boolean
  isSystem: boolean
  courseId: number | string
  createdAt: string | null
  user: IMessageUser
  media: IMedia
  isSendingMessage?: boolean
  tempId?: number | string
}

export interface IListMessageWithUser {
  data: IMessageContent[]
  isLoading: boolean
  total: number
}

export interface IUploadAttachmentData {
  baseUrl: string
  cloudfrontdUrl: string
  dataValues: {
    id: number | string
    type: string
  }
}

export interface IMessageAttachmentData {
  data: IUploadAttachmentData | {}
  isLoading: boolean
}

export interface ICourseMiniDashboard {
  totalCourses: number
  totalPublishedCourses: number
  totalInactiveCourses: number
  totalInactiveAndActiveRequests: number
}
