import styled from 'styled-components'

export const TableTitleWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CommonTeacherContentWrapperStyled = styled.div`
  background: white;
  padding: 20px;
  padding-top: 30px;
`
