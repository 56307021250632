import { DatePicker } from 'antd'

export enum ETeacherOverviewTable {
  RANK = 'Rank',
  NAME = 'Name',
  FROM = 'From',
  TOTAL_REVENUE = 'Total Revenue',
}

export const ERegisterTeacherTable = {
  RANK: 'Rank',
  NAME: 'Name',
  PACKAGE: 'Package',
  TOTAL_REVENUE: 'Total Revenue',
}

export enum EOverviewDateFilter {
  ALL_TIME = 'allTime',
  LAST_MONTH = 'lastMonth',
  LAST_SIX_MONTHS = 'lastSixMonths',
  LAST_YEAR = 'lastYear',
  CUSTOM = 'custom',
}

export const FILTER_OVERVIEW_DATA = [
  {
    label: 'All time',
    value: EOverviewDateFilter.ALL_TIME,
  },

  {
    label: 'Last month',
    value: EOverviewDateFilter.LAST_MONTH,
  },
  {
    label: 'Last 6 months',
    value: EOverviewDateFilter.LAST_SIX_MONTHS,
  },
  {
    label: 'Last year',
    value: EOverviewDateFilter.LAST_YEAR,
  },
]

export declare type EventValue<DateType> = DateType | null
export declare type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null

export const { RangePicker } = DatePicker
