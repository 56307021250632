import { Fragment } from 'react'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import styled from 'styled-components'
import {
  FieldError,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form'

import Editor from 'src/components/editor/Editor'
import { useSettings } from 'src/components/editor/context/SettingsContext'
import PlaygroundNodes from 'src/components/editor/nodes/PlaygroundNodes'
import PlaygroundEditorTheme from 'src/components/editor/themes/PlaygroundEditorTheme'
import { LEXICAL_EDITOR_P_TAG, VALID_EDITOR_REGEX } from '@configs'

interface ICourseDescriptionEditor {
  setValue: UseFormSetValue<any>
  clearErrors: UseFormClearErrors<any>
  setError: UseFormSetError<any>
  name: string
  value: string
  disabled?: boolean
  error?: FieldError
}

const CourseDescriptionEditor = ({
  value = '',
  setValue,
  clearErrors,
  setError,
  name,
  error,
  disabled = false,
}: ICourseDescriptionEditor) => {
  const {
    settings: { isCollab, emptyEditor },
  } = useSettings()

  const initialConfig: any = {
    editorState: isCollab ? null : emptyEditor,
    namespace: 'Playground',
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      //   throw error
    },
    theme: PlaygroundEditorTheme,
    editable: !disabled,
  }

  const normalizeValue = (value: string) => {
    // Check if the value is already in Lexical format (contains HTML-like structure)
    const isLexicalFormat = VALID_EDITOR_REGEX.test(value)

    // If it's plain text, wrap it inside a <p> tag
    if (!isLexicalFormat) {
      return LEXICAL_EDITOR_P_TAG.replace('$value', value)
    }

    return value
  }

  return (
    <Fragment>
      <CourseDescriptionEditorWrapper
        $error={!!error}
        $disabled={disabled}
        className="editor-content"
      >
        <LexicalComposer initialConfig={initialConfig}>
          <>
            {!!value ? (
              <Editor
                setValue={setValue}
                value={normalizeValue(value)}
                clearErrors={clearErrors}
                setError={setError}
                contentName={name}
                config={{
                  hasInsert: false,
                  isRequired: true,
                  minLength: 50,
                }}
              />
            ) : null}
          </>
        </LexicalComposer>
      </CourseDescriptionEditorWrapper>
      {error && error.message && (
        <p className="text-[#B91C1C] mt-2 text-sm">{error?.message}</p>
      )}
    </Fragment>
  )
}

export default CourseDescriptionEditor

const CourseDescriptionEditorWrapper = styled.div<{
  $error?: boolean
  $disabled?: boolean
}>`
  width: 100%;
  margin-top: 12px;
  border: 1px solid
    ${({ $error }) =>
      $error
        ? 'red'
        : 'rgb(var(--color-slate-200) / var(--tw-border-opacity))'};
  border-radius: 0.375rem;

  .ContentEditable__root {
    min-height: 300px !important;
    max-height: 300px !important;
    overflow-y: auto;
  }

  .PlaygroundEditorTheme__ltr {
    span {
      color: ${({ $disabled }) => ($disabled ? 'gray !important' : 'black')};
    }
  }
`
