import { zodResolver } from '@hookform/resolvers/zod'
import { selectContentLoading, useAppDispatch, useAppSelector } from '@redux'
import { Card, Divider, Skeleton, message } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { CONTACT_PAGE, URL_REGEX, thunkActionLoading } from '@configs'
import { Button, Input } from 'src/common'
import { IUpdateContactRequest } from 'src/interfaces/content-management'
import { getContact, updateContact } from 'src/redux/actions/content-management'

interface IContactItemProps {
  activeTab?: string
}

const schema = z.object({
  content: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, { message: t('error:max_contact_content_error') as string }),
  contentInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:max_contact_content_netherlands_error') as string,
    }),
  phoneNumber: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .min(8, { message: t('error:phone_length_error') })
    .max(13, { message: t('error:phone_length_error') })
    .regex(/^\s*\d*\s*$|^$/, {
      message: t('error:phone_format_error') as string,
    }),
  email: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .email({ message: t('error:email_format_error') as string }),
  address: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    }),
  facebookLink: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(2048, { message: t('error:max_url_error') as string })
    // .url({ message: t('error:required_url_error') as string }),
    .regex(URL_REGEX, { message: t('error:required_url_error') as string }),
  instagramLink: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(2048, { message: t('error:max_url_error') as string })
    // .url({ message: t('error:required_url_error') as string }),
    .regex(URL_REGEX, { message: t('error:required_url_error') as string }),
  linkedinLink: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(2048, { message: t('error:max_url_error') as string })
    // .url({ message: t('error:required_url_error') as string }),
    .regex(URL_REGEX, { message: t('error:required_url_error') as string }),
  tiktokLink: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(2048, { message: t('error:max_url_error') as string })
    // .url({ message: t('error:required_url_error') as string }),
    .regex(URL_REGEX, { message: t('error:required_url_error') as string }),
})

export const ContactPage = ({ activeTab }: IContactItemProps) => {
  const { contact } = useAppSelector((state) => state.content)

  const getContactLoading = useAppSelector((state) =>
    selectContentLoading(state, thunkActionLoading.GET_CONTACT_LOADING)
  )
  const updateContactLoading = useAppSelector((state) =>
    selectContentLoading(state, thunkActionLoading.UPDATE_CONTACT_LOADING)
  )

  const defaultValue: IUpdateContactRequest = {
    content: contact?.content ?? '',
    contentInDutch: contact?.contentInDutch ?? '',
    phoneNumber: contact?.metaData?.phoneNumber ?? '',
    email: contact?.metaData?.email ?? '',
    address: contact?.metaData?.address ?? '',
    facebookLink: contact?.metaData.facebookLink ?? '',
    instagramLink: contact?.metaData.instagramLink ?? '',
    linkedinLink: contact?.metaData?.linkedinLink ?? '',
    tiktokLink: contact?.metaData?.tiktokLink ?? '',
  }

  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm<IUpdateContactRequest>({
    defaultValues: defaultValue,
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onSave = async (data: IUpdateContactRequest) => {
    //on save road map
    try {
      const response = await dispatch(updateContact(data)).unwrap()
      if (response?.contact) {
        message.success(response.message)
      }
    } catch (error) {
      throw error
    }
  }

  const onClickCancel = () => {
    reset({
      content: contact?.content ?? '',
      contentInDutch: contact?.contentInDutch ?? '',
      phoneNumber: contact?.metaData?.phoneNumber ?? '',
      email: contact?.metaData?.email ?? '',
      address: contact?.metaData?.address ?? '',
      facebookLink: contact?.metaData?.facebookLink ?? '',
      instagramLink: contact?.metaData?.instagramLink ?? '',
      linkedinLink: contact?.metaData?.linkedinLink ?? '',
      tiktokLink: contact?.metaData?.tiktokLink ?? '',
    })
  }

  useEffect(() => {
    const getContactFn = async () => {
      const response = await dispatch(getContact()).unwrap()
      if (response?.success) {
        setValue('content', response?.data?.content ?? '')
        setValue('contentInDutch', response?.data?.contentInDutch ?? '')
        setValue('phoneNumber', response?.data?.metaData?.phoneNumber ?? '')
        setValue('email', response?.data?.metaData?.email ?? '')
        setValue('address', response?.data?.metaData?.address ?? '')
        setValue('facebookLink', response?.data?.metaData?.facebookLink ?? '')
        setValue('instagramLink', response?.data?.metaData?.instagramLink ?? '')
        setValue('linkedinLink', response?.data?.metaData?.linkedinLink ?? '')
        setValue('tiktokLink', response?.data?.metaData?.tiktokLink ?? '')
      }
    }

    getContactFn()
  }, [])

  return (
    <>
      {getContactLoading ? (
        <>
          <Skeleton
            paragraph={{ rows: 4 }}
            style={{
              marginTop: 10,
            }}
          />
        </>
      ) : (
        <>
          <Card>
            <form>
              <div className="wrapper-divider-title flex items-center">
                <div className="text-[16px] font-bold">Header contact</div>
                <div className="flex-1">
                  <Divider />
                </div>
              </div>
              <div className="grid grid-cols-2 items-start gap-4">
                <Controller
                  name={'content'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="'How we can help':"
                        name="content"
                        alignment="col"
                        required
                        // onChange={(e) => {
                        //   onChange(e)
                        //   if (
                        //     e.target.value
                        //       .trim()
                        //       .localeCompare(contact?.content ?? '', 'en', {
                        //         sensitivity: 'variant',
                        //       }) === 0
                        //   )
                        //     setIsChange(false)
                        //   else setIsChange(true)
                        // }}
                        onChange={onChange}
                        value={value}
                        style={{ marginTop: 10 }}
                        errors={error?.message}
                      />
                    )
                  }}
                />
                <Controller
                  name={'contentInDutch'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="'How we can help' in Netherlands:"
                        name="contentInDutch"
                        alignment="col"
                        required
                        // onChange={(e) => {
                        //   onChange(e)
                        //   if (
                        //     e.target.value
                        //       .trim()
                        //       .localeCompare(contact?.contentInDutch ?? '', 'en', {
                        //         sensitivity: 'variant',
                        //       }) === 0
                        //   )
                        //     setIsChange(false)
                        //   else setIsChange(true)
                        // }}
                        onChange={onChange}
                        value={value}
                        style={{ marginTop: 10 }}
                        errors={error?.message}
                      />
                    )
                  }}
                />
                <Controller
                  name={'phoneNumber'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="Phone number:"
                        name="phoneNumber"
                        alignment="col"
                        required
                        // onChange={(e) => {
                        //   onChange(e)
                        //   if (
                        //     e.target.value
                        //       .trim()
                        //       .localeCompare(
                        //         contact?.metaData?.phoneNumber ?? '',
                        //         'en',
                        //         {
                        //           sensitivity: 'variant',
                        //         }
                        //       ) === 0
                        //   )
                        //     setIsChange(false)
                        //   else setIsChange(true)
                        // }}
                        onChange={onChange}
                        value={value}
                        style={{ marginTop: 10 }}
                        errors={error?.message}
                      />
                    )
                  }}
                />

                <Controller
                  name={'address'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="Address:"
                        name="address"
                        alignment="col"
                        required
                        // onChange={(e) => {
                        //   onChange(e)
                        //   if (
                        //     e.target.value
                        //       .trim()
                        //       .localeCompare(contact?.metaData?.address ?? '', 'en', {
                        //         sensitivity: 'variant',
                        //       }) === 0
                        //   )
                        //     setIsChange(false)
                        //   else setIsChange(true)
                        // }}
                        onChange={onChange}
                        value={value}
                        style={{ marginTop: 10 }}
                        errors={error?.message}
                      />
                    )
                  }}
                />
              </div>

              <div className="wrapper-divider-title flex items-center">
                <div className="text-[16px] font-bold">Footer contact</div>
                <div className="flex-1">
                  <Divider />
                </div>
              </div>
              <div className="grid grid-cols-2 items-start gap-4">
                <Controller
                  name={'email'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="Email:"
                        name="email"
                        alignment="col"
                        required
                        // onChange={(e) => {
                        //   onChange(e)
                        //   if (
                        //     e.target.value
                        //       .trim()
                        //       .localeCompare(contact?.metaData?.email ?? '', 'en', {
                        //         sensitivity: 'variant',
                        //       }) === 0
                        //   )
                        //     setIsChange(false)
                        //   else setIsChange(true)
                        // }}
                        onChange={onChange}
                        value={value}
                        style={{ marginTop: 10 }}
                        errors={error?.message}
                      />
                    )
                  }}
                />
                <Controller
                  name={'tiktokLink'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="Tiktok:"
                        name="tiktokLink"
                        alignment="col"
                        required
                        // onChange={(e) => {
                        //   onChange(e)
                        //   if (
                        //     e.target.value
                        //       .trim()
                        //       .localeCompare(contact?.metaData?.appleLink ?? '', 'en', {
                        //         sensitivity: 'variant',
                        //       }) === 0
                        //   )
                        //     setIsChange(false)
                        //   else setIsChange(true)
                        // }}
                        onChange={onChange}
                        value={value}
                        style={{ marginTop: 10 }}
                        errors={error?.message}
                      />
                    )
                  }}
                />
                <Controller
                  name={'facebookLink'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="Facebook:"
                        name="facebookLink"
                        alignment="col"
                        required
                        // onChange={(e) => {
                        //   onChange(e)
                        //   if (
                        //     e.target.value
                        //       .trim()
                        //       .localeCompare(
                        //         contact?.metaData?.facebookLink ?? '',
                        //         'en',
                        //         {
                        //           sensitivity: 'variant',
                        //         }
                        //       ) === 0
                        //   )
                        //     setIsChange(false)
                        //   else setIsChange(true)
                        // }}
                        onChange={onChange}
                        value={value}
                        style={{ marginTop: 10 }}
                        errors={error?.message}
                      />
                    )
                  }}
                />
                <Controller
                  name={'instagramLink'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="Instagram:"
                        name="instagramLink"
                        alignment="col"
                        required
                        // onChange={(e) => {
                        //   onChange(e)
                        //   if (
                        //     e.target.value
                        //       .trim()
                        //       .localeCompare(
                        //         contact?.metaData?.instagramLink ?? '',
                        //         'en',
                        //         {
                        //           sensitivity: 'variant',
                        //         }
                        //       ) === 0
                        //   )
                        //     setIsChange(false)
                        //   else setIsChange(true)
                        // }}
                        onChange={onChange}
                        value={value}
                        style={{ marginTop: 10 }}
                        errors={error?.message}
                      />
                    )
                  }}
                />
                <Controller
                  name={'linkedinLink'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label="Linkedin:"
                        name="linkedinLink"
                        alignment="col"
                        required
                        // onChange={(e) => {
                        //   onChange(e)
                        //   if (
                        //     e.target.value
                        //       .trim()
                        //       .localeCompare(contact?.metaData?.appleLink ?? '', 'en', {
                        //         sensitivity: 'variant',
                        //       }) === 0
                        //   )
                        //     setIsChange(false)
                        //   else setIsChange(true)
                        // }}
                        onChange={onChange}
                        value={value}
                        style={{ marginTop: 10 }}
                        errors={error?.message}
                      />
                    )
                  }}
                />
              </div>
            </form>

            <div
              style={{
                display: 'flex',
                marginTop: 24,
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              />
              {/* <Button
                htmlType="submit"
                size="middle"
                className="submit__btn login selected-btn"
                style={{
                  alignSelf: 'flex-end',
                  marginRight: 24,
                }}
                disabled={!isDirty}
                onClick={onClickCancel}
              >
                {'Reset'}
              </Button> */}
              <Button
                htmlType="submit"
                type="primary"
                size="middle"
                className="submit__btn loginselected-btn"
                style={{
                  alignSelf: 'flex-end',
                }}
                loading={!!getContactLoading || !!updateContactLoading}
                disabled={!isDirty}
                onClick={handleSubmit(onSave)}
              >
                {'Save'}
              </Button>
            </div>
          </Card>
        </>
      )}
    </>
  )
}
