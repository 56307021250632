import styled from 'styled-components'
import { Avatar, AvatarProps } from 'antd'
import { UserOutlined } from '@ant-design/icons'

export const StyledAvatar = styled(
  (
    props: AvatarProps & {
      iconClassName?: string
    }
  ) => {
    const { iconClassName, ...restProps } = { ...props }
    return (
      <Avatar
        icon={<UserOutlined className={iconClassName} />}
        {...restProps}
      />
    )
  }
)<{}>``
