import { FORMAT_DATE_TABLE } from '@configs'
import { Typography } from 'antd'
import styled from 'styled-components'

import { formatDateTimeForNotification } from '@utils'
import { StyledAvatar } from 'src/common'
import { INotificationTagCommonProps } from '../configs'
import { themes } from '@theme'

export const NotificationTagCommon = ({
  avatar,
  title,
  content,
  createdAt,
  isRead,
  onClick,
}: INotificationTagCommonProps) => {
  return (
    <NotificationTagCommonStyled $isRead={isRead} onClick={onClick}>
      <div className="flex gap-[12px] items-center col-span-6">
        {typeof avatar === 'string' ? (
          <StyledAvatar
            src={avatar}
            className="!flex items-center justify-center min-w-[46px] !w-[46px] !h-[46px]"
            iconClassName="!text-[24px]"
          />
        ) : (
          avatar
        )}

        <MainContentWrapper>
          {title}
          {content}
        </MainContentWrapper>
      </div>
      <Typography.Text className="col-span-1 col-start-8 flex flex-col items-end gap-2 !text-[11px] !text-neutral-500">
        {formatDateTimeForNotification(createdAt ?? '', FORMAT_DATE_TABLE)}
        {!isRead ? <UnreadIndicator /> : null}
      </Typography.Text>
    </NotificationTagCommonStyled>
  )
}

const NotificationTagCommonStyled = styled.div<{ $isRead?: boolean }>`
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  width: 100%;
  background-color: ${({ $isRead }) => ($isRead ? '#ffffffff' : '#F6F3FCFF')};
  border-radius: 6px;
  padding: 16px 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px;
  gap: 12px;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    box-shadow: ${themes.theme.light.colors.active} 0px 2px 2px;
    /* transform: scale(1.2);
    animation-name: floating;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out; */
  }

  @keyframes floating {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 4px);
    }
    100% {
      transform: translate(0, -0px);
    }
  }
`

const MainContentWrapper = styled.div`
  grid-row: span 1 / span 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const UnreadIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 999px;
  background-color: ${themes.theme.light.colors.active};
`
