import { CONTENT_MANAGEMENT_VALIDATION } from '@configs'
import { t } from 'i18next'
import * as z from 'zod'

export const DEFAULT_TEACHER_VIDEO_INTRO = {
  title: '',
  titleInDutch: '',
  subTitle: '',
  subTitleInDutch: '',
  mediaDescription: '',
  mediaDescriptionInDutch: '',
  mediaId: 0,
  mediaMobileId: 0,
}

export const teacherVideoIntroSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  subTitle: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_SUBTITLE, {
      message: t('error:max_length_100') as string,
    }),
  subTitleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_SUBTITLE, {
      message: t('error:max_length_100') as string,
    }),
  mediaDescription: z
    .string()
    .trim()
    .min(CONTENT_MANAGEMENT_VALIDATION.MIN_LENGTH_DESCRIPTION, {
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_DESCRIPTION, {
      message: t('error:max_length_500') as string,
    }),
  mediaDescriptionInDutch: z
    .string()
    .trim()
    .min(CONTENT_MANAGEMENT_VALIDATION.MIN_LENGTH_DESCRIPTION, {
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_DESCRIPTION, {
      message: t('error:max_length_500') as string,
    }),
  mediaId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
  mediaMobileId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
  thumbnailId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
  thumbnailMobileId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
})
