import {
  CustomFilterDashboard,
  MultiBarChartComponent,
  PieChartComponent,
  ShareDateSelect,
  StyledEmpty,
} from '@components'
import {
  CourseByDivision,
  CourseByType,
  ICourseInsightOverview,
} from '@interfaces'
import {
  DescriptionDonut,
  DescriptionDonutProps,
  EOverviewDateFilter,
} from '@pages'
import {
  EFilterBy,
  getCourseGrowth,
  getCourseInsightOverview,
  useAppDispatch,
} from '@redux'
import { themes } from '@theme'
import { formatDate, getCurrentYear } from '@utils'
import { Card, Skeleton, Typography } from 'antd'
import { map } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

type TeacherCourseInsightProps = {
  activeTab: string
}

export const TeacherCourseInsight = (props: TeacherCourseInsightProps) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingPublicCourseGrowth, setIsLoadingPublicCourseGrowth] =
    useState(false)
  const [dataCourseByType, setDataCourseByType] = useState<CourseByType | null>(
    null
  )
  const [dataCourseByDivision, setDataCourseByDivision] =
    useState<CourseByDivision | null>(null)

  const [dataDetailCourseByType, setDataDetailCourseByType] = useState<
    DescriptionDonutProps[]
  >([])
  const [dataDetailCourseByDivision, setDataDetailCourseByDivision] = useState<
    DescriptionDonutProps[]
  >([])

  const [totalCourse, setTotalCourse] = useState<number | null>(null)
  const [filterBy, setFilterBy] = useState<EFilterBy | any>(
    EFilterBy.LAST_MONTH
  )
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [selectedYear, setSelectedYear] = useState(getCurrentYear())
  const [dataCourseGrowth, setDataCourseGrowth] =
    useState<ICourseInsightOverview | null>(null)

  const fetchCourseInsightOverviewData = async () => {
    setIsLoading(true)
    const response = await dispatch(
      getCourseInsightOverview({
        filterBy,
        startDate,
        endDate,
      })
    ).unwrap()
    setIsLoading(false)
    if (response) {
      setDataCourseByType(response.courseByType)
      setDataCourseByDivision(response.courseByDivision)
      setTotalCourse(response.totalCourse)

      const formattedCourseData = map(response.courseByType, (item, key) => {
        return {
          title: item.title,
          bgColor: item.bgColor,
          revenue: item.value,
          percentage: item.percentage,
        }
      })

      setDataDetailCourseByType(formattedCourseData)

      const formattedDivisionData = map(
        response.courseByDivision,
        (item, key) => {
          return {
            title: item.title,
            bgColor: item.bgColor,
            revenue: item.value,
            percentage: item.percentage,
          }
        }
      )

      setDataDetailCourseByDivision(formattedDivisionData)
    }
  }

  const fetchCourseGrowthData = async () => {
    setIsLoadingPublicCourseGrowth(true)
    const response = await dispatch(
      getCourseGrowth({
        year: selectedYear,
      })
    ).unwrap()
    setIsLoadingPublicCourseGrowth(false)
    if (response) {
      setDataCourseGrowth(response)
    }
  }

  useEffect(() => {
    fetchCourseGrowthData()
  }, [selectedYear])

  useEffect(() => {
    if (filterBy === EFilterBy.CUSTOM && (!startDate || !endDate)) {
      return
    } else {
      fetchCourseInsightOverviewData()
    }
  }, [filterBy, startDate, endDate])

  const handleChangeFilterOverview = async (value: any) => {
    const { filterBy, startDate, endDate } = value

    if (filterBy === EFilterBy.CUSTOM) {
      // Only proceed if both startDate and endDate are provided
      if (!startDate || !endDate) return

      setStartDate(startDate)
      setEndDate(endDate)
      setFilterBy(filterBy)
    } else if (filterBy === EFilterBy.ALL_TIME) {
      // Reset filter when 'ALL_TIME' is selected
      setFilterBy('')
      setStartDate('')
      setEndDate('')
    } else {
      // For other filter types, set the filter and reset dates
      setFilterBy(filterBy)
      setStartDate('')
      setEndDate('')
    }
  }

  const formatDataCourseByType = useMemo(() => {
    return {
      labels: Object.values(dataCourseByType || {}).map((item) => item.title),
      datasets: [
        {
          label: 'Course by type',
          data: Object.values(dataCourseByType || {}).map((item) => item.value),
          backgroundColor: [
            themes.theme.dark.colors.miniDashboard.darkBlue,
            themes.theme.dark.colors.miniDashboard.lightPurple,
            themes.theme.dark.colors.miniDashboard.darkYellow,
          ],
          borderColor: [
            themes.theme.dark.colors.miniDashboard.darkBlue,
            themes.theme.dark.colors.miniDashboard.lightPurple,
            themes.theme.dark.colors.miniDashboard.darkYellow,
          ],
          borderWidth: 1,
        },
      ],
    }
  }, [dataCourseByType])

  const formatDataCourseByDivision = useMemo(() => {
    return {
      labels: Object.values(dataCourseByDivision || {}).map(
        (item) => item.title
      ),
      datasets: [
        {
          label: 'Course by division',
          data: Object.values(dataCourseByDivision || {}).map(
            (item) => item.value
          ),
          backgroundColor: [
            themes.theme.dark.colors.miniDashboard.darkBlue,
            themes.theme.dark.colors.miniDashboard.lightPurple,
            themes.theme.dark.colors.miniDashboard.darkYellow,
          ],
          borderColor: [
            themes.theme.dark.colors.miniDashboard.darkBlue,
            themes.theme.dark.colors.miniDashboard.lightPurple,
            themes.theme.dark.colors.miniDashboard.darkYellow,
          ],
          borderWidth: 1,
        },
      ],
    }
  }, [dataCourseByDivision])

  const formatDataCourseGrowth = useMemo(() => {
    const datasets = Object.values(dataCourseGrowth || {})
    return {
      labels: datasets.map((item) => formatDate(item.month, 'MM/YYYY')),
      datasets: [
        {
          label: 'Course growth',
          data: datasets.map((item) => item.value),
          backgroundColor: themes.theme.dark.colors.miniDashboard.darkBlue,
          borderColor: themes.theme.dark.colors.miniDashboard.darkBlue,
          borderWidth: 1,
          barThickness: 40,
        },
      ],
    }
  }, [dataCourseGrowth])

  const isFormatDataCourseGrowthHasData = useMemo(() => {
    const datasets = Object.values(dataCourseGrowth || {})
    return datasets.some((item) => item.value > 0)
  }, [dataCourseGrowth])

  return (
    <div>
      {/* Course insight */}
      <div className="bg-white rounded px-8 py-4">
        <div className="flex justify-end">
          <CustomFilterDashboard
            containerClassName="!min-w-[280px]"
            onChangeFilterOverview={(value) => {
              handleChangeFilterOverview(value)
            }}
            defaultValue={EOverviewDateFilter.LAST_MONTH}
          />
        </div>

        {isLoading && <Skeleton className="mt-4" paragraph={{ rows: 10 }} />}

        {!isLoading && totalCourse === 0 && <StyledEmpty />}

        {!isLoading && totalCourse !== 0 && (
          <div className="mt-10 grid grid-cols-12 gap-8">
            {/* Total course by type */}
            <div className="col-span-6">
              <Typography.Text className="font-semibold text-xl">
                Total course by type
              </Typography.Text>
              <div className="grid grid-cols-12 gap-8 mt-6">
                <div className="col-span-7 relative">
                  <PieChartComponent
                    data={formatDataCourseByType}
                    centerData={totalCourse}
                  />
                  <div className="flex flex-col items-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <Typography.Text className="text-center text-xl">
                      Total courses
                    </Typography.Text>
                    <Typography.Text className="text-center text-xl font-bold">
                      {totalCourse}
                    </Typography.Text>
                  </div>
                </div>
                <div className="col-span-5 flex flex-col justify-center ">
                  {dataDetailCourseByType.map((item) => (
                    <DescriptionDonut
                      title={item.title}
                      bgColor={item.bgColor}
                      revenue={item.revenue}
                      percentage={item.percentage}
                    />
                  ))}
                </div>
              </div>
            </div>

            {/* Total course by division */}
            <div className="col-span-6">
              <Typography.Text className="font-semibold text-xl">
                Total course by division
              </Typography.Text>
              <div className="grid grid-cols-12 gap-8 mt-6">
                <div className="col-span-7 relative">
                  <PieChartComponent
                    data={formatDataCourseByDivision}
                    centerData={totalCourse}
                  />
                  <div className="flex flex-col items-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <Typography.Text className="text-center text-xl">
                      Total courses
                    </Typography.Text>
                    <Typography.Text className="text-center text-xl font-bold">
                      {totalCourse}
                    </Typography.Text>
                  </div>
                </div>
                <div className="col-span-5 flex flex-col justify-center">
                  {dataDetailCourseByDivision.map((item) => (
                    <DescriptionDonut
                      title={item.title}
                      bgColor={item.bgColor}
                      revenue={item.revenue}
                      percentage={item.percentage}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mt-8 bg-white rounded px-8 py-4 mb-4">
        <div className="flex items-start justify-between p-4">
          <Typography.Text className="font-semibold text-2xl">
            Public course growth
          </Typography.Text>
          <ShareDateSelect
            type="year"
            placeholder="To"
            format={'YYYY'}
            onChange={(date, dateString) => {
              setSelectedYear(dateString)
            }}
            maxDate={new Date()}
            defaultValue={moment(selectedYear, 'YYYY')}
            allowClear={false}
          />
        </div>

        {isLoadingPublicCourseGrowth ? (
          <Skeleton className="mt-4" paragraph={{ rows: 10 }} />
        ) : (
          <div>
            {!isFormatDataCourseGrowthHasData ? (
              <StyledEmpty />
            ) : (
              <MultiBarChartComponent data={formatDataCourseGrowth} />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
