import {
  createEditHowToContentAction,
  createEditVideoIntroAction,
  createHowToDetailAction,
  deleteHowToDetailByIdAction,
  editHowToDetailByIdAction,
  getHowToContentAction,
  getHowToDetailByIdAction,
  getHowToTableAction,
} from './../actions/teacher-landing-page'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '.'
import {
  createEditTeacherBannerAction,
  createEditWhyUsContentAction,
  createReasonDetailAction,
  deleteReasonByIdAction,
  editReasonDetailByIdAction,
  getReasonDetailByIdAction,
  getTeacherBannerAction,
  getVideoIntroAction,
  getWhyUsContentAction,
  getWhyUsTableAction,
} from 'src/redux/actions'
import {
  ICommonContentItem,
  IWhyUsContentItem,
  IWhyUsTableItem,
} from '@interfaces'

interface ITeacherLandingPageState {
  bannerInformation: ICommonContentItem

  whyUsList: IWhyUsTableItem[]
  whyUsContent?: IWhyUsContentItem
  reasonDetail: ICommonContentItem

  videoIntro: ICommonContentItem

  howToList: ICommonContentItem[]
  howToContent?: ICommonContentItem
  howToDetail: ICommonContentItem

  loadings: Record<string, boolean | undefined>
}

const initialState: ITeacherLandingPageState = {
  bannerInformation: {
    title: '',
    titleInDutch: '',
    mediaDescription: '',
    mediaDescriptionInDutch: '',
    mediaId: 0,
    mediaMobileId: 0,
  },

  whyUsList: [],
  whyUsContent: {} as IWhyUsContentItem,
  reasonDetail: {} as ICommonContentItem,

  videoIntro: {} as ICommonContentItem,

  howToList: [],
  howToContent: {} as ICommonContentItem,
  howToDetail: {} as ICommonContentItem,
  loadings: {},
}

const teacherLandingPage = createSlice({
  name: 'teacherLanding',
  initialState: initialState,
  reducers: {
    clearReasonDetail: (state) => {
      state.reasonDetail = {} as ICommonContentItem
    },

    clearHowToDetail: (state) => {
      state.howToDetail = {} as ICommonContentItem
    },
  },
  extraReducers: (builder) => {
    // getTeacherBannerAction
    builder.addCase(getTeacherBannerAction.pending, (state) => {
      state.loadings[`getTeacherBannerAction`] = true
    })
    builder.addCase(
      getTeacherBannerAction.fulfilled,
      (state, action: PayloadAction<ICommonContentItem>) => {
        state.loadings[`getTeacherBannerAction`] = false
        state.bannerInformation = action?.payload
      }
    )
    builder.addCase(getTeacherBannerAction.rejected, (state) => {
      state.loadings[`getTeacherBannerAction`] = false
    })

    // createEditTeacherBannerAction
    builder.addCase(createEditTeacherBannerAction.pending, (state) => {
      state.loadings[`createEditTeacherBannerAction`] = true
    })
    builder.addCase(createEditTeacherBannerAction.fulfilled, (state) => {
      state.loadings[`createEditTeacherBannerAction`] = false
    })
    builder.addCase(createEditTeacherBannerAction.rejected, (state) => {
      state.loadings[`createEditTeacherBannerAction`] = false
    })

    // getWhyUsTableAction
    builder.addCase(getWhyUsTableAction.pending, (state) => {
      state.loadings[`getWhyUsTableAction`] = true
    })
    builder.addCase(
      getWhyUsTableAction.fulfilled,
      (state, action: PayloadAction<IWhyUsTableItem[]>) => {
        state.loadings[`getWhyUsTableAction`] = false
        state.whyUsList = action?.payload
      }
    )
    builder.addCase(getWhyUsTableAction.rejected, (state) => {
      state.loadings[`getWhyUsTableAction`] = false
    })

    // getWhyUsContentAction
    builder.addCase(getWhyUsContentAction.pending, (state) => {
      state.loadings[`getWhyUsContentAction`] = true
    })
    builder.addCase(
      getWhyUsContentAction.fulfilled,
      (state, action: PayloadAction<IWhyUsContentItem>) => {
        state.loadings[`getWhyUsContentAction`] = false
        state.whyUsContent = action?.payload
      }
    )
    builder.addCase(getWhyUsContentAction.rejected, (state) => {
      state.loadings[`getWhyUsContentAction`] = false
    })

    // createEditWhyUsContentAction
    builder.addCase(createEditWhyUsContentAction.pending, (state) => {
      state.loadings[`createEditWhyUsContentAction`] = true
    })
    builder.addCase(createEditWhyUsContentAction.fulfilled, (state) => {
      state.loadings[`createEditWhyUsContentAction`] = false
    })
    builder.addCase(createEditWhyUsContentAction.rejected, (state) => {
      state.loadings[`createEditWhyUsContentAction`] = false
    })

    // getReasonDetailByIdAction
    builder.addCase(getReasonDetailByIdAction.pending, (state) => {
      state.loadings[`getReasonDetailByIdAction`] = true
    })
    builder.addCase(
      getReasonDetailByIdAction.fulfilled,
      (state, action: PayloadAction<ICommonContentItem>) => {
        state.loadings[`getReasonDetailByIdAction`] = false
        state.reasonDetail = action?.payload
      }
    )
    builder.addCase(getReasonDetailByIdAction.rejected, (state) => {
      state.loadings[`getReasonDetailByIdAction`] = false
    })

    // createReasonDetailAction
    builder.addCase(createReasonDetailAction.pending, (state) => {
      state.loadings[`createReasonDetailAction`] = true
    })
    builder.addCase(createReasonDetailAction.fulfilled, (state) => {
      state.loadings[`createReasonDetailAction`] = false
    })
    builder.addCase(createReasonDetailAction.rejected, (state) => {
      state.loadings[`createReasonDetailAction`] = false
    })

    // editReasonDetailByIdAction
    builder.addCase(editReasonDetailByIdAction.pending, (state) => {
      state.loadings[`editReasonDetailByIdAction`] = true
    })
    builder.addCase(editReasonDetailByIdAction.fulfilled, (state) => {
      state.loadings[`editReasonDetailByIdAction`] = false
    })
    builder.addCase(editReasonDetailByIdAction.rejected, (state) => {
      state.loadings[`editReasonDetailByIdAction`] = false
    })

    // deleteReasonByIdAction
    builder.addCase(deleteReasonByIdAction.pending, (state) => {
      state.loadings[`deleteReasonByIdAction`] = true
    })
    builder.addCase(deleteReasonByIdAction.fulfilled, (state) => {
      state.loadings[`deleteReasonByIdAction`] = false
    })
    builder.addCase(deleteReasonByIdAction.rejected, (state) => {
      state.loadings[`deleteReasonByIdAction`] = false
    })

    // getVideoIntroAction
    builder.addCase(getVideoIntroAction.pending, (state) => {
      state.loadings[`getVideoIntroAction`] = true
    })
    builder.addCase(
      getVideoIntroAction.fulfilled,
      (state, action: PayloadAction<ICommonContentItem>) => {
        state.loadings[`getVideoIntroAction`] = false
        state.videoIntro = action?.payload
      }
    )
    builder.addCase(getVideoIntroAction.rejected, (state) => {
      state.loadings[`getVideoIntroAction`] = false
    })

    // createEditVideoIntroAction
    builder.addCase(createEditVideoIntroAction.pending, (state) => {
      state.loadings[`createEditVideoIntroAction`] = true
    })
    builder.addCase(createEditVideoIntroAction.fulfilled, (state) => {
      state.loadings[`createEditVideoIntroAction`] = false
    })
    builder.addCase(createEditVideoIntroAction.rejected, (state) => {
      state.loadings[`createEditVideoIntroAction`] = false
    })

    // getHowToTableAction
    builder.addCase(getHowToTableAction.pending, (state) => {
      state.loadings[`getHowToTableAction`] = true
    })
    builder.addCase(
      getHowToTableAction.fulfilled,
      (state, action: PayloadAction<ICommonContentItem[]>) => {
        state.loadings[`getHowToTableAction`] = false
        state.howToList = action?.payload
      }
    )
    builder.addCase(getHowToTableAction.rejected, (state) => {
      state.loadings[`getHowToTableAction`] = false
    })

    // getHowToContentAction
    builder.addCase(getHowToContentAction.pending, (state) => {
      state.loadings[`getHowToContentAction`] = true
    })
    builder.addCase(
      getHowToContentAction.fulfilled,
      (state, action: PayloadAction<ICommonContentItem>) => {
        state.loadings[`getHowToContentAction`] = false
        state.howToContent = action?.payload
      }
    )
    builder.addCase(getHowToContentAction.rejected, (state) => {
      state.loadings[`getHowToContentAction`] = false
    })

    // createEditHowToContentAction
    builder.addCase(createEditHowToContentAction.pending, (state) => {
      state.loadings[`createEditHowToContentAction`] = true
    })
    builder.addCase(createEditHowToContentAction.fulfilled, (state) => {
      state.loadings[`createEditHowToContentAction`] = false
    })
    builder.addCase(createEditHowToContentAction.rejected, (state) => {
      state.loadings[`createEditHowToContentAction`] = false
    })

    // getHowToDetailByIdAction
    builder.addCase(getHowToDetailByIdAction.pending, (state) => {
      state.loadings[`getHowToDetailByIdAction`] = true
    })
    builder.addCase(
      getHowToDetailByIdAction.fulfilled,
      (state, action: PayloadAction<ICommonContentItem>) => {
        state.loadings[`getHowToDetailByIdAction`] = false
        state.howToDetail = action?.payload
      }
    )
    builder.addCase(getHowToDetailByIdAction.rejected, (state) => {
      state.loadings[`getHowToDetailByIdAction`] = false
    })

    // createHowToDetailAction
    builder.addCase(createHowToDetailAction.pending, (state) => {
      state.loadings[`createHowToDetailAction`] = true
    })
    builder.addCase(createHowToDetailAction.fulfilled, (state) => {
      state.loadings[`createHowToDetailAction`] = false
    })
    builder.addCase(createHowToDetailAction.rejected, (state) => {
      state.loadings[`createHowToDetailAction`] = false
    })

    // editHowToDetailByIdAction
    builder.addCase(editHowToDetailByIdAction.pending, (state) => {
      state.loadings[`editHowToDetailByIdAction`] = true
    })
    builder.addCase(editHowToDetailByIdAction.fulfilled, (state) => {
      state.loadings[`editHowToDetailByIdAction`] = false
    })
    builder.addCase(editHowToDetailByIdAction.rejected, (state) => {
      state.loadings[`editHowToDetailByIdAction`] = false
    })

    // deleteHowToDetailByIdAction
    builder.addCase(deleteHowToDetailByIdAction.pending, (state) => {
      state.loadings[`deleteHowToDetailByIdAction`] = true
    })
    builder.addCase(deleteHowToDetailByIdAction.fulfilled, (state) => {
      state.loadings[`deleteHowToDetailByIdAction`] = false
    })
    builder.addCase(deleteHowToDetailByIdAction.rejected, (state) => {
      state.loadings[`deleteHowToDetailByIdAction`] = false
    })
  },
})

export const teacherLandingPageActions = {
  ...teacherLandingPage.actions,
}

export const selectTeacherLandingPageLoading = (
  state: RootState,
  name: string
) => state.plans.loadings[`${name}Loading`]

export default teacherLandingPage.reducer
