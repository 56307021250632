import { TEACHER_DASHBOARD_OVERVIEW } from '@configs'
import { Tabs } from 'antd'
import { useState } from 'react'
import { Text } from 'src/common'
import {
  TeacherBanner,
  TeacherBecomeInstructor,
  TeacherCourseInsight,
  TeacherFAQ,
  TeacherHowTo,
  TeacherInsight,
  TeacherOverview,
  TeacherPricing,
  TeacherRevenue,
  TeacherSEOConfigure,
  TeacherVideoIntro,
  TeacherWhyUs,
} from '@pages'
import styled from 'styled-components'

interface IDashboardTeacherProps {}

export const DashboardTeacher = ({}: IDashboardTeacherProps) => {
  const [activeTab, setActiveTab] = useState<string>(
    TEACHER_DASHBOARD_OVERVIEW.TEACHER_OVERVIEW
  )

  const tabItems = [
    {
      label: <Text>{TEACHER_DASHBOARD_OVERVIEW.TEACHER_OVERVIEW}</Text>,
      key: TEACHER_DASHBOARD_OVERVIEW.TEACHER_OVERVIEW,
      children: <TeacherOverview activeTab={activeTab} />,
    },
    {
      label: <Text>{TEACHER_DASHBOARD_OVERVIEW.TEACHER_REVENUE}</Text>,
      key: TEACHER_DASHBOARD_OVERVIEW.TEACHER_REVENUE,
      children: <TeacherRevenue activeTab={activeTab} />,
    },
    {
      label: <Text>{TEACHER_DASHBOARD_OVERVIEW.TEACHER_COURSE_INSIGHT}</Text>,
      key: TEACHER_DASHBOARD_OVERVIEW.TEACHER_COURSE_INSIGHT,
      children: <TeacherCourseInsight activeTab={activeTab} />,
    },
    {
      label: <Text>{TEACHER_DASHBOARD_OVERVIEW.TEACHER_INSIGHT}</Text>,
      key: TEACHER_DASHBOARD_OVERVIEW.TEACHER_INSIGHT,
      children: <TeacherInsight activeTab={activeTab} />,
    },
  ]

  return (
    <DashboardTeacherStyled>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey={TEACHER_DASHBOARD_OVERVIEW.TEACHER_OVERVIEW}
        type="card"
        size={'small'}
        items={tabItems}
        defaultChecked={true}
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e)
        }}
      />
    </DashboardTeacherStyled>
  )
}

const DashboardTeacherStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },
  }
})
