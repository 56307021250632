import { CaretUpOutlined } from '@ant-design/icons'
import { CustomFilterDashboard } from '@components'
import { ETeacherRole } from '@configs'
import { IAcademyOverview, ITopFiveRevenueResponse } from '@interfaces'
import { ERegisterTeacherTable, ETeacherOverviewTable } from '@pages'
import {
  EFilterBy,
  getAcademyOverview,
  getTopRevenue,
  useAppDispatch,
} from '@redux'
import { formatCurrency } from '@utils'
import { Card, Skeleton, Typography } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { SharedTable } from 'src/common'
import styled from 'styled-components'

type TeacherOverviewProps = {
  activeTab: string
}

export const TeacherOverview = (props: TeacherOverviewProps) => {
  const dispatch = useAppDispatch()
  const [filterBy, setFilterBy] = useState<EFilterBy | any>(
    EFilterBy.LAST_SIX_MONTH
  )
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingAcademyOverview, setIsLoadingAcademyOverview] =
    useState<boolean>(false)
  const [topFiveRevenue, setTopFiveRevenue] =
    useState<ITopFiveRevenueResponse | null>(null)

  const [academyOverview, setAcademyOverview] =
    useState<IAcademyOverview | null>(null)

  useEffect(() => {
    if (filterBy === EFilterBy.CUSTOM && (!startDate || !endDate)) {
      return
    } else {
      fetchCourseInsightOverviewData()
    }
  }, [filterBy, startDate, endDate])

  useEffect(() => {
    fetchAcademyOverviewData()
  }, [])

  const fetchCourseInsightOverviewData = async () => {
    setIsLoading(true)
    const response = await dispatch(
      getTopRevenue({
        filterBy,
        startDate,
        endDate,
      })
    ).unwrap()
    setIsLoading(false)
    if (response) {
      setTopFiveRevenue(response)
    }
  }

  const fetchAcademyOverviewData = async () => {
    setIsLoadingAcademyOverview(true)
    const response = await dispatch(getAcademyOverview()).unwrap()
    setIsLoadingAcademyOverview(false)
    if (response) {
      setAcademyOverview(response)
    }
  }

  const handleChangeFilterOverview = async (value: any) => {
    const { filterBy, startDate, endDate } = value

    if (filterBy === EFilterBy.CUSTOM) {
      // Only proceed if both startDate and endDate are provided
      if (!startDate || !endDate) return

      setStartDate(startDate)
      setEndDate(endDate)
      setFilterBy(filterBy)
    } else if (filterBy === EFilterBy.ALL_TIME) {
      // Reset filter when 'ALL_TIME' is selected
      setFilterBy('')
      setStartDate('')
      setEndDate('')
    } else {
      // For other filter types, set the filter and reset dates
      setFilterBy(filterBy)
      setStartDate('')
      setEndDate('')
    }
  }

  const columnCourses = useMemo(() => {
    return [
      {
        title: ETeacherOverviewTable.RANK,
        dataIndex: 'rank',
        key: 'id',
        ellipsis: true,
        width: '15%',
        align: 'center',
      },
      {
        title: ETeacherOverviewTable.NAME,
        dataIndex: 'name',
        key: 'name',
        render: (text: string) => <TextWrapper>{text}</TextWrapper>,
        align: 'center',
        width: '25%',
      },
      {
        title: ETeacherOverviewTable.FROM,
        dataIndex: 'from',
        key: 'from',
        render: (text: string) => (
          <TextWrapper className="truncate">{text}</TextWrapper>
        ),
        align: 'center',
        width: '30%',
      },
      {
        title: ETeacherOverviewTable.TOTAL_REVENUE,
        dataIndex: 'totalRevenue',
        key: 'totalRevenue',
        render: (text: string) => <Typography.Text>{text}</Typography.Text>,
        align: 'center',
        width: '30%',
      },
    ]
  }, [])

  const columnRegisterTeacher = useMemo(() => {
    return [
      {
        title: ERegisterTeacherTable.RANK,
        dataIndex: 'rank',
        key: 'id',
        ellipsis: true,
        width: '15%',
        align: 'center',
      },
      {
        title: ERegisterTeacherTable.NAME,
        dataIndex: 'name',
        key: 'name',
        render: (text: string) => (
          <TextWrapper className="truncate">{text}</TextWrapper>
        ),
        align: 'center',
        width: '25%',
      },
      {
        title: ERegisterTeacherTable.PACKAGE,
        dataIndex: 'package',
        key: 'package',
        render: (text: string) => (
          <TextWrapper className="truncate">{text}</TextWrapper>
        ),
        align: 'center',
        width: '30%',
      },
      {
        title: ERegisterTeacherTable.TOTAL_REVENUE,
        dataIndex: 'totalRevenue',
        key: 'totalRevenue',
        render: (text: string) => <Typography.Text>{text}</Typography.Text>,
        align: 'center',
        width: '30%',
      },
    ]
  }, [])

  const renderTopFiveRevenueCourses = useMemo(() => {
    if (!topFiveRevenue) return

    return topFiveRevenue.topFiveCourses.map((course, index) => {
      return {
        key: index,
        rank: index + 1,
        name: course.course?.title,
        from:
          course.author?.client !== ETeacherRole.OTHER
            ? 'LLC'
            : course.author?.name,
        totalRevenue: formatCurrency(Number(course.totalRevenue)),
      }
    })
  }, [topFiveRevenue])

  const renderTopFiveRevenueTeachers = useMemo(() => {
    if (!topFiveRevenue) return

    return topFiveRevenue.topFiveTeachers.map((teacher, index) => {
      return {
        key: index,
        rank: index + 1,
        name: teacher.author?.name,
        package: teacher?.packages?.[0]?.name || 'N/A',
        totalRevenue: formatCurrency(Number(teacher.totalRevenue)),
      }
    })
  }, [topFiveRevenue])

  return (
    <Card>
      <div>
        {/* overview card */}
        {isLoadingAcademyOverview ? (
          <Skeleton paragraph={{ rows: 5 }} />
        ) : (
          <div className="flex justify-between gap-8">
            <div className="border rounded-sm w-full px-4 py-2 shadow-md flex flex-col gap-4">
              <Typography.Text className="font-semibold">
                Total Course
              </Typography.Text>

              <Typography.Text className="!text-blue-700 font-bold text-2xl">
                {academyOverview?.courseOverview?.totalCourses}
              </Typography.Text>

              <div className="flex gap-2 items-center">
                <CaretUpOutlined
                  style={{
                    color: '#14923e',
                  }}
                />
                <Typography.Text
                  style={{
                    color: '#14923e',
                  }}
                >
                  {academyOverview?.courseOverview?.newCourses}
                </Typography.Text>
                <Typography.Text>
                  New published course from last month
                </Typography.Text>
              </div>
            </div>

            <div className="border rounded-sm w-full px-4 py-2 shadow-md flex flex-col gap-4">
              <Typography.Text className="font-semibold">
                Total Teacher
              </Typography.Text>

              <Typography.Text className="!text-blue-700 font-bold text-2xl">
                {academyOverview?.teacherOverview?.totalTeachers}
              </Typography.Text>

              <div className="flex gap-2 items-center">
                <CaretUpOutlined
                  style={{
                    color: '#14923e',
                  }}
                />
                <Typography.Text
                  style={{
                    color: '#14923e',
                  }}
                >
                  {academyOverview?.teacherOverview?.newTeachers}
                </Typography.Text>
                <Typography.Text>New teacher from last month</Typography.Text>
              </div>
            </div>
          </div>
        )}

        {/* Top 5 table */}

        <div className="flex justify-end mt-6">
          <CustomFilterDashboard
            containerClassName="!min-w-[280px]"
            onChangeFilterOverview={(value) => {
              handleChangeFilterOverview(value)
            }}
          />
        </div>

        {isLoading ? (
          <Skeleton paragraph={{ rows: 5 }} />
        ) : (
          <div className="mt-4 flex gap-8">
            <div>
              <Typography.Text className="font-semibold text-xl">
                Top 5 Courses
              </Typography.Text>
              <TableWrapper>
                <SharedTable
                  columns={columnCourses as any}
                  dataSource={renderTopFiveRevenueCourses}
                  hasIndexColumn={false}
                />
              </TableWrapper>
            </div>

            <div>
              <Typography.Text className="font-semibold text-xl">
                Top 5 registed Teachers
              </Typography.Text>
              <TableWrapper>
                <SharedTable
                  columns={columnRegisterTeacher as any}
                  dataSource={renderTopFiveRevenueTeachers}
                  hasIndexColumn={false}
                />
              </TableWrapper>
            </div>
          </div>
        )}
      </div>
    </Card>
  )
}

const TableWrapper = styled.div`
  .ant-table-wrapper,
  .ant-table-content {
    padding: 0 !important;
    margin: 0 !important;
  }
`

const TextWrapper = styled(Typography.Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
  text-align: center;
  `
