import { EPackagePageTabs, HOME_SEO } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { Skeleton, message } from 'antd'
import { t } from 'i18next'
import { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'src/common'
import { IHomeSeoDetail } from 'src/interfaces/content-management'
import { EMediaCategory } from 'src/interfaces/gallery'
import MetaTab from 'src/pages/blog/content/MetaTab'
import {
  createHomeSeo,
  createPackageSeo,
  getHomeSeo,
  getPackageSeo,
} from 'src/redux/actions/content-management'
import * as z from 'zod'

type Props = { activeTab?: string }

const packageSeoSchema = z.object({
  metaTitle: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(100, {
      message: t('error:metaTitle_length_error') as string,
    }),
  metaDescription: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:metaDescription_length_error') as string,
    }),
  canonical: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:canonical_length_error') as string,
    }),
  schemaMarkup: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:metaDescription_length_error') as string,
    }),
  metaImage: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    }),
  keywords: z
    .string({
      invalid_type_error: t('error:field_required') as string,
    })
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:metaKeyword_length_error') as string,
    }),
})

const PackageSeoTab = ({ activeTab }: Props) => {
  const [isUploading, setIsUploading] = useState(false)

  const dispatch = useAppDispatch()

  const detailPackageSeo = useAppSelector(
    (state: RootState) => state.content?.packagePage?.seo
  )

  const isGetPackageSeoLoading = useAppSelector(
    (state: RootState) => state.content?.loadings?.GET_PACKAGE_SEO_LOADING
  )

  const isCreatePackageSeoLoading = useAppSelector(
    (state: RootState) => state.content?.loadings?.CREATE_PACKAGE_SEO_LOADING
  )

  // Init react hook form - start
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
    clearErrors,
    setError,
    getValues,
    trigger,
    reset,
  } = useForm<IHomeSeoDetail>({
    defaultValues: {
      metaTitle: detailPackageSeo?.metaData?.metaTitle || '',
      metaDescription: detailPackageSeo?.metaData?.metaDescription || '',
      canonical: detailPackageSeo?.metaData?.canonical || '',
      schemaMarkup:
        detailPackageSeo?.metaData?.schemaMarkup || 'schema mark up',
      metaImage: detailPackageSeo?.metaData?.metaImage || '',
      keywords: detailPackageSeo?.metaData?.keywords || '',
    },
    resolver: zodResolver(packageSeoSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  // Init react hook form - end

  const onSubmit = async (data: IHomeSeoDetail) => {
    try {
      const response = await dispatch(createPackageSeo(data)).unwrap()

      if (response.success) {
        message.success(response?.message ?? '')
        setValue(
          'metaTitle',
          response?.data?.seoPackagePage?.metaData?.metaTitle || ''
        )
        setValue(
          'metaDescription',
          response?.data?.seoPackagePage?.metaData?.metaDescription || ''
        )
        setValue(
          'canonical',
          response?.data?.seoPackagePage?.metaData?.canonical || ''
        )
        setValue(
          'metaImage',
          response?.data?.seoPackagePage?.metaData?.metaImage || ''
        )
        setValue(
          'keywords',
          response?.data?.seoPackagePage?.metaData?.keywords || ''
        )
      }
    } catch (error: any) {
      message.error(error?.message ?? '')
    }
  }

  const handleClickSave = () => {
    handleSubmit(onSubmit)()
  }
  const handleClickCancel = () => {
    setValue('metaTitle', detailPackageSeo?.metaData?.metaTitle || '', {
      shouldDirty: false,
    })
    setValue(
      'metaDescription',
      detailPackageSeo?.metaData?.metaDescription || '',
      { shouldDirty: false }
    )
    setValue('canonical', detailPackageSeo?.metaData?.canonical || '', {
      shouldDirty: false,
    })
    // setValue('schemaMarkup', detailPackageSeo?.metaData?.schemaMarkup || '')
    setValue('metaImage', detailPackageSeo?.metaData?.metaImage || '', {
      shouldDirty: false,
    })
    setValue('keywords', detailPackageSeo?.metaData?.keywords || '', {
      shouldDirty: false,
    })

    clearErrors()
  }

  useEffect(() => {
    if (activeTab === EPackagePageTabs.SEO_CONFIG) {
      const getPackageSeoData = async () => {
        try {
          const response = await dispatch(getPackageSeo()).unwrap()
          if (response.success) {
            setValue('metaTitle', response.data?.metaData?.metaTitle)
            setValue(
              'metaDescription',
              response.data?.metaData?.metaDescription
            )
            setValue('canonical', response.data?.metaData?.canonical)
            // setValue('schemaMarkup', response.data?.metaData?.schemaMarkup)
            setValue('metaImage', response.data?.metaData?.metaImage)
            setValue('keywords', response.data?.metaData?.keywords)
          }
        } catch (error: any) {
          message.error(error?.message ?? '')
        }
      }
      getPackageSeoData()
    }
  }, [activeTab])

  return (
    <Fragment>
      {isGetPackageSeoLoading ? (
        <>
          <Skeleton
            paragraph={{ rows: 4 }}
            style={{
              marginTop: 10,
            }}
          />
        </>
      ) : (
        <div className="bg-white">
          <MetaTab
            activeTab={activeTab}
            control={control}
            getValues={getValues}
            setValue={setValue}
            setIsUploading={setIsUploading}
            isShowSelectMedia
            category={EMediaCategory.HOME_PAGE}
          />

          <div className="term-policy-action mr-5 pb-5 flex items-center justify-end gap-6">
            {/* <Button
              type="default"
              size="small"
              id="blog-form"
              onClick={() => {
                handleClickCancel()
              }}
              disabled={!isDirty}
            >
              Reset
            </Button> */}
            <Button
              type="primary"
              size="small"
              id="blog-form"
              onClick={() => {
                handleClickSave()
              }}
              loading={isGetPackageSeoLoading || isCreatePackageSeoLoading}
              disabled={!isDirty}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default PackageSeoTab
