import { INITIAL_PAGINATION_SiZE } from '@configs'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { teacherManagementAPI } from '@api'
import {
  ICreateTeacherAuthorizedRequest,
  ICreateTeacherLLCRequest,
  IGetTeacherParams,
  ITeacherAcademyPricing,
  ITeacherBecomeInstructorRequest,
  ITeacherFAQContent,
} from 'src/interfaces/teacher'
import { handleAPIError } from 'src/utils/handleApiError'

export const getAllTeachersAction = createAsyncThunk(
  'teacher/getAllTeachersAction',
  async (params: IGetTeacherParams | undefined) => {
    try {
      const localParams = params
        ? params
        : {
            page: 1,
            limit: INITIAL_PAGINATION_SiZE,
          }
      const res = await teacherManagementAPI.getListTeacher(localParams)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const createTeacherLLCAction = createAsyncThunk(
  'teacher/createTeacherLLCAction',
  async (params: ICreateTeacherLLCRequest) => {
    try {
      const res = await teacherManagementAPI.createTeacherLLC(params)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const createTeacherAuthorizedAction = createAsyncThunk(
  'teacher/createTeacherAuthorizedAction',
  async (params: ICreateTeacherAuthorizedRequest) => {
    try {
      const res = await teacherManagementAPI.createTeacherAuthorized(params)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const deleteTeacherByIdAction = createAsyncThunk(
  'teacher/deleteTeacherByIdAction',
  async (id: number) => {
    try {
      const res = await teacherManagementAPI.deleteTeacherById(id)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getTeacherByIdAction = createAsyncThunk(
  'teacher/getTeacherByIdAction',
  async (id: number) => {
    try {
      const res = await teacherManagementAPI.getTeacherById(id)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const updateTeacherByIdAction = createAsyncThunk(
  'teacher/updateTeacherByIdAction',
  async (params: {
    id: number
    data: {
      phone: string
      name: string
      profilePhotoId?: number
      description: string
      status: string
    }
  }) => {
    try {
      const res = await teacherManagementAPI.updateTeacherById(
        params.id,
        params.data
      )
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getTeacherBecomeInstructorAction = createAsyncThunk(
  'teacher/getTeacherBecomeInstructorAction',
  async () => {
    try {
      const res = await teacherManagementAPI.getTeacherBecomeInstructor()
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const updateTeacherBecomeInstructorAction = createAsyncThunk(
  'teacher/updateTeacherBecomeInstructorAction',
  async (params: ITeacherBecomeInstructorRequest) => {
    try {
      const res = await teacherManagementAPI.updateTeacherBecomeInstructor(
        params
      )
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getTeacherSEOConfigureAction = createAsyncThunk(
  'teacher/getTeacherSEOConfigureAction',
  async () => {
    try {
      const res = await teacherManagementAPI.getTeacherSEOConfigure()
      return res.data
    } catch (error: any) {
      handleAPIError(error)
    }
  }
)

export const updateTeacherSEOConfigureAction = createAsyncThunk(
  'teacher/updateTeacherSEOConfigureAction',
  async (params: any) => {
    try {
      const res = await teacherManagementAPI.updateTeacherSEOConfigure(params)
      return res.data
    } catch (error: any) {
      handleAPIError(error)
    }
  }
)

export const getTeacherPricingAction = createAsyncThunk(
  'teacher/getTeacherPricingAction',
  async () => {
    try {
      const res = await teacherManagementAPI.getTeacherPricing()
      return res.data
    } catch (error: any) {
      handleAPIError(error)
    }
  }
)

export const updateTeacherPricingAction = createAsyncThunk(
  'teacher/updateTeacherPricingAction',
  async (params: ITeacherAcademyPricing) => {
    try {
      const res = await teacherManagementAPI.updateTeacherPricing(params)
      return res.data
    } catch (error: any) {
      handleAPIError(error)
    }
  }
)

export const getTeacherFAQContentAction = createAsyncThunk(
  'teacher/getTeacherFAQContentAction',
  async () => {
    try {
      const res = await teacherManagementAPI.getTeacherFAQContent()
      return res.data
    } catch (error: any) {
      handleAPIError(error)
    }
  }
)

export const updateTeacherFAQContentAction = createAsyncThunk(
  'teacher/updateTeacherFAQContentAction',
  async (params: ITeacherFAQContent) => {
    try {
      const res = await teacherManagementAPI.updateTeacherFAQContent(params)
      return res.data
    } catch (error: any) {
      handleAPIError(error)
    }
  }
)

export const getTeacherFAQListAction = createAsyncThunk(
  'teacher/getTeacherFAQListAction',
  async () => {
    try {
      const res = await teacherManagementAPI.getTeacherFAQList()
      return res.data
    } catch (error: any) {
      handleAPIError(error)
    }
  }
)

export const getTeacherFAQDetailAction = createAsyncThunk(
  'teacher/getTeacherFAQDetailAction',
  async (id: number) => {
    try {
      const res = await teacherManagementAPI.getTeacherFAQDetail(id)
      return res.data
    } catch (error: any) {
      handleAPIError(error)
    }
  }
)

export const createTeacherFAQDetailAction = createAsyncThunk(
  'teacher/updateTeacherFAQDetailAction',
  async (params: ITeacherFAQContent) => {
    try {
      const res = await teacherManagementAPI.createTeacherFAQDetail(params)
      return res.data
    } catch (error: any) {
      handleAPIError(error)
    }
  }
)

export const updateTeacherFAQDetailAction = createAsyncThunk(
  'teacher/updateTeacherFAQDetailAction',
  async (props: { id: number; params: ITeacherFAQContent }) => {
    const { id, params } = props
    try {
      const res = await teacherManagementAPI.updateTeacherFAQDetail(id, params)
      return res.data
    } catch (error: any) {
      handleAPIError(error)
    }
  }
)
export const deleteTeacherFAQContentAction = createAsyncThunk(
  'teacher/deleteTeacherFAQContentAction',
  async (id: number) => {
    try {
      const res = await teacherManagementAPI.deleteTeacherFAQDetail(id)
      return res.data
    } catch (error: any) {
      handleAPIError(error)
    }
  }
)

export const updateTeacherFaqDisplayAction = createAsyncThunk(
  'teacher/updateTeacherFaqDisplayAction',
  async (params: { ids: number[] }) => {
    try {
      const res = await teacherManagementAPI.updateTeacherFaqDisplay(params)
      return res.data
    } catch (error: any) {
      handleAPIError(error)
    }
  }
)
