export const API_LIST_TEST_MANAGEMENT = '/final-test-section-histories'
export const API_LIST_TEACHER_MANAGEMENT = '/teachers'
export const API_SUBMIT_GRADE_TEST =
  '/final-test-section-histories/submit-grading'
export const API_ASSIGN_TEACHER = '/teachers/assign-grading'
export const API_ACCEPT_ASSIGNMENT = '/teachers/accept'
export const API_REJECT_ASSIGNMENT = '/teachers/response-assignment'
export const API_GET_MINI_DASHBOARD = `${API_LIST_TEST_MANAGEMENT}/dashboard`
export const API_LIST_REJECTION = '/assignment-responses'
export const API_LIST_MESSAGE_WITH_USER = '/messages/users'
export const API_LIST_CHAT = '/messages/chat/courses'
export const API_COURSES = '/courses'
export const API_LIST_PERMISSION_ROLES = '/roles/permissions'
export const API_ROLES = '/roles'
export const API_TEACHER_REQUESTS = '/teacher-requests'
export const API_COURSE_REQUESTS = '/course-requests'
export const API_COURSE_REQUESTS_MINI_DASHBOARD = `${API_COURSE_REQUESTS}/mini-dashboard`

export const API_COURSE_INSIGHT_OVERVIEW = '/statistics/academy/course-insight-overview'
export const API_COURSE_GROWTH = '/statistics/academy/course-growth'

export const API_TOP_REVENUE = '/statistics/academy/top-revenue'
export const API_ACADEMY_OVERVIEW = '/statistics/academy/overview'

export const API_REVENUE_COURSE_PURCHASE = '/statistics/revenue/course-purchase'
export const API_TEACHER_OVERVIEW = '/statistics/teacher/teacher-overview'

export const API_TEACHER_GROWTH = '/statistics/teacher/teacher-growth'
export const API_WITHDRAWAL_AMOUNT = '/statistics/revenue/withdrawal-amount'

export const API_REVENUE_TEACHER_PACKAGE = '/statistics/revenue/teacher-package'

export const API_LIST_POPUP_COURSES = '/courses/pop-up'

export const API_SAVE_POPUP = '/popup'
export const API_LIST_POPUP = '/popup'

export const API_DELETE_POPUP = '/popup'
export const API_POPUP_DETAIL = '/popup'

export const API_PUBLISH_POPUP = '/popup/publish'






