import { upperCase } from 'lodash'
import { t } from 'i18next'
import * as z from 'zod'
import { ColumnsType } from 'antd/lib/table'
import { Typography } from 'antd'

import { TableTitleWrapperStyled } from '../../styled'
import { IMedia } from 'src/interfaces/course'
import { CONTENT_MANAGEMENT_VALIDATION } from '@configs'

export const HOW_TO_TABLE_COLUMNS: ColumnsType<any> = [
  {
    width: '15%',
    title: upperCase(t('dashboard:no')),
    dataIndex: 'no',
    key: 'no',
    ellipsis: true,
    align: 'center',
    render: (e: string) => {
      return (
        <Typography.Text className="!m-0 !text-[14px] !text-wrap">
          {e}
        </Typography.Text>
      )
    },
  },
  {
    width: '50%',
    align: 'center',
    dataIndex: 'title',
    key: 'title',
    title: () => {
      return (
        <TableTitleWrapperStyled className="w-full !text-center">
          <p className="m-0">{t('teacher:title')}</p>
        </TableTitleWrapperStyled>
      )
    },
    render: (e: string) => {
      return (
        <Typography.Text className="!m-0 !text-[14px] !text-wrap">
          {e}
        </Typography.Text>
      )
    },
  },
  {
    width: '20%',
    align: 'center',
    dataIndex: 'media',
    key: 'media',
    title: () => {
      return (
        <TableTitleWrapperStyled className="w-full !text-center">
          <p className="m-0">{t('teacher:image')}</p>
        </TableTitleWrapperStyled>
      )
    },
    render: (media: IMedia) => {
      return (
        <div className="flex items-center justify-center w-full">
          {!!media?.url ? (
            <img
              src={media.url}
              alt={media?.name}
              className="object-cover w-14 h-14"
            />
          ) : null}
        </div>
      )
    },
  },
  {
    width: '10%',
    align: 'center',
    dataIndex: 'action',
    key: 'action',
    title: () => {
      return (
        <TableTitleWrapperStyled className="w-full !text-center">
          <p className="m-0">{t('teacher:action')}</p>
        </TableTitleWrapperStyled>
      )
    },
  },
]

export const HOW_TO_CONTENT_DEFAULT_VALUES = {
  title: '',
  titleInDutch: '',
  subTitle: '',
  subTitleInDutch: '',
  button: '',
  buttonInDutch: '',
}

export const howToContentSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  subTitle: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_SUBTITLE, {
      message: t('error:max_length_100') as string,
    }),
  subTitleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_SUBTITLE, {
      message: t('error:max_length_100') as string,
    }),
  button: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_CTA_BUTTON_NAME, {
      message: t('error:max_length_100') as string,
    }),
  buttonInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_CTA_BUTTON_NAME, {
      message: t('error:max_length_100') as string,
    }),
})

export const HOW_TO_DETAIL_DEFAULT_VALUES = {
  no: 1,
  title: '',
  titleInDutch: '',
  mediaDescription: '',
  mediaDescriptionInDutch: '',
  mediaId: 0,
  mediaMobileId: 0,
}

export const howToDetailSchema = z.object({
  no: z.number().min(1, {
    message: t('error:min_1') as string,
  }),
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  mediaDescription: z
    .string()
    .trim()
    .min(CONTENT_MANAGEMENT_VALIDATION.MIN_LENGTH_DESCRIPTION, {
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_DESCRIPTION, {
      message: t('error:max_length_500') as string,
    }),
  mediaDescriptionInDutch: z
    .string()
    .trim()
    .min(CONTENT_MANAGEMENT_VALIDATION.MIN_LENGTH_DESCRIPTION, {
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_DESCRIPTION, {
      message: t('error:max_length_500') as string,
    }),
  mediaId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
  mediaMobileId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
})
