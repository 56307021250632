import React from 'react'

import VideoIntro from './video-intro/VideoIntro'

type TeacherVideoIntroProps = {
  activeTab: string
}

export const TeacherVideoIntro = (props: TeacherVideoIntroProps) => {
  return (
    <div>
      <VideoIntro {...props} />
    </div>
  )
}
