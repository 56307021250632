/* eslint-disable jsx-a11y/anchor-is-valid */

import { DeleteOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons'
import {
  ETableTitleAboutUsManagementCoreValue,
  HOME_PAGE_CONTENT,
  PLACEHOLDER_IMAGE_AVATAR,
  thunkActionLoading
} from '@configs'
import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { CSS } from '@dnd-kit/utilities'
import { IPhotoMedia } from '@interfaces'
import {
  addNewContent,
  deleteContentHomePage,
  editContent,
  editPositionContent,
  getListContentHomePage,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Card, Empty, message, Skeleton, Table, Tooltip } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'
import { Button } from 'src/common'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'

import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { debounce } from 'lodash'
import { HomePageContent } from 'src/interfaces/aboutus-management'
import { ContentDetailModal } from './ContentDetailModal'
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  }

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'test') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          })
        }
        return child
      })}
    </tr>
  )
}
interface IHomeSeoPageProps {
  activeTab?: string
}

export const HomepageContentManagement = ({ activeTab }: IHomeSeoPageProps) => {
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isChange, setIsChanged] = useState(false)

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
  const [selectedAboutUsId, setSelectedAboutUsId] = useState<number | null>(
    null
  )
  const [selectedRoadMap, setSelectedRoadMap] = useState<
    HomePageContent | undefined | null
  >()
  const { t } = useTranslation(['error', 'common'])
  const [noDefault, setNoDefault] = useState(0)
  const dispatch = useAppDispatch()

  const getListCoreValuesLoading = useAppSelector(
    (state: RootState) =>
      state.homePageManagement.loadings[
        thunkActionLoading.GET_LIST_HOME_CONTENT
      ]
  )
  const [dataSource, setDataSource] = useState<HomePageContent[]>([])
  const listCoreValues = useAppSelector(
    (state: RootState) => state.homePageManagement.content.data
  )

  const updatePosition = useCallback(async () => {
    try {
      const dataPosition = dataSource?.map((item, index) => ({
        id: item.id,
        no: index + 1,
      }))
      const response = await dispatch(
        editPositionContent(dataPosition)
      ).unwrap()
      message.success(response.message)
    } catch (error: any) {}
  }, [dataSource])
  const debouncedUpdatePosition = debounce(updatePosition, 2000)
  useEffect(() => {
    if (dataSource.length && !isModalLoading && isChange) {
      debouncedUpdatePosition()
    }
    // Clean up
    return () => debouncedUpdatePosition.cancel()
  }, [dataSource, debouncedUpdatePosition])
  const onClose = () => {
    setShowModal(false)
    setSelectedAboutUsId(null)
    setSelectedRoadMap(null)
  }

  const onOpen = () => {
    setSelectedAboutUsId(null)
    setShowModal(true)
  }

  const onCloseConfirmModal = () => {
    setOpenConfirmDeleteModal(false)
  }
  const _getListContent = async () => {
    dispatch(getListContentHomePage())
  }
  useEffect(() => {
    if (activeTab === HOME_PAGE_CONTENT) {
      _getListContent()
    }
  }, [activeTab])
  useEffect(() => {
    setDataSource(listCoreValues)
    setNoDefault(listCoreValues?.length)
  }, [listCoreValues])

  const columns: any = [
    {
      dataIndex: 'test',
      align: 'center',
      key: 'test',
      width: '4%',
    },
    {
      title: 'Image',
      align: 'center',
      dataIndex: 'media',
      key: 'media',
      width: '10%',

      render: (record: IPhotoMedia) => {
        if (record) {
          if (['image/png', 'image/jpeg', 'image/jpg'].includes(record.type)) {
            return (
              <div className="flex items-center justify-center w-full">
                <img
                  src={record?.url ?? PLACEHOLDER_IMAGE_AVATAR}
                  alt={record?.url}
                  className="object-cover w-14 h-14"
                />
              </div>
            )
          }

          return (
            <div className="flex items-center justify-center w-full">
              <div className="w-14 h-14">
                <ReactPlayer
                  url={record?.url}
                  controls={true}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </div>
            </div>
          )
        } else {
          return (
            <div className="flex items-center justify-center w-full">
              <img
                src={PLACEHOLDER_IMAGE_AVATAR}
                alt={'image'}
                className="object-cover w-14 h-14"
              />
            </div>
          )
        }
      },
    },
    {
      title: 'Image mobile',
      align: 'center',
      dataIndex: 'mediaMobile',
      key: 'mediaMobile',
      width: '10%',

      render: (record: IPhotoMedia) => {
        if (record) {
          if (['image/png', 'image/jpeg', 'image/jpg'].includes(record.type)) {
            return (
              <div className="flex items-center justify-center w-full">
                <img
                  src={record?.url ?? PLACEHOLDER_IMAGE_AVATAR}
                  alt={record?.url}
                  className="object-cover w-14 h-14"
                />
              </div>
            )
          }
          return (
            <div className="flex items-center justify-center w-full">
              <div className="w-14 h-14">
                <ReactPlayer
                  url={record?.url}
                  controls={true}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </div>
            </div>
          )
        } else {
          return (
            <div className="flex items-center justify-center w-full">
              <img
                src={PLACEHOLDER_IMAGE_AVATAR}
                alt={'image'}
                className="object-cover w-14 h-14"
              />
            </div>
          )
        }
      },
    },
    {
      title: 'Content EN',
      dataIndex: 'content',
      key: 'content',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '14%',
    },
    {
      title: 'Content NL',
      dataIndex: 'contentInDutch',
      key: 'contentInDutch',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '14%',
    },
    {
      title: 'title EN',
      dataIndex: 'title',
      key: 'title',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '10%',
    },
    {
      title: 'title NL',
      dataIndex: 'titleInDutch',
      key: 'titleInDutch',
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      ellipsis: true,
      width: '10%',
    },
    {
      title: () => {
        return (
          <div className="flex items-center justify-center w-full">
            <p className="m-0">
              {ETableTitleAboutUsManagementCoreValue.ACTION}
            </p>
          </div>
        )
      },
      key: 'action',
      dataIndex: 'action',
      width: '7%',
      render: (record: HomePageContent) => (
        <div className="flex space-x-4 items-f justify-center w-full">
          <Tooltip title={t('common:edit')}>
            <EditOutlined
              className="text-lg font-light mr-2.5 cursor-pointer !text-[#184f64] hover:opacity-80"
              onClick={() => {
                setSelectedRoadMap(record)
                onOpen()
              }}
            />
          </Tooltip>

          <Tooltip title={'Delete'}>
            <DeleteOutlined
              className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-red-600`}
              onClick={() => {
                setOpenConfirmDeleteModal(true)
                setSelectedAboutUsId(record.id)
              }}
            />
          </Tooltip>
        </div>
      ),
      ellipsis: true,
    },
  ]
  const onDelete = async () => {
    //on delete about us
    try {
      if (selectedAboutUsId) {
        setIsModalLoading(true)
        const response = await dispatch(
          deleteContentHomePage(selectedAboutUsId)
        ).unwrap()
        if (response?.success) {
          message.success(response.message)
          setIsModalLoading(false)
          setOpenConfirmDeleteModal(false)
        }
      } else {
      }
    } catch (error: any) {
      message.error(error.message)
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
        delay: 250,
        tolerance: 5,
      },
    })
  )
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setIsChanged(true)
      setDataSource((prev: any) => {
        const activeIndex = prev.findIndex((i: any) => i.id === active.id)
        const overIndex = prev.findIndex((i: any) => i.id === over?.id)
        return arrayMove(prev, activeIndex, overIndex)
      })
    }
  }
  const onSave = async (data: any) => {
    try {
      if (selectedRoadMap) {
        const response = await dispatch(
          editContent({
            ...data,
            no: selectedRoadMap.no,
            id: selectedRoadMap.id,
          })
        ).unwrap()
        setShowModal(false)
        if (response?.homepageSection) message.success(response.message)
      } else {
        const response = await dispatch(
          addNewContent({
            ...data,
            no: noDefault + 1,
          })
        ).unwrap()
        setShowModal(false)
        if (response?.homepageSection) message.success(response.message)
      }
    } catch (error: any) {
      message.error(error.message)
    }
  }

  return (
    <Card>
      <div className="flex items-center justify-between py-[1.25rem] w-full">
        <div
          style={{
            flex: 1,
          }}
        />
        <Button
          htmlType="submit"
          type="primary"
          size="middle"
          className="submit__btn loginselected-btn"
          style={{
            alignSelf: 'flex-end',
          }}
          onClick={() => {
            onOpen()
          }}
        >
          {'Add new'}
        </Button>
      </div>
      {getListCoreValuesLoading ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : dataSource.length === 0 ? (
        <>
          <Empty description="no data" />
        </>
      ) : (
        <DndContext
          sensors={sensors}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={onDragEnd}
          autoScroll
        >
          <SortableContext
            // rowKey array
            items={dataSource.map((i: any) => i.id)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              components={{
                body: {
                  row: Row,
                },
              }}
              rowKey="key"
              pagination={false}
              columns={columns}
              dataSource={dataSource?.map((item: any, index: any) => {
                return {
                  ...item,
                  key: item?.id,
                  media: item?.media,
                  record: item,
                  action: item,
                  mediaMobile: item?.mediaMobile,
                }
              })}
            />
          </SortableContext>
        </DndContext>
      )}
      {showModal && (
        <ContentDetailModal
          open={showModal}
          onClose={onClose}
          isLoading={isModalLoading || !!getListCoreValuesLoading}
          changeLoading={setIsModalLoading}
          onSave={onSave}
          item={selectedRoadMap}
          noDefault={noDefault}
        />
      )}
      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          title="Delete this section "
          content="Are you sure to delete this section?"
          open={openConfirmDeleteModal}
          isLoading={!!isModalLoading}
          onDelete={onDelete}
          onClose={onCloseConfirmModal}
        />
      )}
    </Card>
  )
}
