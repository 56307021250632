import {
  IGetNotificationListRequestParam,
  INotificationListResponse,
  markAsReadNotificationResponse,
} from '@interfaces'
import { ApiClient } from './axiosClient'

export const notificationAPI = {
  getListNotifications: async (params?: IGetNotificationListRequestParam) => {
    return await ApiClient.get<INotificationListResponse>('/notifications', {
      params,
    })
  },

  getNotificationUnreadAmount: async () => {
    return await ApiClient.get<{ unreadNumber: number }>(
      '/notifications/unread-number'
    )
  },

  markNotificationAsReadById: async (notificationId: number) => {
    return await ApiClient.put<markAsReadNotificationResponse>(
      `/notifications/${notificationId}`
    )
  },

  markAllNotificationsAsRead: async () => {
    return await ApiClient.put<{ message: string }>('notifications')
  },
}
