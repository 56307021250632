import { memo, useEffect } from 'react'
import { t } from 'i18next'
import { message, Skeleton, Typography } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import styled from 'styled-components'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { AppModal } from '@components'
import { WHY_US_CONTENT_DEFAULT_VALUES, whyUsContentSchema } from './configs'
import { Button, Input } from 'src/common'
import { ButtonWrapper } from 'src/pages/teacher-request-detail/fill-in-teacher-information/style'
import { IWhyUsContentInputParams } from '@interfaces'
import {
  createEditWhyUsContentAction,
  getWhyUsContentAction,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@redux'

interface IProps {
  open: boolean
  onClose: () => void
}

export const WhyUsContentModal = memo(({ open, onClose }: IProps) => {
  const dispatch = useAppDispatch()

  const { whyUsContent, loadings } = useAppSelector(
    (state: RootState) => state.teacherLanding
  )
  const isSubmitting = loadings['createEditWhyUsContentAction']
  const isGettingWhyUsContent = loadings['getWhyUsContentAction']

  const { control, handleSubmit, reset } = useForm<IWhyUsContentInputParams>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: zodResolver(whyUsContentSchema),
    defaultValues: WHY_US_CONTENT_DEFAULT_VALUES,
  })

  const getWhyUsContent = async () => {
    await dispatch(getWhyUsContentAction())

    if (!!Object.keys(whyUsContent ?? {})?.length) {
      reset({ ...whyUsContent })
    }
  }

  const handleSubmitWhyUsContent = async (params: IWhyUsContentInputParams) => {
    try {
      const res = await dispatch(createEditWhyUsContentAction(params)).unwrap()
      if (res?.commonContent) {
        message.success({
          content: res?.message ?? t('common:save_success'),
        })
      }
    } catch (error: any) {
      message.error(error?.message)
    } finally {
      onClose()
    }
  }

  useDeepCompareEffect(() => {
    getWhyUsContent()
  }, [dispatch, whyUsContent])

  return (
    <AppModal
      open={open}
      onClose={onClose}
      haveCloseIcon
      title={
        <Typography.Title level={3} className="!m-0 !text-[32px]">
          {t('teacher:why_us_content')}
        </Typography.Title>
      }
      contentClassName="!min-w-[800px]"
    >
      {!!isGettingWhyUsContent ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <WhyUsContentWrapper>
          <Controller
            name="title"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Title"
                  value={value}
                  onChange={onChange}
                  required
                  label="Title"
                  alignment="col"
                  errors={error?.message}
                />
              )
            }}
          />
          <Controller
            name="titleInDutch"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Title in Netherlands"
                  value={value}
                  onChange={onChange}
                  required
                  label="Title in Netherlands"
                  alignment="col"
                  errors={error?.message}
                />
              )
            }}
          />
          <Controller
            name="subTitle"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Sub-title"
                  value={value}
                  onChange={onChange}
                  required
                  label="Sub-title"
                  alignment="col"
                  errors={error?.message}
                />
              )
            }}
          />
          <Controller
            name="subTitleInDutch"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Sub-title in Netherlands"
                  value={value}
                  onChange={onChange}
                  required
                  label="Sub-title in Netherlands"
                  alignment="col"
                  errors={error?.message}
                />
              )
            }}
          />

          <ButtonWrapper className="w-full col-span-2 !justify-center !items-center">
            <Button
              size="small"
              className="!text-[16px] !text-white !bg-gray-400 !border-none"
              onClick={onClose}
            >
              {t('common:cancel')}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              className="!text-[16px] !text-white !border-none"
              onClick={handleSubmit(handleSubmitWhyUsContent)}
              loading={isSubmitting}
            >
              {t('common:save')}
            </Button>
          </ButtonWrapper>
        </WhyUsContentWrapper>
      )}
    </AppModal>
  )
})

const WhyUsContentWrapper = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
`
