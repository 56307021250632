import { EPackagePageTabs, thunkActionLoading } from '@configs'
import { Card, message } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { zodResolver } from '@hookform/resolvers/zod'
import { RootState, selectContentLoading, useAppDispatch } from '@redux'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import { IDataMemberTab } from 'src/interfaces/package-page-management'
import {
  createPackageMember,
  getPackageMember,
  updateCommunity,
} from 'src/redux/actions/content-management'
import { z } from 'zod'
import { InputWrapperGridStyled } from '@theme'

type Props = { activeTab?: string }

const schema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(100, {
      message: t('error:title_blog_list_length_error') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(100, {
      message: t('error:title_netherlands_blog_list_length_error') as string,
    }),
  content: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:content_community_length_error') as string,
    }),
  contentInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(255, {
      message: t('error:content_community_length_error') as string,
    }),
  mediaId: z.number().min(1, { message: t('error:field_required') as string }),
  mediaMobileId: z
    .number()
    .min(1, { message: t('error:field_required') as string }),
})

const MemberTab = ({ activeTab }: Props) => {
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)

  const dispatch = useAppDispatch()
  const member = useSelector(
    (state: RootState) => state.content.packagePage?.member
  )

  const getMemberLoading = useSelector((state: RootState) =>
    selectContentLoading(
      state,
      thunkActionLoading.GET_LIST_COMMUNITY_CONTENT_LOADING
    )
  )

  const [reset, setReset] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [imageSrc, setImageSrc] = useState(member?.media?.url ?? '')
  const [imageMobileSrc, setImageMobileSrc] = useState(
    member?.mediaMobile?.url ?? ''
  )
  const [showCancelModal, setShowCancelModal] = useState(false)

  const [isChange, setIsChange] = useState(false)

  // Init hook form - start
  const defaultValue = {
    title: '',
    titleInDutch: '',
    content: '',
    contentInDutch: '',
    mediaId: undefined,
    mediaMobileId: undefined,
  }

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { isDirty },
  } = useForm<IDataMemberTab>({
    defaultValues: defaultValue,
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setImageSrc(data?.baseUrl ?? '')
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
    } else if (selectedField === 'mobile') {
      setImageMobileSrc(data?.baseUrl ?? '')
      setValue('mediaMobileId', data?.id ?? 0)
      onChangeMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectMedia(false)
    setIsChange(true)
  }

  useEffect(() => {
    if (activeTab === EPackagePageTabs.MEMBER) {
      dispatch(getPackageMember())
    }
  }, [activeTab])

  useEffect(() => {
    setValue('mediaId', member?.mediaId ?? 0)
    setValue('mediaMobileId', member?.mediaMobileId ?? 0)
    setValue('title', member?.title ?? '')
    setValue('titleInDutch', member?.titleInDutch ?? '')
    setValue('content', member?.content ?? '')
    setValue('contentInDutch', member?.contentInDutch ?? '')
    setImageSrc(member?.media?.url ?? '')
    setImageMobileSrc(member?.mediaMobile?.url ?? '')
    setIsChange(false)
  }, [member])

  const onCancel = () => {
    //
    setValue('mediaId', member?.mediaId ?? 0)
    setValue('mediaMobileId', member?.mediaMobileId ?? 0)
    setValue('title', member?.title ?? '')
    setValue('titleInDutch', member?.titleInDutch ?? '')
    setValue('content', member?.content ?? '')
    setValue('contentInDutch', member?.contentInDutch ?? '')
    setImageSrc(member?.media?.url ?? '')
    setImageMobileSrc(member?.mediaMobile?.url ?? '')
    setReset((r) => !r)
    setIsChange(false)
  }

  const onSave = async (data: IDataMemberTab) => {
    try {
      const response = await dispatch(createPackageMember(data)).unwrap()
      if (response?.success) {
        message.success(response.message)
        setIsChange(false)
      }
    } catch (error) {
      throw error
    }
  }

  const onClickCancel = () => {
    setShowCancelModal(true)
  }

  const onCloseCancelModal = () => {
    setShowCancelModal(false)
  }

  const onConfirmCancel = () => {
    onCancel()
    setShowCancelModal(false)
  }

  return (
    <Card>
      <form>
        <InputWrapperGridStyled>
        <Controller
          name="title"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label="Title"
                required
                size="middle"
                value={value}
                onChange={(e) => {
                  onChange(e?.target?.value)
                  setIsChange(true)
                }}
                errors={error?.message}
                alignment="col"
              />
            )
          }}
        />
        <Controller
          name="titleInDutch"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Input
                label="Title in Netherlands"
                required
                size="middle"
                value={value}
                onChange={(e) => {
                  onChange(e?.target?.value)
                  setIsChange(true)
                }}
                errors={error?.message}
                alignment="col"
              />
            )
          }}
        />
        <Controller
          name="content"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextArea
                label="Content"
                name="content"
                required
                onChange={(e) => {
                  onChange(e?.target?.value)
                  setIsChange(true)
                }}
                value={value}
                errors={error?.message}
                alignment="col"
                containerClassName="mt-3"
              />
            )
          }}
        />
        <Controller
          name="contentInDutch"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <TextArea
                label="Content in Netherlands"
                name="contentInDutch"
                required
                onChange={(e) => {
                  onChange(e?.target?.value)
                  setIsChange(true)
                }}
                value={value}
                errors={error?.message}
                alignment="col"
                containerClassName="mt-3"
              />
            )
          }}
        />

        <div className="flex flex-col gap-1">
        <Controller
          name={'mediaId'}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            onChangeDesktopRef.current = onChange
            return (
              <>
                <CustomDragger
                  label="Image"
                  containerClassName="mt-[10px]"
                  alignment="col"
                  onLoadEnd={(data) => {
                    setValue('mediaId', data?.id ?? 0)
                    onChange(data?.id ?? 0)
                    setIsChange(true)
                  }}
                  name="mediaId"
                  id="mediaId"
                  required
                  errors={error?.message}
                  multiple={false}
                  initResource={imageSrc}
                  reset={reset}
                  allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                  limitFileSize={5}
                  changeLoading={setIsModalLoading}
                  uploadType={EMediaType.IMAGE as any}
                  uploadCategory={EMediaCategory.HOME_PAGE}
                />
              </>
            )
          }}
        />
        <SelectMedia
          isNotGrid
          onClickSelectMedia={() => {
            setSelectedField((pre) => 'desktop')
            setShowModalSelectMedia(true)
          }}
        />
        </div>

        <div className="flex flex-col gap-1">
        <Controller
          name={'mediaMobileId'}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            onChangeMobileRef.current = onChange
            return (
              <>
                <CustomDragger
                  label="Image mobile"
                  containerClassName="mt-[10px]"
                  alignment="col"
                  onLoadEnd={(data) => {
                    setValue('mediaMobileId', data?.id ?? 0)
                    onChange(data?.id ?? 0)
                    setIsChange(true)
                  }}
                  name="mediaMobileId"
                  id="mediaMobileId"
                  required
                  errors={error?.message}
                  multiple={false}
                  initResource={imageMobileSrc}
                  reset={reset}
                  allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                  limitFileSize={5}
                  changeLoading={setIsModalLoading}
                  uploadType={EMediaType.IMAGE as any}
                  uploadCategory={EMediaCategory.HOME_PAGE}
                />
              </>
            )
          }}
        />
        <SelectMedia
          isNotGrid
          onClickSelectMedia={() => {
            setSelectedField((pre) => 'mobile')
            setShowModalSelectMedia(true)
          }}
        />
        </div>
        </InputWrapperGridStyled>
      </form>
      <div
        style={{
          display: 'flex',
          marginTop: 24,
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        />
        {/* <Button
          htmlType="submit"
          size="middle"
          className="submit__btn loginselected-btn"
          style={{
            alignSelf: 'flex-end',
            marginRight: 24,
          }}
          disabled={!isDirty || !isChange}
          onClick={onClickCancel}
        >
          {'Reset'}
        </Button> */}
        <Button
          htmlType="submit"
          type="primary"
          size="middle"
          className="submit__btn loginselected-btn"
          style={{
            alignSelf: 'flex-end',
          }}
          loading={isModalLoading || getMemberLoading}
          disabled={!isDirty || !isChange}
          onClick={handleSubmit(onSave)}
        >
          {'Save'}
        </Button>
      </div>
      <ConfirmDeleteModal
        title="Reset all data"
        content="Are you sure you want to discard all your change?"
        open={showCancelModal}
        isLoading={!!isModalLoading}
        onDelete={onConfirmCancel}
        onClose={onCloseCancelModal}
      />
      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.HOME_PAGE}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </Card>
  )
}

export default MemberTab
