/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { Skeleton } from 'antd'

import {
  EFilterBy,
  getStatisticChartIncome,
  getStatisticChartUSer,
  selectDashboardLoading,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { thunkActionLoading } from '@configs'
import { UserAndIncomeChart } from 'src/components/dashboard/chart/UserAndIncomeChart'
import { themes } from '@theme'
import { handleAPIError } from 'src/utils/handleApiError'
import { EOverviewDateFilter } from '@pages'
import { getCurrentYear } from '@utils'

type Props = {}

const enum EFilterType {
  USER = 'USER',
  INCOME = 'INCOME',
}

export const StatisticChart = (props: Props) => {
  const dispatch = useAppDispatch()
  const { statisticChartUSer, statisticChartUSerIncome } = useAppSelector(
    (state) => state.dashboard
  )

  const getStatisticUserLoading = useAppSelector((state) =>
    selectDashboardLoading(
      state,
      thunkActionLoading.GET_STATISTIC_CHART_LOADING
    )
  )
  const getStatisticIncomeLoading = useAppSelector((state) =>
    selectDashboardLoading(
      state,
      thunkActionLoading.GET_STATISTIC_CHART_INCOME_LOADING
    )
  )

  const [userFilter, setUserFilter] = useState<string | null>(null)

  const [incomeFilter, setIncomeFilter] = useState<string | null>(null)

  useEffect(() => {
    if (!userFilter) return
    dispatch(getStatisticChartUSer(userFilter))
  }, [userFilter, dispatch])

  useEffect(() => {
    if (!incomeFilter) return
    dispatch(getStatisticChartIncome(incomeFilter))
  }, [incomeFilter, dispatch])

  return (
    <div className="pt-3 relative mt-8">
      {getStatisticIncomeLoading || getStatisticUserLoading ? (
        <div style={{ height: '500px', width: '100%' }}>
          <Skeleton
            active
            loading={!getStatisticIncomeLoading || !getStatisticUserLoading}
          />
          <Skeleton
            active
            loading={!getStatisticIncomeLoading || !getStatisticUserLoading}
          />
          <Skeleton
            active
            loading={!getStatisticIncomeLoading || !getStatisticUserLoading}
          />
        </div>
      ) : (
        <div className="flex gap-3">
          <UserAndIncomeChart
            title={'User'}
            containerClassName="flex-1"
            chartData={statisticChartUSer ?? []}
            backgroundColor={themes.theme.dark.colors.miniDashboard.darkOrange}
            borderColor={themes.theme.dark.colors.miniDashboard.darkOrange}
            onChangeFilter={(value) => setUserFilter(value)}
          />
          <UserAndIncomeChart
            title={'Income'}
            containerClassName="flex-1"
            chartData={statisticChartUSerIncome ?? []}
            backgroundColor={themes.theme.dark.colors.miniDashboard.darkBlue}
            borderColor={themes.theme.dark.colors.miniDashboard.darkBlue}
            onChangeFilter={(value) => setIncomeFilter(value)}
            isPriceChart
          />
        </div>
      )}
    </div>
  )
}
