import { MAIN_THEME_DATA } from '@configs'
import { opacityHex } from '@theme'
import { Select } from 'antd'
import {
  StyledLabel,
  StyledSelectInputContainer,
} from 'src/components/shared/select/SelectInput'
import styled from 'styled-components'

interface IMultiSelectInputType {
  options: { label: string; value: string }[]
  handleChange: (value: string[]) => void
  label?: string
  required?: boolean
  errors?: string
  containerClassName?: string
  labelClassName?: string
  inlineLabelClassName?: string
  placeholder?: string
  value?: string[]
}

export const MultiSelectInput = (props: IMultiSelectInputType) => {
  const {
    options,
    handleChange,
    label,
    required,
    errors,
    containerClassName,
    labelClassName,
    inlineLabelClassName,
    value = [],
    ...rest
  } = props

  return (
    <StyledSelectInputContainer className={containerClassName}>
      {label && (
        <StyledLabel className={'app-select__label ' + labelClassName}>
          <label className={'label ' + inlineLabelClassName}>
            {label}
            {required && <span className="required"> * </span>}
          </label>
        </StyledLabel>
      )}

      <StyledMultiSelectInput
        mode="multiple"
        allowClear
        style={{ width: '100%', minHeight: '46px' }}
        placeholder="Please select"
        onChange={(value: any) => handleChange(value)}
        options={options}
        value={value}
        {...rest}
      />

      {errors && <p className="text-[rgb(185,28,28)] mt-2 text-sm">{errors}</p>}
    </StyledSelectInputContainer>
  )
}

const StyledMultiSelectInput = styled(Select)`
  width: 100%;
  border-radius: 0.625rem;
  height: fit-content;

  .ant-select-selector {
    padding: 0.25rem 0.5rem !important;
    height: 100% !important;
  }
  .ant-select-selection-search-input {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .ant-select-selection-item {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    align-items: center;
    display: flex;
  }
  .ant-select-selection-search {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline-start: 0px;
  }
  .ant-select-selector {
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    color: #495057;
    border-color: #e2e8f0 !important;
    border-radius: 0.375rem !important;
    transition: outline 0.1s !important;
    &:focus,
    &:focus-within,
    &:focus-visible {
      box-shadow: none !important;
      border-color: #e2e8f0 !important;
      outline: 4px solid ${MAIN_THEME_DATA.mainColor + opacityHex[20]}!important;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: transparent !important;
    border: 0 !important;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: transparent !important;
    border: 0 !important;
  }

  .ant-select-arrow {
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
  }
  .ant-select-selection-item {
    font-weight: 400 !important;
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    font-size: 0.875rem !important;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  .suffix-icon {
    margin-top: -0.375rem;
    transform: rotate(-180deg);
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      background: #fafafa;
      cursor: default;
      color: #00000040;
    }
  }
`
