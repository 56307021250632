import { ReactNode } from 'react'

export enum ENotificationType {
  // SEND_FRIEND_REQUEST = 'SEND_FRIEND_REQUEST',
  // FRIEND_REQUEST = 'FRIEND_REQUEST',
  // REQUEST_JOIN_CHAT_ROOM = 'REQUEST_JOIN_CHAT_ROOM',
  // ACCEPT_JOIN_CHAT_ROOM = 'ACCEPT_JOIN_CHAT_ROOM',
  // INVITE_JOIN_CHAT_ROOM = 'INVITE_JOIN_CHAT_ROOM',
  // POST = 'POST',
  // COMMENT = 'COMMENT',
  // REACTION = 'REACTION',
  // CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS',
  // CREATE_POST_FAIL = 'CREATE_POST_FAIL',

  // message
  MESSAGE = 'MESSAGE',
  COURSE_MESSAGE = 'COURSE_MESSAGE',

  // Notification teacher sign up
  TEACHER_PENDING_SIGNUP = 'TEACHER_PENDING_SIGNUP',
  TEACHER_RESUBMIT_SIGNUP = 'TEACHER_RESUBMIT_SIGNUP',

  // Notification teacher publish course
  TEACHER_PUBLISH_COURSE = 'TEACHER_PUBLISH_COURSE',
  APPROVE_PUBLISH_COURSE = 'APPROVE_PUBLISH_COURSE',
  REJECT_PUBLISH_COURSE = 'REJECT_PUBLISH_COURSE',

  // Notification about test for admin and teacher
  SUBMIT_NEW_TEST = 'SUBMIT_NEW_TEST',
  ASSIGN_GRADE_TEST = 'ASSIGN_GRADE_TEST',
  ACCEPT_GRADE_TEST = 'ACCEPT_GRADE_TEST',
  REJECT_GRADE_TEST = 'REJECT_GRADE_TEST',
  FINISH_GRADE_TEST = 'FINISH_GRADE_TEST',
  UNGRADED_TEST_DEADLINE = 'UNGRADED_TEST_DEADLINE',

  // Notification about test for user in app
  TEST_GRADED = 'TEST_GRADED',
  TEST_SUBMISSION_DEADLINE = 'TEST_SUBMISSION_DEADLINE',
  TEST_DEADLINE_MISSED = 'TEST_DEADLINE_MISSED',

  // default
  DEFAULT = 'DEFAULT',
}

export enum ENotificationTab {
  NOTIFICATION_UNREAD = 'NOTIFICATION_UNREAD',
  NOTIFICATION_ALL = 'NOTIFICATION_ALL',
}

export interface INotificationTagCommonProps {
  avatar?: ReactNode | string
  title: ReactNode | string
  content: ReactNode | string
  createdAt?: string | null
  isRead?: boolean
  onClick?: () => void
}
