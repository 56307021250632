import {
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { Divider, Skeleton, message } from 'antd'
import { useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { Button, Input, TextArea } from 'src/common'
import { TStartingScreenForm } from 'src/interfaces/habit-management'
import { useAppDispatch } from 'src/redux'
import {
  getStartingScreenContentAction,
  postStartingDetailDailyRoutineAction,
} from 'src/redux/actions/habit-management'
import styled from 'styled-components'
import { z } from 'zod'

type Props = {}

const answerSchema = z.object({
  id: z.number(),
  title: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(150, 'Answer should not 150 characters'),
  titleInDutch: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(150, 'Answer NL should not excess 150 characters'),
})

const startingSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(150, 'Title should not excess 150 characters'),
  titleInDutch: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(150, 'Title NL should not excess 150 characters'),
  content: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(500, 'Content should not excess 500 characters'),
  contentInDutch: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(500, 'Content NL should not excess 500 characters'),
  question: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(150, 'Question should not excess 150 characters'),
  questionInDutch: z
    .string()
    .trim()
    .nonempty('This is a required field')
    .max(150, 'Question NL should not excess 150 characters'),
  answers: z
    .array(answerSchema)
    .min(2, {
      message: 'Answers should have at least 2 answer',
    })
    .max(4, {
      message: 'Maximum number of answers is 4',
    }),
})

const SetupStartingScreen = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<TStartingScreenForm>({
    defaultValues: {
      title: '',
      titleInDutch: '',
      content: '',
      contentInDutch: '',
      question: '',
      questionInDutch: '',
      answers: [
        {
          id: 1,
          title: '',
          titleInDutch: '',
        },
        {
          id: 2,
          title: '',
          titleInDutch: '',
        },
      ],
    },
    resolver: zodResolver(startingSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'answers',
  })

  const onSave = async (data: TStartingScreenForm) => {
    setIsLoading(true)
    try {
      await dispatch(
        postStartingDetailDailyRoutineAction({
          ...data,
        })
      )

      await getData()
    } catch (error: any) {
      message.error(error?.message)
    } finally {
      setIsLoading(false)
    }
  }

  const getData = async () => {
    try {
      const res = await dispatch(getStartingScreenContentAction()).unwrap()

      if (res.success) {
        reset({
          title: res.data.title,
          titleInDutch: res.data.titleInDutch,
          content: res.data.content,
          contentInDutch: res.data.contentInDutch,
          question: res.data.question,
          questionInDutch: res.data.questionInDutch,
          answers: res.data.answers,
        })
      }
    } catch (error) {}
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <Styled className="px-20 bg-white">
      <div className="flex items-center justify-center ">
        <div>
          <h1 className="mb-5 mt-5 text-[32px] text-center ">
            Setup Starting screen
          </h1>
        </div>
      </div>
      <div className="mt-6">
        {isLoading ? (
          <Skeleton
            paragraph={{ rows: 4 }}
            style={{
              marginTop: 10,
            }}
          />
        ) : (
          <form className="gap-5 flex flex-col max-w-[800px] mx-auto">
            <div>
              <Divider
                className="text-lg"
                orientation="left"
                orientationMargin={0}
              >
                Get started screen
              </Divider>
              <div className="w-full grid grid-cols-2 gap-4">
                <Controller
                  name="title"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label={`Title EN`}
                        name="title"
                        required
                        onChange={onChange}
                        value={value ?? ''}
                        errors={error?.message}
                        alignment="col"
                      />
                    )
                  }}
                />
                <Controller
                  name="titleInDutch"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label={`Title NL`}
                        name="title"
                        required
                        onChange={onChange}
                        value={value ?? ''}
                        errors={error?.message}
                        alignment="col"
                      />
                    )
                  }}
                />
                <Controller
                  name="content"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextArea
                        label="Content EN"
                        name="content"
                        required
                        onChange={onChange}
                        value={value}
                        errors={error?.message}
                      />
                    )
                  }}
                />
                <Controller
                  name="contentInDutch"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextArea
                        label="Content NL"
                        name="contentInDutch"
                        required
                        onChange={onChange}
                        value={value}
                        errors={error?.message}
                      />
                    )
                  }}
                />
              </div>
            </div>

            <div>
              <Divider
                className="text-lg"
                orientation="left"
                orientationMargin={0}
              >
                Question
              </Divider>
              <div className="grid grid-cols-2 gap-4">
                <Controller
                  name="question"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label={`Question EN`}
                        name="missionInDutch"
                        required
                        onChange={onChange}
                        value={value ?? ''}
                        errors={error?.message}
                        alignment="col"
                      />
                    )
                  }}
                />

                <Controller
                  name="questionInDutch"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        label={`Question NL`}
                        name="missionInDutch"
                        required
                        onChange={onChange}
                        value={value ?? ''}
                        errors={error?.message}
                        alignment="col"
                      />
                    )
                  }}
                />
              </div>
            </div>
            <div>
              <Divider
                className="text-lg"
                orientation="left"
                orientationMargin={0}
              >
                Answers
              </Divider>
              {fields.map((answer, index) => (
                <div
                  key={answer.id}
                  className="flex items-center gap-8 rounded-lg p-3"
                >
                  <div className="flex-1 w-full grid grid-cols-2 gap-4">
                    <Controller
                      name={`answers.${index}.title`}
                      control={control}
                      defaultValue={answer.title}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <Input
                            label={`Answer ${index + 1} EN`}
                            name={`answers.${index}.title`}
                            onChange={(e) => {
                              onChange(e.target.value)
                            }}
                            required
                            value={value ?? ''}
                            errors={error?.message}
                            alignment="col"
                          />
                        )
                      }}
                    />

                    <Controller
                      name={`answers.${index}.titleInDutch`}
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        return (
                          <Input
                            label={`Answer ${index + 1} NL`}
                            name={`answers.${index}.titleInDutch`}
                            onChange={(e) => {
                              onChange(e.target.value)
                            }}
                            required
                            value={value ?? ''}
                            errors={error?.message}
                            alignment="col"
                          />
                        )
                      }}
                    />
                  </div>
                  <div className="flex-[0] mt-[30px] flex items-center justify-end">
                    <div
                      className={`w-[32px] h-[32px]   flex items-center justify-center  border-gray-200 border rounded-full ${
                        fields.length <= 2
                          ? 'opacity-50'
                          : 'cursor-pointer hover:opacity-70'
                      }`}
                      onClick={() => {
                        fields.length > 2 && remove(index)
                      }}
                    >
                      <DeleteOutlined
                        className={`w-full h-full flex justify-center items-center text-lg font-light hover:opacity-80 !text-red-600`}
                        style={{ display: 'flex' }}
                      />
                    </div>
                  </div>  
                </div>
              ))}
              <div className="w-full flex justify-center">
                <Button
                  type="primary"
                  size="middle"
                  className="submit__btn loginselected-btn mt-[24px]"
                  onClick={() => {
                    append({
                      id: fields.length + 1,
                      title: '',
                      titleInDutch: '',
                    })
                  }}
                  disabled={fields.length >= 4}
                >
                  Add new answer
                </Button>
              </div>
            </div>
            <div className="mt-[80px] pb-10">
              <div className="flex items-center mt-5 justify-center">
                <Button
                  htmlType="submit"
                  type="primary"
                  size="middle"
                  className="submit__btn login-btn"
                  loading={isLoading}
                  onClick={handleSubmit(onSave, (e) => {})}
                >
                  {'Save'}
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </Styled>
  )
}

export default SetupStartingScreen

const Styled = styled('div')(() => {
  // return {
  //   '.ant-tabs-nav': {
  //     marginBottom: 0,
  //     paddingLeft: '1.25rem',

  //     '& .ant-tabs-tab': {
  //       borderRadius: '0.75rem 0.75rem 0 0 !important',

  //       '&.ant-tabs-tab-active': {
  //         span: {
  //           color: '#009BB7',
  //           fontWeight: '500',
  //         },
  //       },
  //     },
  //   },

  //   '.ant-upload.ant-upload-select-picture-card': {
  //     width: '100%',
  //     height: 'auto',
  //   },
  // }
  return {}
})
