import { objectToQueryString } from '@utils'
import { ApiClient } from 'src/api/axiosClient'
import {
  ICreateTeacherAuthorizedRequest,
  ICreateTeacherLLCRequest,
  IGetTeacherParams,
  IGetTeacherResponse,
  ITeacherAcademyPricing,
  ITeacherBecomeInstructorRequest,
  ITeacherFAQContent,
  ITeacherItem,
} from 'src/interfaces/teacher'

export const teacherManagementAPI = {
  getListTeacher: async (params: IGetTeacherParams) => {
    return await ApiClient.get<IGetTeacherResponse>('/teachers', {
      params: {
        ...params,
        ...(!!params.types && {
          types: params.types.join(','),
        }),
        sort: objectToQueryString(params.sort || {}) || undefined,
      },
    })
  },

  createTeacherLLC: async (params: ICreateTeacherLLCRequest) => {
    return await ApiClient.post<{
      message: string
      teacher: ITeacherItem
    }>('/teachers/teacher-llc', params)
  },

  createTeacherAuthorized: async (params: ICreateTeacherAuthorizedRequest) => {
    return await ApiClient.post<{
      message: string
      teacher: ITeacherItem
    }>('/teachers/teacher-authorize', params)
  },

  deleteTeacherById: async (id: number) => {
    return await ApiClient.delete<{
      message: string
      status: boolean
    }>(`/teachers/${id}`)
  },

  getTeacherById: async (id: number) => {
    return await ApiClient.get<ITeacherItem>(`/teachers/${id}`)
  },

  updateTeacherById: async (
    id: number,
    data: {
      phone: string
      name: string
      profilePhotoId?: number
      description?: string
      status: string
    }
  ) => {
    return await ApiClient.put<{
      message: string
      teacher: ITeacherItem
    }>(`/teachers/${id}`, data)
  },

  getTeacherBecomeInstructor: async () => {
    return await ApiClient.get<any>(
      `/common-contents/academy-become-instructor`
    )
  },

  updateTeacherBecomeInstructor: async (
    params: ITeacherBecomeInstructorRequest
  ) => {
    return await ApiClient.post<any>(
      `/common-contents/academy-become-instructor`,
      params
    )
  },

  getTeacherSEOConfigure: async () => {
    return await ApiClient.get<any>(`/common-contents/academy-seo-config`)
  },

  updateTeacherSEOConfigure: async (params: any) => {
    return await ApiClient.post<any>(
      `/common-contents/academy-seo-config`,
      params
    )
  },

  getTeacherPricing: async () => {
    return await ApiClient.get<any>(`/common-contents/academy-pricing`)
  },

  updateTeacherPricing: async (params: ITeacherAcademyPricing) => {
    return await ApiClient.post<any>(`/common-contents/academy-pricing`, params)
  },

  getTeacherFAQContent: async () => {
    return await ApiClient.get<any>(`/common-contents/academy-faq`)
  },

  updateTeacherFAQContent: async (params: ITeacherFAQContent) => {
    return await ApiClient.post<any>(`/common-contents/academy-faq`, params)
  },

  getTeacherFAQList: async () => {
    return await ApiClient.get<any>(`/common-contents/academy-faq-detail
 `)
  },

  getTeacherFAQDetail: async (id: number) => {
    return await ApiClient.get<any>(`/common-contents/academy-faq-detail/${id}`)
  },

  createTeacherFAQDetail: async (params: ITeacherFAQContent) => {
    return await ApiClient.post<any>(
      `/common-contents/academy-faq-detail`,
      params
    )
  },

  updateTeacherFAQDetail: async (id: number, params: ITeacherFAQContent) => {
    return await ApiClient.put<any>(
      `/common-contents/academy-faq-detail/${id}`,
      params
    )
  },

  deleteTeacherFAQDetail: async (id: number) => {
    return await ApiClient.delete<any>(`/common-contents/academy-faq-detail/${id}`)
  },

  updateTeacherFaqDisplay: async (params: {
    ids: number[]
  }) => {
    return await ApiClient.put<any>(
      `/common-contents/academy-faq-display`,
      params
    )
  }

}
