import { PATH_POPUP_MANAGEMENT } from '@configs'
import {
  CreatePopupStepOne,
  CreatePopupStepTwo,
  EPopupScreenType,
  TPopupBody,
  TPopupType,
} from '@pages'
import {
  createPopup,
  editPopup,
  getPopupCourses,
  getPopupDetail,
  publishPopup,
  useAppDispatch,
} from '@redux'
import { formatDate } from '@utils'
import { Card, message, Steps, Tabs } from 'antd'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { handleAPIError } from 'src/utils/handleApiError'

export const CreatePopup: React.FC = () => {
  const { popupId } = useParams<{ popupId: string }>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [current, setCurrent] = useState(0)
  const [isLoadingPopupCourse, setIsLoadingPopupCourse] = useState(false)
  const [listPopupCourse, setListPopupCourse] = useState<
    { label: string; value: number }[]
  >([])
  const [popupDetail, setPopupDetail] = useState<TPopupType | null>(null)

  // Fetch list of popup courses
  const fetchPopupCourses = async () => {
    setIsLoadingPopupCourse(true)
    try {
      const response = await dispatch(getPopupCourses()).unwrap()
      setListPopupCourse(
        response.map((item) => ({ label: item.title, value: item.id }))
      )
    } catch (error: any) {
      handleAPIError(error)
    } finally {
      setIsLoadingPopupCourse(false)
    }
  }

  // Fetch popup details based on the popup ID
  const fetchPopupDetail = async () => {
    if (!popupId) return

    try {
      const response = await dispatch(getPopupDetail(popupId)).unwrap()
      if (response) {
        setPopupDetail({
          ...response,
          photoUrl: response.photo.baseUrl,
          mobilePhotoUrl: response.mobilePhoto.baseUrl,
          photoId: response.photo.id,
          mobilePhotoId: response.mobilePhoto.id,
          scheduleDate: response.scheduleDate,
        })
      }
    } catch (error: any) {
      handleAPIError(error)
    }
  }

  // Save popup data
  const handleSavePopup = async (popupData: TPopupType) => {
    if (!popupData) return

    if (popupId) {
      let body: TPopupBody = {
        name: popupData.name || '',
        nameInDutch: popupData.nameInDutch || '',
        title: popupData.title || '',
        titleInDutch: popupData.titleInDutch || '',
        description: popupData.description || '',
        descriptionInDutch: popupData.descriptionInDutch || '',
        ctaButtonName: popupData.ctaButtonName || '',
        ctaButtonNameInDutch: popupData.ctaButtonNameInDutch || '',
        type: popupData.type || '',
        photoId: popupData.photoId || 0,
        mobilePhotoId: popupData.mobilePhotoId || 0,
        courseId: popupData.courseId || 0,
        isDisplayAllPage: popupData.isDisplayAllPage || false,
        displayOnWeb: popupData.displayOnWeb || [],
        displayOnMobile: popupData.displayOnMobile || [],
        scheduleDate: popupData.scheduleDate
          ? formatDate(popupData.scheduleDate, 'YYYY-MM-DD')
          : null,
        isActive: popupData.isActive,
      }

      if (popupData.isDisplayAllPage) {
        body.displayOnWeb = []
        body.displayOnMobile = []
      }

      try {
        const response = await dispatch(
          editPopup({
            id: popupId,
            params: body,
          })
        ).unwrap()
        message.success(response?.message ?? 'Save success')
      } catch (error: any) {
        handleAPIError(error)
      }
    } else {
      const body: TPopupBody = {
        name: popupData.name || '',
        nameInDutch: popupData.nameInDutch || '',
        title: popupData.title || '',
        titleInDutch: popupData.titleInDutch || '',
        description: popupData.description || '',
        descriptionInDutch: popupData.descriptionInDutch || '',
        ctaButtonName: popupData.ctaButtonName || '',
        ctaButtonNameInDutch: popupData.ctaButtonNameInDutch || '',
        type: popupData.type || '',
        photoId: popupData.photoId || 0,
        mobilePhotoId: popupData.mobilePhotoId || 0,
        courseId: popupData.courseId || 0,
        isDisplayAllPage: popupData.isDisplayAllPage || false,
        displayOnWeb: popupData.displayOnWeb || [],
        displayOnMobile: popupData.displayOnMobile || [],
        scheduleDate: popupData.scheduleDate
          ? formatDate(popupData.scheduleDate, 'YYYY-MM-DD')
          : null,
        state: popupData.state || '',
      }

      if (popupData.isDisplayAllPage) {
        body.displayOnWeb = []
        body.displayOnMobile = []
      }

      try {
        const response = await dispatch(createPopup(body)).unwrap()
        message.success(response?.message ?? 'Save success')
        navigate(PATH_POPUP_MANAGEMENT)
      } catch (error: any) {
        handleAPIError(error)
      }
    }
  }

  useEffect(() => {
    fetchPopupCourses()
  }, [])

  useEffect(() => {
    fetchPopupDetail()
  }, [popupId])

  const steps = [
    { key: 0, title: 'Content' },
    { key: 1, title: 'Behavior' },
  ]

  const next = () => setCurrent((prev) => prev + 1)
  const prev = () => setCurrent((prev) => prev - 1)

  const handleChangeStatusPopup = async (popupData?: TPopupType) => {
    if (!popupId || !popupData) return

    let body: TPopupBody = {
      name: popupData.name || '',
      nameInDutch: popupData.nameInDutch || '',
      title: popupData.title || '',
      titleInDutch: popupData.titleInDutch || '',
      description: popupData.description || '',
      descriptionInDutch: popupData.descriptionInDutch || '',
      ctaButtonName: popupData.ctaButtonName || '',
      ctaButtonNameInDutch: popupData.ctaButtonNameInDutch || '',
      type: popupData.type || '',
      photoId: popupData.photoId || 0,
      mobilePhotoId: popupData.mobilePhotoId || 0,
      courseId: popupData.courseId || 0,
      isDisplayAllPage: popupData.isDisplayAllPage || false,
      displayOnWeb: popupData.displayOnWeb || [],
      displayOnMobile: popupData.displayOnMobile || [],
      scheduleDate: popupData.scheduleDate
        ? formatDate(popupData.scheduleDate, 'YYYY-MM-DD')
        : null,
      isActive: popupData.isActive,
    }

    if (popupData.isDisplayAllPage) {
      body.displayOnWeb = []
      body.displayOnMobile = []
    }

    try {
      const response = await dispatch(
        publishPopup({
          id: popupId,
          params: body,
        })
      ).unwrap()
      message.success(response?.message ?? 'Publish success')
      navigate(PATH_POPUP_MANAGEMENT)
    } catch (error: any) {
      handleAPIError(error)
    }
  }

  const onChange = (key: string) => {
    setCurrent(Number(key))
  }

  return (
    <Card>
      {popupId ? (
        <Tabs
          items={steps.map((item) => ({
            key: item.key.toString(),
            label: item.title,
          }))}
          onChange={onChange}
        />
      ) : (
        <div className='flex items-center justify-center'>
        <Steps
          className='max-w-[30%] '
          current={current}
          items={steps.map((item) => ({ key: item.key, title: item.title }))}
        />
        </div>

      )}

      {current === EPopupScreenType.STEP1 && (
        <CreatePopupStepOne
          listPopupCourses={listPopupCourse}
          popupDetail={popupDetail}
          setPopupDetail={setPopupDetail}
          handleNextStep={next}
          popupId={popupId}
          handleSavePopup={handleSavePopup}
          isLoading={isLoadingPopupCourse}
        />
      )}

      {current === EPopupScreenType.STEP2 && (
        <CreatePopupStepTwo
          popupDetail={popupDetail}
          setPopupDetail={setPopupDetail}
          handleSavePopup={handleSavePopup}
          handlePrevStep={prev}
          popupId={popupId}
          handleChangeStatusPopup={handleChangeStatusPopup}
        />
      )}
    </Card>
  )
}
