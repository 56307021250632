import { memo } from 'react'
import { useNavigate } from 'react-router-dom'

import { getNotificationTypes } from './configs'
import { ENotificationType } from '../configs'
import { INotificationItem } from 'src/interfaces/notification'
import { NotificationTagCommon } from './NotificationTagCommon'
import { ENavBarRole } from '@configs'
import useRole from 'src/hooks/useRole'

export const NotificationTag = memo(
  ({
    notificationItem,
    onClickNotification,
  }: {
    notificationItem: INotificationItem
    onClickNotification: (notificationId: number, isRead: boolean) => void
  }) => {
    const { type } = { ...notificationItem }
    const navigate = useNavigate()
    const { currentUserRole } = useRole()
    const userRole = currentUserRole as ENavBarRole

    const notificationTypes = getNotificationTypes(
      notificationItem,
      navigate,
      onClickNotification,
      userRole
    )

    const isTypeExisted = Object.keys(ENotificationType).includes(type)

    const notificationContent = isTypeExisted
      ? notificationTypes?.[type]
      : notificationTypes?.[ENotificationType.DEFAULT]

    return (
      <NotificationTagCommon
        avatar={notificationContent?.avatar ?? ''}
        title={notificationContent?.title ?? ''}
        content={notificationContent?.content ?? ''}
        createdAt={notificationContent?.createdAt}
        isRead={notificationContent?.isRead}
        onClick={notificationContent?.onClick}
      />
    )
  }
)
