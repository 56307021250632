import { ETeacherRole } from '@configs'

export enum EStudentAnswerResult {
  PASS = 'PASS',
  FAIL = 'FAIL',
  NOT_YET = 'NOT_YET',
}

export enum EGradeTestViewMode {
  GRADE = 'GRADE',
  VIEW = 'VIEW',
}

export enum EGradingStatus {
  GRADED = 'GRADED',
  IN_PROGRESS = 'IN_PROGRESS',
  NA = 'N/A',
}

export enum EAssignStatus {
  ALL = 'ALL',
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  NA = 'N/A',
}
export interface ITestItem {
  index: number
  teacher?: {
    id: number
    name: string
    status: string
    client: ETeacherRole
  }
  key: number
  testName: string
  courseName: string
  studentName: string
  assignee?: string
  assignStatus: EAssignStatus
  gradingStatus: EGradingStatus
  studentSubmissionDate?: string | null
  gradedDate?: string | null
  gradingDeadline?: string | null
  avatar?: string
  finalTestId?: string
  studentId?: string
  finalTestSection?: IFinalTestSection
}

export interface IUser {
  name: string
}

export interface IQuestion {
  content: string
  type: string
}

export interface IPracticeFile {
  cloudfrontUrl: string
  type: string
  path: string
  url: string
}

export interface FinalTestSectionResults {
  practiceWriting: string
  isCorrected: boolean
  question: IQuestion
  practiceFile: IPracticeFile | null
  id: string
}

export interface IFinalTestSection {
  id: number
  title: number
  lessonId: number
  titleInDutch: string
  type: string
  restrictTimer: number
  createdAt: null | string
  updatedAt: null | string
  deletedAt: null | string
}

export interface IGradeTestDetailData {
  finalTestSection: IFinalTestSection
  finalTestSectionResults: FinalTestSectionResults[]
  user: IUser
  deadline: string | null
  totalCorrect: number
  totalQuestions: number
  gradedDate: string | null
  gradingStatus: EGradingStatus
  teacher: ITeacher
  gradedBy: {
    id: number
    name: string
  }
}

interface IGradingCommentItem {
  id: number
  mediaId: number
  caption: string
  media: {
    cloudfrontUrl: string
    id: number
    userId: number
    url: string
  }
}

export interface IGradingResultData extends IGradeTestDetailData {
  comment: string
  isCorrected: boolean
  gradingComment: IGradingCommentItem[]
}

export interface ISubmitGradeTest {
  finalTestSectionHistoryId: number
  userId: number
  teacherComment: {
    finalTestSectionResultId: string
    mediaAndCaptions: {
      mediaId: number
      caption: string
    }[]
    comment: string
    isCorrected: boolean
  }[]
}

export interface IGradedTestDataItem {
  id: string
  comment: string
  feedback: {
    photoImage: string
    photoCaption: string
    mediaId: number
  }[]
  status: EStudentAnswerResult
}
export interface IAssignTeacherRequestData {
  teacherId: number
  finalTestHistoryId: number
  deadline: string | null
}

export interface IMiniDashboard {
  totalUnAssignedOrPending: number
  totalGradedTest: number
  deadlineInWeek: number
}
interface ICourse {
  id: number
  title: string
}

interface ITeacher {
  id: number
  name: string
}

export interface IStudentTestItem {
  id: number
  assignStatus: EGradingStatus
  course: ICourse
  deadline: string | null
  finalTestSection: {
    id: number
    title: string
  }
  finalTestSectionId: string
  gradedDate: string | null
  gradingStatus: EGradingStatus
  teacher: ITeacher
  user: {
    id: number
    name: string
    profilePhoto: {
      cloudFrontUrl: string
    }
  }
  userId: number
}
export interface IListStudentTestData {
  items: IStudentTestItem[]
  total: number
}

export interface IRejectItem {
  id: number
  finalTestSectionHistoryId: number
  reason: string
  updatedAt: string | null
  teacher: {
    name: string
  }
}

export interface IRejectionData {
  data: IRejectItem[]
  isLoading: boolean
}
