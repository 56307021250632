import { message } from 'antd'

import { BaseResponseError } from '@interfaces'

export const handleAPIError = (error: BaseResponseError) => {
  if (!error) return

  if (typeof error.errors === 'string') {
    message.error(error.errors)
    return
  }

  const errorMessage = !!error.errors?.length ? error.errors[0] : error.message

  message.error(errorMessage)
}
