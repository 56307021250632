import * as z from 'zod'
import { t } from 'i18next'
import { EPackagePrivacy, WHITE_COLOR } from '@configs'

const accessModuleSchema = z.object({
  community: z
    .object({
      isFreeTryApp: z.boolean().optional(),
      freeTryApp: z
        .number({
          invalid_type_error: t('teacher:not_a_number'),
        })
        .min(1, { message: t('teacher:min_1') })
        .optional(),
      isFullAccess: z.boolean().optional(),
    })
    .optional(),
  productDiscount: z
    .object({
      isDiscount: z.boolean().optional(),
      discount: z
        .number({
          invalid_type_error: t('teacher:not_a_number'),
        })
        .min(1, { message: t('teacher:range_1_99') })
        .max(99, { message: t('teacher:range_1_99') })
        .optional(),
    })
    .optional(),
  userCourse: z
    .object({
      isFreeCourse: z.boolean().optional(),
      isMemberCourse: z.boolean().optional(),
      memberCourse: z
        .number({
          invalid_type_error: t('teacher:not_a_number'),
        })
        .min(1, { message: t('teacher:min_1') })
        .optional(),
      isAllMemberCourse: z.boolean().optional(),
      isPremiumCourseDiscount: z.boolean().optional(),
      premiumCourseDiscount: z
        .number({
          invalid_type_error: t('teacher:not_a_number'),
        })
        .min(0, { message: t('teacher:range_0_99') })
        .max(99, { message: t('teacher:range_0_99') })
        .optional(),
    })
    .optional(),
  bookRetreat: z
    .object({
      isFreeUpgradeRoom: z.boolean().optional(),
      isDiscountAllRoom: z.boolean().optional(),
      discountAllRoom: z
        .number({
          invalid_type_error: t('teacher:not_a_number'),
        })
        .min(1, { message: t('teacher:range_1_99') })
        .max(99, { message: t('teacher:range_1_99') })
        .optional(),
      isLimitToVisit: z.boolean().optional(),
      limitToVisit: z
        .number({
          invalid_type_error: t('teacher:not_a_number'),
        })
        .min(1, { message: t('teacher:min_1') })
        .optional(),
      isDiscountAllActivities: z.boolean().optional(),
      discountAllActivities: z
        .number({
          invalid_type_error: t('teacher:not_a_number'),
        })
        .min(1, { message: t('teacher:range_1_99') })
        .max(99, { message: t('teacher:range_1_99') })
        .optional(),
      isAdditionalFreeNight: z.boolean().optional(),
      additionalFreeNight: z
        .number({
          invalid_type_error: t('teacher:not_a_number'),
        })
        .min(1, { message: t('teacher:range_1_99') })
        .max(99, { message: t('teacher:range_1_99') })
        .optional(),
      isFreeStay: z.boolean().optional(),
    })
    .optional(),
  bookWorkshop: z
    .object({
      isDiscount: z.boolean().optional(),
      discount: z
        .number({
          invalid_type_error: t('teacher:not_a_number'),
        })
        .min(1, { message: t('teacher:range_1_99') })
        .max(99, { message: t('teacher:range_1_99') })
        .optional(),
      isFree: z.boolean().optional(),
    })
    .optional(),
  bookFestivalTicket: z
    .object({
      isEarlyBird: z.boolean().optional(),
      isDiscount: z.boolean().optional(),
      discount: z
        .number({
          invalid_type_error: t('teacher:not_a_number'),
        })
        .min(1, { message: t('teacher:range_1_99') })
        .max(99, { message: t('teacher:range_1_99') })
        .optional(),
      isFreeVipAccess: z.boolean().optional(),
      freeVipAccess: z
        .number({
          invalid_type_error: t('teacher:not_a_number'),
        })
        .min(1, { message: t('teacher:min_1') })
        .optional(),
    })
    .optional(),
  birthdayPresent: z
    .object({
      isNote: z.boolean().optional(),
      note: z.string().optional(),
    })
    .optional(),
})

export const packageSchema = z
  .object({
    type: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    name: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:name_package_length_error'),
      })
      .min(2, {
        message: t('error:name_package_length_error'),
      }),
    nameInDutch: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:name_package_length_error'),
      })
      .min(2, {
        message: t('error:name_package_length_error'),
      }),
    priceUsd: z
      .number()
      .min(0.5, {
        message: t('teacher:min_price_is_0.5') as string,
      })
      .optional(),
    priceEur: z
      .number()
      .min(0.5, {
        message: t('teacher:min_price_is_0.5') as string,
      })
      .optional(),
    mediaId: z
      .number({
        required_error: t('error:field_required') as string,
      })
      .nullable()
      .refine((value) => !!value, {
        message: t('error:field_required') as string,
      }),
    appleReference: z.string().optional(),
    textColor: z.string(),
    accessModule: accessModuleSchema,
    isActive: z.boolean().optional(),
  })
  .refine(
    (data) => {
      if (data.type === EPackagePrivacy.PUBLIC) {
        return data.priceUsd !== undefined
      }
      return true
    },
    {
      message: t('error:field_required') as string,
      path: ['priceUsd'],
    }
  )
  .refine(
    (data) => {
      if (data.type === EPackagePrivacy.PUBLIC) {
        return data.priceEur !== undefined
      }
      return true
    },
    {
      message: t('error:field_required') as string,
      path: ['priceEur'],
    }
  )

export const userPackageDefaultValues = {
  name: '',
  nameInDutch: '',
  priceUsd: undefined,
  priceEur: undefined,
  textColor: WHITE_COLOR,
  type: EPackagePrivacy.PUBLIC,
  mediaId: undefined,
  appleReference: '',
  accessModule: {
    community: {
      isFreeTryApp: false,
      freeTryApp: undefined,
      // isFullAccess: false,
    },
    productDiscount: {
      isDiscount: false,
      discount: undefined,
    },
    userCourse: {
      isFreeCourse: true,
      isMemberCourse: false,
      memberCourse: undefined,
      // isAllMemberCourse: false,
      isPremiumCourseDiscount: false,
      premiumCourseDiscount: undefined,
    },
    bookRetreat: {
      // isFreeUpgradeRoom: false,
      isDiscountAllRoom: false,
      discountAllRoom: undefined,
      isLimitToVisit: false,
      limitToVisit: undefined,
      isDiscountAllActivities: false,
      discountAllActivities: undefined,
      isAdditionalFreeNight: false,
      additionalFreeNight: undefined,
      // isFreeStay: false,
    },
    bookWorkshop: {
      isDiscount: false,
      discount: undefined,
      // isFree: false,
    },
    bookFestivalTicket: {
      // isEarlyBird: false,
      isDiscount: false,
      discount: undefined,
      isFreeVipAccess: false,
      freeVipAccess: undefined,
    },
    birthdayPresent: {
      isNote: false,
      note: undefined,
    },
  },
}
