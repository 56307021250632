import { EAboutUsPageTabs } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  getOurMissionAction,
  getOurVisionAction,
  updateOurMissionAction,
  updateOurVisionAction,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Skeleton, message } from 'antd'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button, Input, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { IOurVisionFormData } from 'src/interfaces/aboutus-management'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import * as z from 'zod'
import SelectMedia from '../gallery/SelectMedia'
import SelectMediaModal from '../gallery/SelectMediaModal'
import { InputWrapperGridStyled } from '@theme'

interface IAboutUsPageTabItemProps {
  activeTab?: string
}

const OurVisionTab = ({ activeTab }: IAboutUsPageTabItemProps) => {
  const [imageSrc, setImageSrc] = useState('')
  const [imageMobileSrc, setImageMobileSrc] = useState('')
  const [isLoadingMissionImage, setIsLoadingMissionImage] = useState(false)

  const [imageVisionSrc, setImageVisionSrc] = useState('')
  const [imageVisionMobileSrc, setImageVisionMobileSrc] = useState('')
  const [isLoadingVisionImage, setIsLoadingVisionImage] = useState(false)
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [showModalSelectMediaVision, setShowModalSelectMediaVision] =
    useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const [selectedFieldVision, setSelectedFieldVision] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const [isUploading, setIsUploading] = useState(false)
  const dispatch = useAppDispatch()

  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)

  const onChangeVisionDesktopRef = useRef<any>(null)
  const onChangeVisionMobileRef = useRef<any>(null)

  const { t } = useTranslation(['error', 'common'])

  const updateOurVisionLoading = useAppSelector(
    (state) => state.aboutUsManagement.loadings?.updateOurVisionActionLoading
  )
  const getOurVisionActionLoading = useAppSelector(
    (state) => state.aboutUsManagement.loadings?.getOurVisionActionLoading
  )

  const isDisabledSaveButton = useMemo(() => {
    return updateOurVisionLoading || isUploading
  }, [updateOurVisionLoading, isUploading])

  const ourVisionSchema = z.object({
    title: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(50, {
        message: t('error:title_our_vision_length_error') as string,
      }),
    titleInDutch: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(50, {
        message: t('error:title_our_vision_length_error') as string,
      }),
    content: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(500, {
        message: t('error:content_our_vision_length_error') as string,
      }),
    contentInDutch: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(500, {
        message: t('error:content_our_vision_length_error') as string,
      }),
    mediaId: z.number({
      required_error: t('error:field_required') as string,
    }),
    mediaMobileId: z.number({
      required_error: t('error:field_required') as string,
    }),
  })

  const { handleSubmit, control, setValue, reset } =
    useForm<IOurVisionFormData>({
      defaultValues: {
        content: '',
        contentInDutch: '',
        title: '',
        titleInDutch: '',
        mediaId: 0,
        mediaMobileId: 0,
      },
      resolver: zodResolver(ourVisionSchema),
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    })

  const {
    handleSubmit: handleSubmitVision,
    control: controlVision,
    setValue: setValueVision,
    reset: resetVision,
  } = useForm<IOurVisionFormData>({
    defaultValues: {
      content: '',
      contentInDutch: '',
      title: '',
      titleInDutch: '',
      mediaId: 0,
      mediaMobileId: 0,
    },
    resolver: zodResolver(ourVisionSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onCloseModalSelectMediaVision = () => {
    setShowModalSelectMediaVision(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setImageSrc(data?.baseUrl ?? '')
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
    } else if (selectedField === 'mobile') {
      setImageMobileSrc(data?.baseUrl ?? '')
      setValue('mediaMobileId', data?.id ?? 0)

      onChangeMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectMedia(false)
  }

  const onSelectVisionMedia = (data: IMediaItem) => {
    if (selectedFieldVision === 'desktop') {
      setImageVisionSrc(data?.baseUrl ?? '')
      setValueVision('mediaId', data?.id ?? 0)
      onChangeVisionDesktopRef.current(data?.id ?? 0)
    } else if (selectedFieldVision === 'mobile') {
      setImageVisionMobileSrc(data?.baseUrl ?? '')

      setValueVision('mediaMobileId', data?.id ?? 0)
      onChangeVisionMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectMedia(false)
  }

  const onSubmit = async (data: IOurVisionFormData) => {
    try {
      const responseCreateOurMission = await dispatch(
        updateOurMissionAction(data)
      ).unwrap()

      if (responseCreateOurMission.success) {
        message.success({
          content:
            responseCreateOurMission?.message ?? t('common:save_success'),
        })
      }
    } catch (error: any) {
      message.error({
        content: error?.message ?? t('error:failed_to_save_landing'),
      })
    }
  }

  const onSubmitVision = async (data: IOurVisionFormData) => {
    try {
      const responseCreateOurVision = await dispatch(
        updateOurVisionAction(data)
      ).unwrap()

      if (responseCreateOurVision.success) {
        message.success({
          content: responseCreateOurVision?.message ?? t('common:save_success'),
        })
      }
    } catch (error: any) {
      message.error({
        content: error?.message ?? t('error:failed_to_save_landing'),
      })
    }
  }

  const onSave = () => {
    handleSubmit(onSubmit)()
  }

  const onSaveVision = () => {
    handleSubmitVision(onSubmitVision)()
  }

  useEffect(() => {
    if (activeTab === EAboutUsPageTabs.OUR_VISION) {
      const getOurVisions = async () => {
        try {
          const responseGetOurVision = await dispatch(
            getOurVisionAction()
          ).unwrap()

          const responseGetOurMission = await dispatch(
            getOurMissionAction()
          ).unwrap()

          if (responseGetOurVision.success) {
            const ourVisionData = responseGetOurVision.data

            setValueVision('content', ourVisionData?.content)
            setValueVision('contentInDutch', ourVisionData?.contentInDutch)
            setValueVision('title', ourVisionData?.title)
            setValueVision('titleInDutch', ourVisionData?.titleInDutch)
            setValueVision('mediaId', ourVisionData?.media?.id)
            setValueVision('mediaMobileId', ourVisionData?.mediaMobile?.id)
            ourVisionData?.media?.url &&
              setImageVisionSrc(ourVisionData.media.url)
            ourVisionData?.mediaMobile?.url &&
              setImageVisionMobileSrc(ourVisionData?.mediaMobile?.url)
          }

          if (responseGetOurMission.success) {
            const ourMissionData = responseGetOurMission.data

            setValue('content', ourMissionData?.content)
            setValue('contentInDutch', ourMissionData?.contentInDutch)
            setValue('title', ourMissionData?.title)
            setValue('titleInDutch', ourMissionData?.titleInDutch)
            setValue('mediaId', ourMissionData?.media?.id)
            setValue('mediaMobileId', ourMissionData?.mediaMobile?.id)

            ourMissionData?.media?.url && setImageSrc(ourMissionData.media.url)
            ourMissionData?.mediaMobile?.url &&
              setImageMobileSrc(ourMissionData?.mediaMobile?.url)
          }
        } catch (error: any) {
          console.log('error', error)
        }
      }
      getOurVisions()
    }
  }, [activeTab])

  return (
    <Fragment>
      <div className="bg-white p-5 pt-[60px]">
        {getOurVisionActionLoading ? (
          <>
            <Skeleton
              paragraph={{ rows: 4 }}
              style={{
                marginTop: 10,
              }}
            />
          </>
        ) : (
          <>
            <span className="text-[20px] text-black font-bold">Vision</span>
            <InputWrapperGridStyled>
              <Controller
                name="title"
                control={controlVision}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Title"
                      name="title"
                      required
                      onChange={onChange}
                      value={value}
                      alignment="col"
                      errors={error?.message}
                      containerClassName="mt-3"
                    />
                  )
                }}
              />
              <Controller
                name="titleInDutch"
                control={controlVision}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Title in Netherlands"
                      name="titleInDutch"
                      required
                      onChange={onChange}
                      value={value}
                      alignment="col"
                      errors={error?.message}
                      containerClassName="mt-3"
                    />
                  )
                }}
              />
              <Controller
                name="content"
                control={controlVision}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <TextArea
                      label="Description"
                      name="content"
                      required
                      onChange={onChange}
                      value={value}
                      alignment="col"
                      errors={error?.message}
                      containerClassName="mt-3"
                    />
                  )
                }}
              />
              <Controller
                name="contentInDutch"
                control={controlVision}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <TextArea
                      label="Description in Netherlands"
                      name="contentInDutch"
                      required
                      onChange={onChange}
                      value={value}
                      alignment="col"
                      errors={error?.message}
                      containerClassName="mt-3"
                    />
                  )
                }}
              />

              <div className="flex flex-col gap-1">
                <Controller
                  name="mediaId"
                  control={controlVision}
                  render={({ field: { onChange }, fieldState: { error } }) => {
                    onChangeVisionDesktopRef.current = onChange
                    return (
                      <CustomDragger
                        label="Image"
                        name="mediaVisionId"
                        id="mediaVisionId"
                        containerClassName="mt-3"
                        alignment="col"
                        required
                        onLoadEnd={(data) => {
                          setValueVision('mediaId', data?.id ?? 0)
                          onChange(data?.id ?? 0)
                        }}
                        changeLoading={(loading) => {
                          setIsLoadingVisionImage(loading)
                        }}
                        errors={error?.message}
                        initResource={imageVisionSrc}
                        allowFileTypes={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg',
                        ]}
                        limitFileSize={5}
                        uploadType={EMediaType.IMAGE as any}
                        uploadCategory={EMediaCategory.CONTENT}
                      />
                    )
                  }}
                />
                <SelectMedia
                  isNotGrid
                  onClickSelectMedia={() => {
                    setSelectedFieldVision((pre) => 'desktop')
                    setShowModalSelectMediaVision(true)
                  }}
                />
              </div>

              <div className="flex flex-col gap-1">
                <Controller
                  name="mediaMobileId"
                  control={controlVision}
                  render={({ field: { onChange }, fieldState: { error } }) => {
                    onChangeVisionMobileRef.current = onChange
                    return (
                      <CustomDragger
                        label="Image mobile"
                        name="mediaVisionMobileId"
                        id="mediaVisionMobileId"
                        containerClassName="mt-3"
                        alignment="col"
                        required
                        onLoadEnd={(data) => {
                          setValueVision('mediaMobileId', data?.id ?? 0)
                          onChange(data?.id ?? 0)
                        }}
                        changeLoading={(loading) => {
                          setIsLoadingVisionImage(loading)
                        }}
                        errors={error?.message}
                        initResource={imageVisionMobileSrc}
                        allowFileTypes={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg',
                        ]}
                        limitFileSize={5}
                        uploadType={EMediaType.IMAGE as any}
                        uploadCategory={EMediaCategory.CONTENT}
                      />
                    )
                  }}
                />
                <SelectMedia
                  isNotGrid
                  onClickSelectMedia={() => {
                    setSelectedFieldVision((pre) => 'mobile')
                    setShowModalSelectMediaVision(true)
                  }}
                />
              </div>
            </InputWrapperGridStyled>

            <div className="landing-image-wrapper mt-6">
              <div className="landing-image-action mt-5">
                <div className="landing-save-cancel flex items-center justify-end">
                  <Button
                    type="primary"
                    onClick={onSaveVision}
                    disabled={isLoadingVisionImage}
                    loading={isDisabledSaveButton}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>

            <span className="text-[20px] text-black font-bold">Mission</span>
            <InputWrapperGridStyled>
              <Controller
                name="title"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Title"
                      name="title"
                      required
                      onChange={onChange}
                      value={value}
                      alignment="col"
                      errors={error?.message}
                      containerClassName="mt-3"
                    />
                  )
                }}
              />
              <Controller
                name="titleInDutch"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Title in Netherlands"
                      name="titleInDutch"
                      required
                      onChange={onChange}
                      value={value}
                      alignment="col"
                      errors={error?.message}
                      containerClassName="mt-3"
                    />
                  )
                }}
              />
              <Controller
                name="content"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <TextArea
                      label="Description"
                      name="content"
                      required
                      onChange={onChange}
                      value={value}
                      alignment="col"
                      errors={error?.message}
                      containerClassName="mt-3"
                    />
                  )
                }}
              />
              <Controller
                name="contentInDutch"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <TextArea
                      label="Description in Netherlands"
                      name="contentInDutch"
                      required
                      onChange={onChange}
                      value={value}
                      alignment="col"
                      errors={error?.message}
                      containerClassName="mt-3"
                    />
                  )
                }}
              />

              <div className="flex flex-col gap-1">
                <Controller
                  name="mediaId"
                  control={control}
                  render={({ field: { onChange }, fieldState: { error } }) => {
                    onChangeDesktopRef.current = onChange
                    return (
                      <CustomDragger
                        label="Image"
                        name="mediaId"
                        id="mediaId"
                        containerClassName="mt-3"
                        alignment="col"
                        required
                        onLoadEnd={(data) => {
                          setValue('mediaId', data?.id ?? 0)
                          onChange(data?.id ?? 0)
                        }}
                        errors={error?.message}
                        initResource={imageSrc}
                        allowFileTypes={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg',
                        ]}
                        limitFileSize={5}
                        changeLoading={(loading) => {
                          setIsLoadingMissionImage(loading)
                        }}
                        uploadType={EMediaType.IMAGE as any}
                        uploadCategory={EMediaCategory.CONTENT}
                      />
                    )
                  }}
                />
                <SelectMedia
                  isNotGrid
                  onClickSelectMedia={() => {
                    setSelectedField((pre) => 'desktop')
                    setShowModalSelectMedia(true)
                  }}
                />
              </div>

              <div className="flex flex-col gap-1">
                <Controller
                  name="mediaMobileId"
                  control={control}
                  render={({ field: { onChange }, fieldState: { error } }) => {
                    onChangeMobileRef.current = onChange
                    return (
                      <CustomDragger
                        label="Image mobile"
                        name="mediaMobileId"
                        id="mediaMobileId"
                        containerClassName="mt-3"
                        alignment="col"
                        required
                        onLoadEnd={(data) => {
                          setValue('mediaMobileId', data?.id ?? 0)
                          onChange(data?.id ?? 0)
                        }}
                        changeLoading={(loading) => {
                          setIsLoadingMissionImage(loading)
                        }}
                        errors={error?.message}
                        initResource={imageMobileSrc}
                        allowFileTypes={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg',
                        ]}
                        limitFileSize={5}
                        uploadType={EMediaType.IMAGE as any}
                        uploadCategory={EMediaCategory.CONTENT}
                      />
                    )
                  }}
                />
                <SelectMedia
                  isNotGrid
                  onClickSelectMedia={() => {
                    setSelectedField((pre) => 'mobile')
                    setShowModalSelectMedia(true)
                  }}
                />
              </div>
            </InputWrapperGridStyled>

            <div className="landing-image-wrapper mt-6">
              <div className="landing-image-action mt-5">
                <div className="landing-save-cancel flex items-center justify-end">
                  <Button
                    type="primary"
                    disabled={isLoadingMissionImage}
                    onClick={onSave}
                    loading={isDisabledSaveButton}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.CONTENT}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}

      {showModalSelectMediaVision ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMediaVision}
            onClose={onCloseModalSelectMediaVision}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.CONTENT}
            onSelectMedia={onSelectVisionMedia}
          />
        </>
      ) : (
        <></>
      )}
    </Fragment>
  )
}

export default OurVisionTab
