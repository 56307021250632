import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { Card, Checkbox, message, Skeleton, Tooltip, Typography } from 'antd'
import styled from 'styled-components'

import { enumTableFAQ, enumTableTitleInUserManagement } from '@configs'
import { t } from 'i18next'
import { Button, SharedTable } from 'src/common'
import { AddNewFaqModal } from './AddNewFaqModal'
import { EditContentModal } from './EditContentModal'
import { useEffect, useMemo, useState } from 'react'
import {
  getTeacherFAQListAction,
  updateTeacherFAQContentAction,
  createTeacherFAQDetailAction,
  useAppDispatch,
  deleteTeacherFAQContentAction,
  updateTeacherFAQDetailAction,
  teachersActions,
  useAppSelector,
  updateTeacherFaqDisplayAction,
} from '@redux'
import { ITeacherFAQContent } from 'src/interfaces/teacher'
import { isEqual } from 'lodash'

type TeacherFAQProps = {
  activeTab: string
}

export const TeacherFAQ = (props: TeacherFAQProps) => {
  const [isOpenAddNewFaqModal, setIsOpenAddNewFaqModal] = useState(false)
  const [isOpenEditContentModal, setIsOpenEditContentModal] = useState(false)
  const [listFAQ, setListFAQ] = useState<any[]>([])
  const [selectedFAQ, setSelectedFAQ] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const originalFAQContent = useAppSelector(
    (state) => state.teacherManagement.faqContent
  )

  const columns = useMemo(() => {
    return [
      {
        title: enumTableTitleInUserManagement.NO,
        dataIndex: 'no',
        key: 'id',
        ellipsis: true,
        width: '7%',
        align: 'center',
      },
      {
        title: enumTableFAQ.TITLE,
        dataIndex: 'title',
        key: 'title',
        render: (text: string) => (
          <Typography.Text className="text-wrap">{text}</Typography.Text>
        ),
        align: 'center',
        width: '50%',
      },
      {
        title: enumTableFAQ.DISPLAY_ON_PAGE,
        dataIndex: 'displayOnPage',
        key: 'displayOnPage',
        render: (id: number, record: any) => {
          return (
            <div className="flex items-center justify-center ">
              <Checkbox
                onChange={() => {
                  handleUpdateShowFaq(record.id)
                }}
                checked={record.displayOnPage}
              ></Checkbox>
            </div>
          )
        },
        align: 'center',
      },
      {
        title: '',
        key: enumTableFAQ.EDIT,
        render: (_: any, record: { id: any }) => (
          <div
            onClick={() => {
              setSelectedFAQ(record)
              setIsOpenAddNewFaqModal(true)
            }}
            className="flex space-x-4 items-center justify-center"
          >
            <Tooltip title={t('common:view_detail')}>
              <EyeOutlined className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]" />
            </Tooltip>
          </div>
        ),
        ellipsis: true,
        width: '10%',
        align: 'center',
      },
      {
        title: '',
        key: enumTableFAQ.DELETE,
        render: (_: any, record: { id: any }) => (
          <div
            onClick={() => {
              handleDeleteFAQ(record.id)
            }}
            className="flex space-x-4 items-center justify-center"
          >
            <Tooltip title={t('common:delete')}>
              <DeleteOutlined
                className={`text-lg font-light mr-2.5 cursor-pointer  hover:opacity-80 !text-red-600`}
              />
            </Tooltip>
          </div>
        ),
        ellipsis: true,
        width: '10%',
        align: 'center',
      },
    ]
  }, [listFAQ])

  const fetchData = async () => {
    setIsLoading(true)
    const response = await dispatch(getTeacherFAQListAction()).unwrap()
    setIsLoading(false)
    if (response) {
      const formattedListFaq = response.map((item: any, index: number) => ({
        ...item,
        key: item.id,
        title: item.title,
        displayOnPage: item?.metaData?.displayOnPage,
      }))
      setListFAQ(formattedListFaq)
      dispatch(teachersActions.setFAQContent(formattedListFaq))
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const isShowSaveButton = useMemo(() => {
    return !isEqual(originalFAQContent, listFAQ)
  }, [listFAQ, originalFAQContent])

  const handleSaveFaqDisplayOnPage = async () => {
    const ids = listFAQ
      .filter((item) => item.displayOnPage)
      .map((item) => item.id)
    try {
      const response = await dispatch(
        updateTeacherFaqDisplayAction({
          ids,
        })
      ).unwrap()
      if (response) {
        message.success({
          content: response?.message ?? t('common:save_success'),
        })
        fetchData()
      }
    } catch (error: any) {
      setListFAQ(originalFAQContent)
      message.error({
        content: t('common:errorFAQ'),
      })
    }
  }

  const handleUpdateShowFaq = (id: number) => {
    const updatedListFAQ = listFAQ.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          displayOnPage: !item.displayOnPage,
        }
      }
      return item
    })

    setListFAQ(updatedListFAQ)
  }

  const handleEditFAQContent = async (params: ITeacherFAQContent) => {
    try {
      const response = await dispatch(
        updateTeacherFAQContentAction(params)
      ).unwrap()

      if (response?.commonContent) {
        setIsOpenEditContentModal(false)
        message.success({
          content: response?.message ?? t('common:save_success'),
        })
      }
    } catch (error: any) {
      message.error({
        content: error?.message ?? t('common:save_error'),
      })
    }
  }

  const handleUpdateFAQDetailContent = async (params: any) => {
    const body: any = {
      title: params?.question,
      titleInDutch: params?.questionInDutch,
      content: params?.answer,
      contentInDutch: params?.answerInDutch,
    }

    if (selectedFAQ) {
      try {
        const response = await dispatch(
          updateTeacherFAQDetailAction({
            id: selectedFAQ.id,
            params: {
              ...body,
            },
          })
        ).unwrap()
        if (response?.academyFAQDetail) {
          setIsOpenAddNewFaqModal(false)
          message.success({
            content: response?.message ?? t('common:save_success'),
          })
          fetchData()
        }
      } catch (error: any) {
        message.error({
          content: error?.message ?? t('common:save_error'),
        })
      }
    } else {
      try {
        const response = await dispatch(
          createTeacherFAQDetailAction(body)
        ).unwrap()

        if (response?.commonContent) {
          setIsOpenAddNewFaqModal(false)
          message.success({
            content: response?.message ?? t('common:save_success'),
          })
          fetchData()
        }
      } catch (error: any) {
        message.error({
          content: error?.message ?? t('common:save_error'),
        })
      }
    }
  }

  const handleDeleteFAQ = async (id: number) => {
    try {
      const response = await dispatch(
        deleteTeacherFAQContentAction(id)
      ).unwrap()
      if (response) {
        message.success({
          content: response?.message ?? t('common:delete_success'),
        })
        fetchData()
      }
    } catch (error: any) {
      message.error({
        content: error?.message ?? t('common:delete_error'),
      })
    }
  }

  return (
    <Card>
      <div className="flex items-center justify-between py-[1.25rem] w-full">
        <div className="max-w-[500px] flex-1 flex-row flex"></div>
        <div className="flex items-center gap-[16px]">
          {isShowSaveButton && (
            <Button onClick={handleSaveFaqDisplayOnPage} type="primary">
              Save
            </Button>
          )}

          <Button
            onClick={() => {
              setIsOpenEditContentModal(true)
            }}
            type="primary"
            size="small"
            className=" !bg-amber-500 !border-none"
            icon={<EditOutlined className="!text-[16px]" />}
          >
            Edit content
          </Button>

          <Button
            onClick={() => {
              setSelectedFAQ(null)
              setIsOpenAddNewFaqModal(true)
            }}
            type="primary"
          >
            Add new
          </Button>
        </div>
      </div>
      {isLoading ? (
        <Skeleton paragraph={{ rows: 4 }} />
      ) : (
        <TableWrapper>
          <SharedTable
            columns={columns as any}
            dataSource={listFAQ}
            hasIndexColumn={false}
          />
        </TableWrapper>
      )}
      {isOpenAddNewFaqModal ? (
        <AddNewFaqModal
          open={true}
          onSave={(data) => {
            handleUpdateFAQDetailContent(data)
          }}
          onClose={() => {
            setIsOpenAddNewFaqModal(false)
          }}
          faqContentDetail={selectedFAQ}
        />
      ) : null}

      <EditContentModal
        open={isOpenEditContentModal}
        onSave={(data) => {
          handleEditFAQContent(data)
        }}
        onClose={() => {
          setIsOpenEditContentModal(false)
        }}
      />
    </Card>
  )
}

const TableWrapper = styled.div`
  .ant-table-wrapper,
  .ant-table-content {
    padding: 0 !important;
    margin: 0 !important;
  }
`
