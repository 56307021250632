import { ApiClient } from '@api'
import { INITIAL_PAGINATION_SiZE } from '@configs'
import { IGetParams, IListPopupResponse } from '@interfaces'
import { IPopupDetailType, TPopupType } from '@pages'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  API_DELETE_POPUP,
  API_LIST_POPUP,
  API_LIST_POPUP_COURSES,
  API_POPUP_DETAIL,
  API_PUBLISH_POPUP,
  API_SAVE_POPUP,
} from 'src/routes/api'

export const getPopupCourses = createAsyncThunk(
  'popupCourses/getPopupCourses',
  async () => {
    const response = await ApiClient.get<
      {
        id: number
        title: string
      }[]
    >(API_LIST_POPUP_COURSES)
    return response?.data || {}
  }
)

export const createPopup = createAsyncThunk(
  'popupCourses/createPopup',
  async (params: TPopupType) => {
    const response = await ApiClient.post<any>(API_SAVE_POPUP, params)
    return response?.data || {}
  }
)

export const editPopup = createAsyncThunk(
  'popupCourses/createPopup',
  async ({ id, params }: { id: number | string; params: TPopupType }) => {
    const response = await ApiClient.put<any>(`${API_SAVE_POPUP}/${id}`, params)
    return response?.data || {}
  }
)

export const publishPopup = createAsyncThunk(
  'popupCourses/publishPopup',
  async ({ id, params }: { id: number | string; params: TPopupType }) => {
    const response = await ApiClient.put<any>(
      `${API_PUBLISH_POPUP}/${id}`,
      params
    )
    return response?.data || {}
  }
)

export interface IFetchPopupParams extends IGetParams {
  search?: string
  limit?: string | number
  status?: string
  state?: string
  types?: string
  sort?: string
}

export const getListPopup = createAsyncThunk(
  'popupCourses/getListPopup',
  async (params?: IFetchPopupParams) => {
    const localParams = params
      ? params
      : {
          page: 1,
          limit: INITIAL_PAGINATION_SiZE,
        }
    const response = await ApiClient.get<IListPopupResponse>(API_LIST_POPUP, {
      params: localParams,
    })
    return response?.data || {}
  }
)

export const deletePopup = createAsyncThunk(
  'popupCourses/deletePopup',
  async (id: number | string) => {
    const response = await ApiClient.delete<any>(`${API_DELETE_POPUP}/${id}`)
    return response?.data || {}
  }
)

export const getPopupDetail = createAsyncThunk(
  'popupCourses/getPopupDetail',
  async (id: number | string) => {
    const response = await ApiClient.get<IPopupDetailType>(
      `${API_POPUP_DETAIL}/${id}`
    )
    return response?.data || {}
  }
)
