import { NavigateFunction } from 'react-router-dom'
import styled from 'styled-components'
import { Typography } from 'antd'
import { t } from 'i18next'

import { ENotificationType, INotificationTagCommonProps } from '../configs'
import { notificationIcons } from '../assets'
import { INotificationItem } from '@interfaces'
import { formatDate } from '@utils'
import {
  PATH_TEACHER_REQUEST_DETAIL,
  PATH_COURSE_REQUEST_DETAIL,
  PATH_COURSE_MANAGEMENT_DETAIL,
  PATH_VIEW_GRADED_TEST_BY_STUDENT_ID,
  PATH_TEST_MANAGEMENT,
  FORMAT_DATE_TABLE,
  PATH_GRADED_TEST_BY_STUDENT_ID,
  ENavBarRole,
} from '@configs'
import { EGradingStatus } from 'src/pages/test-management/type'

export const getNotificationTypes = (
  notificationItem: INotificationItem,
  navigate: NavigateFunction,
  onClickNotification: (notificationId: number, isRead: boolean) => void,
  userRole: ENavBarRole
) => {
  const {
    sender,
    course,
    finalTestSectionHistory,
    title,
    createdAt,
    isRead,
    body,
    id: notificationId,
    metaData,
  } = {
    ...notificationItem,
  }

  const notificationTypes: Record<string, INotificationTagCommonProps> = {
    [ENotificationType.TEACHER_PENDING_SIGNUP]: {
      avatar: sender?.profilePhoto?.url,
      title: <NotificationTitle>{title}</NotificationTitle>,
      content: (
        <NotificationContent>
          <p
            dangerouslySetInnerHTML={{
              __html: t('notification:teacher_pending_content', {
                teacher_name: sender?.name,
              }),
            }}
          />
        </NotificationContent>
      ),
      createdAt,
      isRead: isRead,
      onClick: () => {
        onClickNotification(notificationId, isRead)
        navigate(
          PATH_TEACHER_REQUEST_DETAIL.replace(
            ':teacherId',
            `${metaData?.teacherRequest?.id}`
          )
        )
      },
    },
    [ENotificationType.TEACHER_RESUBMIT_SIGNUP]: {
      avatar: sender?.profilePhoto?.url,
      title: <NotificationTitle>{title}</NotificationTitle>,
      content: (
        <NotificationContent>
          <p
            dangerouslySetInnerHTML={{
              __html: t('notification:teacher_resubmit_signup', {
                teacher_name: sender?.name,
              }),
            }}
          />
        </NotificationContent>
      ),
      createdAt,
      isRead: isRead,
      onClick: () => {
        onClickNotification(notificationId, isRead)
        navigate(
          PATH_TEACHER_REQUEST_DETAIL.replace(
            ':teacherId',
            `${metaData?.teacherRequest?.id}`
          )
        )
      },
    },
    [ENotificationType.TEACHER_PUBLISH_COURSE]: {
      avatar: sender?.profilePhoto?.url,
      title: <NotificationTitle>{title}</NotificationTitle>,
      content: (
        <NotificationContent>
          <p
            dangerouslySetInnerHTML={{
              __html: t('notification:teacher_publish_course', {
                course_name: course?.title,
                teacher_name: sender?.name,
              }),
            }}
          />
        </NotificationContent>
      ),
      createdAt,
      isRead: isRead,
      onClick: () => {
        onClickNotification(notificationId, isRead)
        navigate(
          PATH_COURSE_REQUEST_DETAIL.replace(
            ':courseId',
            `${metaData?.courseRequest?.courseId}`
          )
        )
      },
    },
    [ENotificationType.APPROVE_PUBLISH_COURSE]: {
      avatar: notificationIcons.successIcon,
      title: <NotificationTitle>{title}</NotificationTitle>,
      content: (
        <NotificationContent>
          <p
            dangerouslySetInnerHTML={{
              __html: t('notification:approve_publish_course', {
                course_name: course?.title,
              }),
            }}
          />
        </NotificationContent>
      ),
      createdAt,
      isRead: isRead,
      onClick: () => {
        onClickNotification(notificationId, isRead)
        navigate(
          PATH_COURSE_MANAGEMENT_DETAIL.replace(
            ':id',
            `${metaData?.course?.id}`
          )
        )
      },
    },
    [ENotificationType.REJECT_PUBLISH_COURSE]: {
      avatar: notificationIcons.failedIcon,
      title: <NotificationTitle>{title}</NotificationTitle>,
      content: (
        <NotificationContent>
          <p
            dangerouslySetInnerHTML={{
              __html: t('notification:reject_publish_course', {
                course_name: course?.title,
              }),
            }}
          />
        </NotificationContent>
      ),
      createdAt,
      isRead: isRead,
      onClick: () => {
        onClickNotification(notificationId, isRead)
        navigate(
          PATH_COURSE_REQUEST_DETAIL.replace(
            ':courseId',
            `${metaData?.courseRequest?.courseId}`
          )
        )
      },
    },
    [ENotificationType.SUBMIT_NEW_TEST]: {
      avatar: sender?.profilePhoto?.url,
      title: (
        <NotificationTitle>
          {title} {sender?.name}
        </NotificationTitle>
      ),
      content: (
        <NotificationContent>
          <p
            dangerouslySetInnerHTML={{
              __html: t('notification:submit_new_test', {
                user_name: finalTestSectionHistory?.user?.name,
                course_name: course?.title,
              }),
            }}
          />
        </NotificationContent>
      ),
      createdAt,
      isRead: isRead,
      onClick: () => {
        onClickNotification(notificationId, isRead)
        if (
          ([ENavBarRole.ADMIN].includes(userRole) &&
            !!finalTestSectionHistory?.teacherId) ||
          [EGradingStatus.GRADED].includes(
            finalTestSectionHistory?.gradingStatus
          )
        ) {
          navigate(PATH_TEST_MANAGEMENT)
          return
        }

        navigate(
          PATH_GRADED_TEST_BY_STUDENT_ID.replace(
            ':testId',
            `${metaData?.finalTestHistory?.id}`
          ).replace(':studentId', `${metaData?.finalTestHistory?.userId}`)
        )
      },
    },
    [ENotificationType.ASSIGN_GRADE_TEST]: {
      avatar: sender?.profilePhoto?.url,
      title: <NotificationTitle>{title}</NotificationTitle>,
      content: (
        <NotificationContent>
          <p
            dangerouslySetInnerHTML={{
              __html: t('notification:assign_grade_test', {
                user_name: finalTestSectionHistory?.user?.name,
                course_name: course?.title,
              }),
            }}
          />
        </NotificationContent>
      ),
      createdAt,
      isRead: isRead,
      onClick: () => {
        onClickNotification(notificationId, isRead)
        navigate(PATH_TEST_MANAGEMENT)
      },
    },
    [ENotificationType.ACCEPT_GRADE_TEST]: {
      avatar: notificationIcons.successIcon,
      title: <NotificationTitle>{title}</NotificationTitle>,
      content: (
        <NotificationContent>
          <p
            dangerouslySetInnerHTML={{
              __html: t('notification:accept_grade_test', {
                teacher_name: sender?.name,
                user_name: finalTestSectionHistory?.user?.name,
                course_name: course?.title,
              }),
            }}
          />
        </NotificationContent>
      ),
      createdAt,
      isRead: isRead,
      onClick: () => {
        onClickNotification(notificationId, isRead)
        navigate(PATH_TEST_MANAGEMENT)
      },
    },
    [ENotificationType.REJECT_GRADE_TEST]: {
      avatar: notificationIcons.failedIcon,
      title: <NotificationTitle>{title}</NotificationTitle>,
      content: (
        <NotificationContent>
          <p
            dangerouslySetInnerHTML={{
              __html: t('notification:reject_grade_test', {
                teacher_name: sender?.name,
                user_name: finalTestSectionHistory?.user?.name,
                course_name: course?.title,
              }),
            }}
          />
        </NotificationContent>
      ),
      createdAt,
      isRead: isRead,
      onClick: () => {
        onClickNotification(notificationId, isRead)
        navigate(PATH_TEST_MANAGEMENT)
      },
    },
    [ENotificationType.FINISH_GRADE_TEST]: {
      avatar: sender?.profilePhoto?.url,
      title: (
        <NotificationTitle>
          {title} {sender?.name}
        </NotificationTitle>
      ),
      content: (
        <NotificationContent>
          <p
            dangerouslySetInnerHTML={{
              __html: t('notification:finish_grade_test', {
                teacher_name: sender?.name,
                user_name: finalTestSectionHistory?.user?.name,
                course_name: course?.title,
              }),
            }}
          />
        </NotificationContent>
      ),
      createdAt,
      isRead: isRead,
      onClick: () => {
        onClickNotification(notificationId, isRead)
        navigate(
          PATH_VIEW_GRADED_TEST_BY_STUDENT_ID.replace(
            ':testId',
            `${metaData?.finalTestHistory?.id}`
          ).replace(':studentId', `${metaData?.finalTestHistory?.userId}`)
        )
      },
    },
    [ENotificationType.UNGRADED_TEST_DEADLINE]: {
      avatar: (
        <img
          src={notificationIcons.warningIcon}
          alt="iconWaining"
          className="min-w-[46px] !w-[46px] !h-[46px]"
        />
      ),
      title: <NotificationTitle>{title}</NotificationTitle>,
      content: (
        <NotificationContent>
          <p
            dangerouslySetInnerHTML={{
              __html: t('notification:ungrade_test_deadline', {
                user_name: finalTestSectionHistory?.user?.name,
                course_name: course?.title,
                deadline: formatDate(
                  finalTestSectionHistory?.deadline,
                  FORMAT_DATE_TABLE
                ),
              }),
            }}
          />
        </NotificationContent>
      ),
      createdAt,
      isRead: isRead,
      onClick: () => {
        onClickNotification(notificationId, isRead)
        navigate(PATH_TEST_MANAGEMENT)
      },
    },
    [ENotificationType.COURSE_MESSAGE]: {
      avatar: sender?.profilePhoto?.url,
      title: (
        <NotificationTitle>
          {title} {sender?.name}
        </NotificationTitle>
      ),
      content: (
        <NotificationContent>
          <p
            dangerouslySetInnerHTML={{
              __html: t('notification:message', {
                user_name: sender?.name,
              }),
            }}
          />
        </NotificationContent>
      ),
      createdAt,
      isRead: isRead,
      onClick: () => {
        onClickNotification(notificationId, isRead)
        navigate(
          `${PATH_COURSE_MANAGEMENT_DETAIL.replace(
            ':id',
            `${metaData?.course?.id}`
          )}?tab=HOME_PAGE_CHAT`
        )
      },
    },

    // default
    [ENotificationType.DEFAULT]: {
      title: <NotificationTitle>{title}</NotificationTitle>,
      content: <NotificationContent>{body}</NotificationContent>,
      createdAt,
      isRead: isRead,
      onClick() {
        onClickNotification(notificationId, isRead)
      },
    },
  }

  return notificationTypes
}

const NotificationTitle = styled(Typography.Title)`
  font-size: 14px !important;
  margin-bottom: 0px !important;
  font-style: italic !important;
  font-weight: 700;
  color: black;
`

const NotificationContent = styled(Typography.Text)`
  font-size: 12px !important;
  font-weight: 400;
  color: black;

  p {
    margin-bottom: 0px !important;
  }
`
