import { FormProvider, useForm } from 'react-hook-form'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { z } from 'zod'
import { t } from 'i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { personalInformationSchema, specificInformationSchema } from './schema'
import { WorkExperiences } from './work-experiences'
import { CoursesReference } from './courses-reference'
import { Educations } from './educations'
import { PersonalInformation } from './personal-information'
import { Degree } from './degree'
import { getTeacherRequestDetail, useAppDispatch, useAppSelector } from '@redux'
import { FillInTeacherInformationPageStyled } from './style'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'src/common'
import { Typography } from 'antd'
import AcceptTeacherRequestDialog from './AcceptTeacherRequestDialog'
import RejectTeacherRequestDialog from './RejectTeacherRequestDialog'
import RequestUpdateTeacherRequestDialog from './RequestUpdateTeacherRequestDialog'
import { ETeacherRequestModalType, ETeacherRequestStatus } from './types'
import { PATH_TEACHER_REQUEST_MANAGEMENT } from '@configs'
import { RESPONSE_STATUS_CONTENT } from './config'
import Skeleton from 'src/components/skeleton/Skeleton'
import { SignatureSection } from './signature/SignatureSection'

export const schema = z.object({
  personalInformation: personalInformationSchema,
  specificInformation: specificInformationSchema,
})

const defaultFormValues = {
  personalInformation: {
    firstname: '',
    lastname: '',
    gender: '',
    avatarId: 0,
    dateOfBirth: '',
    phone: '',
    description: '',
    email: '',
    password: '',
  },
  specificInformation: {
    education: [
      {
        institution: '',
        educationLevel: '',
        qualification: '',
        description: '',
        startDate: '',
        endDate: '',
        editable: true,
        isPresent: false,
        degree: {
          file: {
            name: '',
            fileId: 0,
          },
        },
      },
    ],
    workExperience: [
      {
        companyName: '',
        jobTitle: '',
        description: '',
        startDate: '',
        endDate: '',
        editable: true,
        isPresent: false,
      },
    ],
    courseReference: [
      {
        name: '',
        url: '',
        editable: true,
      },
    ],
  },
}

export const FillInTeacherInformationPage = ({
  onBack,
}: {
  onBack?: () => void
}) => {
  const navigate = useNavigate()
  const { teacherId } = useParams()
  const isViewOnly = true

  const dispatch = useAppDispatch()
  const {
    data: teacherRequestDetailData,
    isLoading,
    error,
  } = useAppSelector(
    (state) => state.teacherRequestManagement.teacherRequestDetail
  )

  const [modalType, setModalType] = useState<ETeacherRequestModalType | ''>('')

  const methods = useForm<any>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValues,
  })

  const { reset } = methods

  const onCloseModal = () => setModalType('')

  const responseStatusTitle = useMemo(() => {
    const teacherRequestStatus = teacherRequestDetailData?.status
    const name = teacherRequestDetailData?.responseData?.updatedBy?.name || ''

    if (
      [
        ETeacherRequestStatus.ACCEPTED,
        ETeacherRequestStatus.REJECTED,
        ETeacherRequestStatus.REQUEST_UPDATE,
      ].includes(teacherRequestStatus)
    )
      return `${
        RESPONSE_STATUS_CONTENT?.[teacherRequestStatus]?.responseTitle || ''
      } ${name}`

    return RESPONSE_STATUS_CONTENT?.[teacherRequestStatus]?.responseTitle || ''
  }, [teacherRequestDetailData])

  const renderResponseStatus = useCallback(
    (teacherRequestStatus) => {
      if (teacherRequestStatus === ETeacherRequestStatus.PENDING) return

      return (
        <div className="p-6 pb-0">
          <Typography
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              color:
                RESPONSE_STATUS_CONTENT?.[
                  teacherRequestStatus as ETeacherRequestStatus
                ]?.color,
            }}
          >
            {responseStatusTitle}
          </Typography>
          <Typography
            style={{
              fontSize: 20,
              fontWeight: 'bold',
            }}
          >
            {teacherRequestDetailData?.responseData?.reason &&
            ![
              ETeacherRequestStatus.ACCEPTED,
              ETeacherRequestStatus.UPDATED,
            ].includes(teacherRequestDetailData?.status)
              ? `Reason: ${teacherRequestDetailData?.responseData?.reason}`
              : ''}
          </Typography>
        </div>
      )
    },
    [
      responseStatusTitle,
      teacherRequestDetailData?.responseData?.reason,
      teacherRequestDetailData?.status,
    ]
  )

  useEffect(() => {
    if (!Object.keys(teacherRequestDetailData)?.length) return

    const { specificInformation, personalInformation } =
      teacherRequestDetailData || {}

    const transformedSpecificInformation = {
      ...specificInformation,
      workExperience: specificInformation?.workExperience?.map(
        (experience) => ({
          ...experience,
          editable: false,
        })
      ),
      courseReference: specificInformation?.courseReference?.map(
        (reference) => ({
          ...reference,
          editable: false,
        })
      ),
      education: specificInformation?.education?.map((education) => ({
        ...education,
        degree: {
          file: {
            fileId: education?.degree?.fileId || 0,
            name: education?.degree?.name || '',
          },
          mediaFile: { ...education?.degree?.file },
        },
        isPresent: education?.isPresent || false,
        editable: false,
      })),
      teacherSignature: {
        ...specificInformation?.teacherSignature,
        editable: !specificInformation?.teacherSignature?.fileId,
        signaturePhoto: {
          ...specificInformation?.teacherSignature?.signaturePhoto,
        },
      },
    }

    const transformedPersonalInformation = {
      ...personalInformation,
      avatarId: personalInformation?.avatar?.id,
      editable: false,
    }

    reset({
      ...defaultFormValues,
      personalInformation: {
        ...defaultFormValues.personalInformation,
        ...transformedPersonalInformation,
      },
      specificInformation: {
        ...defaultFormValues.specificInformation,
        ...transformedSpecificInformation,
      },
    })
  }, [reset, teacherRequestDetailData])

  useEffect(() => {
    dispatch(getTeacherRequestDetail(String(teacherId)))
  }, [dispatch, teacherId])

  useEffect(() => {
    if (error?.code !== 404) return

    navigate('/404')
  }, [error?.code, navigate])

  if (isLoading)
    return (
      <div className="p-6">
        <Skeleton row={3} column={2} style={{ height: 60 }} />
        <Skeleton row={4} column={1} style={{ height: 120 }} />
      </div>
    )

  return (
    <>
      <Typography
        style={{
          fontSize: 28,
          fontWeight: 'bold',
          textAlign: 'center',
          padding: '24px 24px 0',
        }}
      >
        View profile
      </Typography>

      {renderResponseStatus(teacherRequestDetailData?.status)}

      <FormProvider {...methods}>
        <FillInTeacherInformationPageStyled className="!border-none flex items-center justify-center w-full !max-w-unset">
          <PersonalInformation isViewMode={isViewOnly} />
          <Educations isViewMode={isViewOnly} />
          <WorkExperiences isViewMode={isViewOnly} />
          <CoursesReference isViewMode={isViewOnly} />
          <SignatureSection />
        </FillInTeacherInformationPageStyled>

        <div className="flex w-full justify-center items-center gap-4 pb-6">
          {[
            ETeacherRequestStatus.PENDING,
            ETeacherRequestStatus.UPDATED,
          ].includes(
            teacherRequestDetailData?.status as ETeacherRequestStatus
          ) ? (
            <>
              <Button
                type="ghost"
                danger
                size="small"
                style={{
                  backgroundColor: '#ffffff',
                  color: '#ff0000',
                  borderColor: '#ff0000',
                }}
                onClick={() => setModalType(ETeacherRequestModalType.REJECTED)}
              >
                {t('courseRequest:reject_profile')}
              </Button>

              <Button
                type="primary"
                size="small"
                style={{
                  backgroundColor: '#26B7B9FF',
                  color: '#ffffff',
                  borderColor: '#26B7B9FF',
                }}
                onClick={() => setModalType(ETeacherRequestModalType.ACCEPTED)}
              >
                {t('courseRequest:accept_profile')}
              </Button>

              <Button
                type="primary"
                size="small"
                htmlType="submit"
                style={{
                  backgroundColor: '#D4A90FFF',
                  color: '#ffffff',
                  borderColor: '#D4A90FFF',
                }}
                onClick={() =>
                  setModalType(ETeacherRequestModalType.REQUEST_UPDATE)
                }
              >
                Request update application
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              size="small"
              onClick={() => navigate(PATH_TEACHER_REQUEST_MANAGEMENT)}
            >
              Back
            </Button>
          )}
        </div>
      </FormProvider>

      {/* need to render a modal in dom to prevent scroll to top of page when open modal */}
      <AcceptTeacherRequestDialog
        open={modalType === ETeacherRequestModalType.ACCEPTED}
        onClose={onCloseModal}
      />

      {modalType === ETeacherRequestModalType.REJECTED ? (
        <RejectTeacherRequestDialog open onClose={onCloseModal} />
      ) : null}

      {modalType === ETeacherRequestModalType.REQUEST_UPDATE ? (
        <RequestUpdateTeacherRequestDialog open onClose={onCloseModal} />
      ) : null}
    </>
  )
}
