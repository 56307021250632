import styled from 'styled-components'
import { Empty, EmptyProps } from 'antd'
import { themes } from '@theme'

export const StyledEmpty = styled((props: EmptyProps) => {
  return <Empty {...props} />
})<{}>`
  .ant-empty-description {
    color: ${themes.theme.light.colors.primary};
  }
`
