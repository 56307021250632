import {
  IChartData,
  IDashboardParams,
  IFetchNewUserStatSuccessData,
  IFetchRevenueStatSuccessData,
  IGetStatisticChartRequest,
  ISubscriberStat,
} from '@interfaces'
import { ApiClient } from './axiosClient'

const STATISTIC_URL = '/statistics'
const GET_USER = '/users'
const GET_INCOME = '/income'

export const dashboardAPI = {
  getRevenueStat: async (params: IDashboardParams) => {
    return await ApiClient.get<IFetchRevenueStatSuccessData>(
      '/transaction/stats',
      {
        params,
      }
    )
  },

  getNewUserStat: async (params: IDashboardParams) => {
    return await ApiClient.get<IFetchNewUserStatSuccessData>('/users/stats', {
      params,
    })
  },

  getUserStatisticChart: async (year: string) => {
    return await ApiClient.get<IChartData[]>(`${STATISTIC_URL}/users/${year}`)
  },

  getIncomeStatisticChart: async (year: string) => {
    return await ApiClient.get<IChartData[]>(`${STATISTIC_URL}/income/${year}`)
  },

  getSubscriberData: async () => {
    return await ApiClient.get<ISubscriberStat>(`${STATISTIC_URL}/new-subscriber`)
  },
}
