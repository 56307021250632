import { useEffect, useRef, useState } from 'react'
import { Skeleton, message, Typography } from 'antd'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'
import queryString from 'query-string'
import { zodResolver } from '@hookform/resolvers/zod'

import {
  CONTENT_MANAGEMENT_VALIDATION,
  ETeacherLandingPageTabs,
  LAYOUT_OPTIONS,
} from '@configs'
import {
  createEditTeacherBannerAction,
  getTeacherBannerAction,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Button, HookFormTextArea, Input } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { ILandingFormData } from 'src/interfaces/aboutus-management'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import { InputWrapperGridStyled } from '@theme'
import { ShareSelectInput } from '@components'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import { DEFAULT_TEACHER_BANNER_VALUES, teacherBannerSchema } from './configs'
import { CommonTeacherContentWrapperStyled } from '../../styled'

interface IProps {
  activeTab?: string
}

const BannerForm = ({ activeTab }: IProps) => {
  const [isModalLoading, setIsModalLoading] = useState(false)
  const dispatch = useAppDispatch()

  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)

  const [imageSrc, setImageSrc] = useState('')
  const [imageMobileSrc, setImageMobileSrc] = useState('')

  const { bannerInformation, loadings } = useAppSelector(
    (state: RootState) => state.teacherLanding
  )

  const isGettingBannerInformation = loadings['getTeacherBannerAction']
  const isSavingBannerInformation = loadings[`createEditTeacherBannerAction`]

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isDirty },
    reset,
    getValues,
  } = useForm<ILandingFormData>({
    defaultValues: DEFAULT_TEACHER_BANNER_VALUES,
    resolver: zodResolver(teacherBannerSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: ILandingFormData) => {
    try {
      const res = await dispatch(createEditTeacherBannerAction(data)).unwrap()

      if (res?.commonContent) {
        message.success({
          content: res?.message ?? t('common:save_success'),
        })
      }
    } catch (error: any) {
      message.error({
        content: error?.message ?? t('error:failed_to_save_landing'),
      })
    }
  }

  const onSave = () => {
    handleSubmit(onSubmit)()
  }

  const onPreview = (data: ILandingFormData) => {
    const payload = {
      title: data.title,
      url: imageSrc,
      urlMobile: imageMobileSrc,
      type: 'banner',
      textColor: data.textColor,
      mediaDescription: data.mediaDescription,
      layout: data.layout,
    }
    const stringified = queryString.stringify(payload)
    const URL = `${process.env.REACT_APP_PUBLIC_WEB_DOMAIN}/preview-content?${stringified}`
    window.open(URL, '_blank')
  }

  const onReset = () => {
    if (!bannerInformation || !Object.keys(bannerInformation)?.length) {
      reset()
      return
    }

    reset({
      ...bannerInformation,
      textColor: bannerInformation?.metaData?.textColor,
      layout: bannerInformation?.metaData?.layout,
    })
    setImageSrc(bannerInformation?.media?.url ?? '')
    setImageMobileSrc(bannerInformation?.mediaMobile?.url ?? '')
  }

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
      setImageSrc(data?.baseUrl ?? '')
    } else if (selectedField === 'mobile') {
      setValue('mediaMobileId', data?.id ?? 0)
      onChangeMobileRef.current(data?.id ?? 0)
      setImageMobileSrc(data?.baseUrl ?? '')
    }

    setShowModalSelectMedia(false)
  }

  useEffect(() => {
    if (ETeacherLandingPageTabs.TEACHER_BANNER.includes(activeTab ?? '')) {
      dispatch(getTeacherBannerAction())
    }
  }, [activeTab, dispatch])

  useEffect(() => {
    onReset()

    return () => {
      reset()
      setImageSrc('')
      setImageMobileSrc('')
    }
  }, [bannerInformation, reset])

  return (
    <CommonTeacherContentWrapperStyled>
      {/* check loading here */}
      {!!isGettingBannerInformation ? (
        <>
          <Skeleton
            paragraph={{ rows: 4 }}
            style={{
              marginTop: 10,
            }}
          />
        </>
      ) : (
        <>
          <InputWrapperGridStyled>
            <Controller
              name="title"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label="Title"
                    required
                    size="middle"
                    value={value}
                    onChange={(e) => {
                      onChange(e?.target?.value)
                    }}
                    errors={error?.message}
                  />
                )
              }}
            />

            <Controller
              name="titleInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label="Title in Netherlands"
                    required
                    size="middle"
                    value={value}
                    onChange={(e) => {
                      onChange(e?.target?.value)
                    }}
                    errors={error?.message}
                  />
                )
              }}
            />

            <HookFormTextArea
              control={control}
              controlName={`mediaDescription`}
              label="Description"
              rows={5}
              required
            />

            <HookFormTextArea
              control={control}
              controlName={`mediaDescriptionInDutch`}
              label="Description in Netherlands"
              rows={5}
              required
            />

            <Controller
              name="layout"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <ShareSelectInput
                    containerClassName="flex flex-col gap-[10px] !min-w-0 w-[30%]"
                    inlineLabelClassName="!text-start !block !m-0"
                    data={LAYOUT_OPTIONS}
                    onChange={(value: string) => {
                      onChange(value)
                    }}
                    label={'Layout'}
                    errors={error?.message}
                    value={value}
                  />
                )
              }}
            />

            <Controller
              name="textColor"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Text color"
                    alignment="col"
                    name="textColor"
                    type="color"
                    required
                    onChange={(e) => {
                      onChange(e?.target?.value)
                    }}
                    value={value}
                    errors={error?.message}
                    isDisableShadow
                  />
                )
              }}
            />
            <div className="flex flex-col gap-1 items-end">
              <Controller
                name="mediaId"
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => {
                  onChangeDesktopRef.current = onChange
                  return (
                    <CustomDragger
                      label="Image"
                      name="mediaId"
                      id="mediaId"
                      containerClassName="mt-3"
                      alignment="col"
                      required
                      onLoadEnd={(data) => {
                        setValue('mediaId', data?.id ?? 0)
                        onChange(data?.id ?? 0)
                        setImageSrc((prev) => data.original ?? prev)
                      }}
                      errors={error?.message}
                      initResource={imageSrc}
                      reset={getValues('mediaId') === 0}
                      limitFileSize={
                        CONTENT_MANAGEMENT_VALIDATION.MAX_IMAGE_SIZE
                      }
                      allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                      changeLoading={setIsModalLoading}
                      uploadType={EMediaType.IMAGE as any}
                      uploadCategory={EMediaCategory.CONTENT}
                    />
                  )
                }}
              />
              <Typography.Text type="danger">
                {t('common:imageResolutionStandard')}
              </Typography.Text>
              <SelectMedia
                isNotGrid
                buttonClassName="!text-blue-500"
                onClickSelectMedia={() => {
                  setSelectedField((pre) => 'desktop')
                  setShowModalSelectMedia(true)
                }}
              />
            </div>

            <div className="flex flex-col gap-1 items-end mb-4">
              <Controller
                name={'mediaMobileId'}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  onChangeMobileRef.current = onChange
                  return (
                    <>
                      <CustomDragger
                        label="Image mobile"
                        containerClassName="mt-[10px]"
                        alignment="col"
                        onLoadEnd={(data) => {
                          setValue('mediaMobileId', data?.id ?? 0)
                          onChange(data?.id ?? 0)
                          setImageMobileSrc((prev) => data.original ?? prev)
                        }}
                        name="mediaMobileId"
                        id="mediaMobileId"
                        required
                        errors={error?.message}
                        multiple={false}
                        initResource={imageMobileSrc}
                        reset={getValues('mediaMobileId') === 0}
                        allowFileTypes={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg',
                        ]}
                        limitFileSize={
                          CONTENT_MANAGEMENT_VALIDATION.MAX_IMAGE_SIZE
                        }
                        changeLoading={setIsModalLoading}
                        uploadType={EMediaType.IMAGE as any}
                        uploadCategory={EMediaCategory.CONTENT}
                      />
                    </>
                  )
                }}
              />
              <Typography.Text type="danger">
                {t('common:imageMobileResolutionStandard')}
              </Typography.Text>
              <SelectMedia
                isNotGrid
                buttonClassName="!text-blue-500"
                onClickSelectMedia={() => {
                  setSelectedField((pre) => 'mobile')
                  setShowModalSelectMedia(true)
                }}
              />
            </div>
          </InputWrapperGridStyled>

          <div className="landing-image-wrapper mt-6">
            <div className="landing-image-action mt-5">
              <div className="landing-save-cancel flex items-center justify-end">
                <Button
                  className="mr-5"
                  type="primary"
                  onClick={handleSubmit(onPreview)}
                >
                  {t('common:preview')}
                </Button>
                {/* <Button className="mr-5" onClick={onReset} disabled={!isDirty}>
                  {t('common:reset')}
                </Button> */}
                <Button
                  type="primary"
                  onClick={onSave}
                  loading={isModalLoading || !!isSavingBannerInformation}
                  disabled={!isDirty}
                >
                  {t('common:save')}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={EMediaType.IMAGE}
            category={EMediaCategory.CONTENT}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </CommonTeacherContentWrapperStyled>
  )
}

export default BannerForm
