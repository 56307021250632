import { Typography } from 'antd'
import { useMemo, useState } from 'react'
import { ChartData } from 'chart.js'
import moment from 'moment'

import { IChartData } from '@interfaces'
import { StyledEmpty } from 'src/components/empty-component'
import { MultiLineChartComponent } from 'src/components/dashboard/chart/MultiLineChartComponent'
import { formatDate, getCurrentYear } from '@utils'
import { themes } from '@theme'
import { EOverviewDateFilter } from '@pages'
import { ShareDateSelect } from 'src/components/shared'

interface IProps {
  chartData: IChartData[]
  title: string
  isPriceChart?: boolean
  backgroundColor?: string
  borderColor?: string
  containerClassName?: string
  onChangeFilter?: (dateString: string) => void
}

export const UserAndIncomeChart = ({
  title,
  chartData,
  isPriceChart,
  backgroundColor,
  borderColor,
  containerClassName,
  onChangeFilter,
}: IProps) => {
  const formatDatasets: ChartData<'line'> = useMemo(
    () => ({
      labels: chartData?.map((item) => formatDate(item.month, 'MM/YYYY')),
      datasets: [
        {
          data: chartData?.map((item) => item.total),
          label: title,
          backgroundColor:
            backgroundColor ?? themes.theme.dark.colors.miniDashboard.darkBlue,
          borderColor: borderColor,
        },
      ],
    }),
    [backgroundColor, borderColor, chartData, title]
  )

  return (
    <div className={` ${containerClassName}`}>
      <div className="flex justify-between items-center">
        <Typography.Text className="font-semibold text-2xl">
          {title}
        </Typography.Text>

        <ShareDateSelect
          type="year"
          placeholder="To"
          format={'YYYY'}
          onChange={(date, dateString) => {
            onChangeFilter?.(dateString)
          }}
          maxDate={new Date()}
          defaultValue={moment(getCurrentYear(), 'YYYY')}
          allowClear={false}
        />
      </div>

      {!!chartData?.length ? (
        <MultiLineChartComponent
          isPriceChart={isPriceChart}
          data={formatDatasets}
        />
      ) : (
        <StyledEmpty />
      )}
    </div>
  )
}
