import { EyeOutlined, SearchOutlined } from '@ant-design/icons'
import { Card, Skeleton, Tag, Tooltip, Typography, message } from 'antd'
import { format } from 'date-fns'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import {
  EKeyBoardCode,
  EUserStatus,
  INITIAL_PAGINATION_SiZE,
  LIST_STATUS_USER,
  PATH_USER_MANAGEMENT,
  enumTableTitleInUserManagement,
} from '@configs'
import { ILoginResponse, TUpdateUserData } from '@interfaces'
import {
  RootState,
  addUserAction,
  getAllUsersAction,
  useAppDispatch,
} from '@redux'
import { Button, Input, SharedTable } from 'src/common'
import AddUserModal from 'src/components/user-management/AddUserModal'
import { dowloadFile } from 'src/utils/file'
import { ESortColumnValue, SORT_VALUES } from './type'
import { useDebounce } from 'src/hooks/useDebounce'

export const UserManagementPage = () => {
  const navigate = useNavigate()
  const { search } = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()

  const [searchValue, setSearchValue] = useState('')
  const debouncedValue = useDebounce<string | null>(searchValue.trim(), 500)
  const [pageSize, setPageSize] = useState(INITIAL_PAGINATION_SiZE)
  const [isDowloading, setIsDowloading] = useState<boolean>(false)
  const [isAddingUser, setIsAddingUser] = useState<boolean>(false)
  const [openAddUserModal, setOpenAddUserModal] = useState<boolean>(false)
  const [createdTimeSortValue, setCreatedTimeSortValue] = useState(
    ESortColumnValue.DESC
  )

  const { t } = useTranslation(['common', 'user', 'error'])

  const dispatch = useAppDispatch()
  const getAllUsersActionLoading = useSelector(
    (state: RootState) => state.users.loadings.getAllUsersActionLoading
  )
  const { users, usersCurrentPage, usersTotalItems } = useSelector(
    (state: RootState) => state.users
  )

  const [localPage, setLocalPage] = useState(1)

  const onSearchUsers = (page?: number) => {
    const localURlQuery: any = {
      page: page ?? 1,
      limit: pageSize,
    }
    localURlQuery.search = searchValue.trim()
    const stringifyQuery = queryString.stringify(localURlQuery)
    setSearchParams(stringifyQuery)
  }

  const onDowloadFile = async () => {
    try {
      setIsDowloading(true)
      await dowloadFile(`users/export${search}`)
    } catch (error: any) {
      message.error(error.message || 'Oops, something went wrong')
    } finally {
      setIsDowloading(false)
    }
  }

  const onOpenUserModal = () => {
    setOpenAddUserModal(true)
  }

  const onCloseUserModal = () => {
    setOpenAddUserModal(false)
  }

  const onAddUser = async (data: TUpdateUserData) => {
    const { packageId, ...passData } = data
    setIsAddingUser(true)
    const payload: any = {
      ...passData,
    }
    if (packageId) {
      payload.packageId = +packageId
    }
    try {
      const response = await dispatch(addUserAction(payload)).unwrap()
      message.success(response.message)

      onCloseUserModal()
      getAllUsers()
    } catch (error: any) {
      console.log('error', error)
      message.error(error.message)
    } finally {
      setIsAddingUser(false)
    }
  }

  const columns = [
    {
      title: enumTableTitleInUserManagement.NO,
      dataIndex: 'no',
      key: 'id',
      ellipsis: true,
      width: '7%',
      align: 'center',
    },
    {
      title: enumTableTitleInUserManagement.NAME,
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: enumTableTitleInUserManagement.EMAIL,
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
    },
    {
      title: enumTableTitleInUserManagement.PACKAGE_NAME,
      dataIndex: 'packageName',
      key: 'packageName',
      ellipsis: true,
    },
    {
      title: enumTableTitleInUserManagement.PRICE + '($)',
      dataIndex: 'price',
      key: 'price',
      ellipsis: true,
    },
    {
      title: (
        <Typography
          style={{
            color: '#ffffff',
          }}
          onClick={() => {
            setCreatedTimeSortValue((prev) => {
              if (prev === ESortColumnValue.ASC) return ESortColumnValue.DESC

              return ESortColumnValue.ASC
            })
          }}
        >
          {enumTableTitleInUserManagement.CREATE_TIME}
        </Typography>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (e: any) => {
        return <>{format(new Date(e), 'dd/MM/yyyy HH:mm:ss')}</>
      },
      sorter: (a: any, b: any) => false,
      sortDirections: [ESortColumnValue.ASC, ESortColumnValue.DESC],
      showSorterTooltip: false,
      sortOrder: createdTimeSortValue,
      ellipsis: true,
      align: 'center',
    },
    {
      title: enumTableTitleInUserManagement.STATUS,
      dataIndex: 'status',
      key: 'status',
      width: '17%',
      render: (text: any, record: ILoginResponse) => {
        let tagStatus = ''
        switch (record.status) {
          case LIST_STATUS_USER[EUserStatus.ACTIVE]:
            tagStatus = 'success'
            break
          case LIST_STATUS_USER[EUserStatus.INACTIVE]:
            tagStatus = ''
            break
          default:
            tagStatus = 'error'
            break
        }

        if (!record.isActive) {
          tagStatus = 'warning'
        }
        return (
          <Tooltip title="View details to change this status">
            <Tag color={tagStatus}>{record.isActive ? text : 'Pending'}</Tag>
          </Tooltip>
        )
      },
    },
    {
      title: enumTableTitleInUserManagement.ACTION,
      key: 'action',
      dataIndex: 'action',
      render: (_: any, record: { id: any }) => (
        <div className="flex space-x-4 items-center justify-center">
          <Tooltip title={t('common:view_detail')}>
            <EyeOutlined
              className="text-lg font-light mr-2.5 cursor-pointer text-[#184f64]"
              onClick={() => {
                navigate(`${PATH_USER_MANAGEMENT}/user-detail/${record.id}`)
              }}
            />
          </Tooltip>
        </div>
      ),
      ellipsis: true,
      width: '12.5%',
      align: 'center',
    },
  ]

  const getAllUsers = () => {
    if (search) {
      const parsedQuery = queryString.parse(search)

      dispatch(
        getAllUsersAction({
          ...parsedQuery,
          sort: `createdAt:${SORT_VALUES?.[createdTimeSortValue]}`,
        })
      )
      return
    }

    dispatch(getAllUsersAction())
  }

  useEffect(() => {
    getAllUsers()
  }, [dispatch, search, pageSize, createdTimeSortValue])

  useEffect(() => {
    if (!search) {
      setSearchValue('')
    }
  }, [search])

  useEffect(() => {
    setSearchValue(searchParams.get('search') ?? '')
  }, [])

  useEffect(() => {
    if (debouncedValue !== null) {
      onSearchUsers()
    }
  }, [debouncedValue])

  return (
    <Card>
      <div className="flex items-center justify-between py-[1.25rem] w-full">
        <div className="max-w-[500px] flex-1 flex-row flex">
          <Input
            placeholder={t('common:user_management_placeholder')}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === `${EKeyBoardCode.ENTER}`) {
                onSearchUsers()
              }
            }}
            prefix={
              <span className="flex items-center justify-center pl-[0.75rem]">
                <SearchOutlined />
              </span>
            }
          />
        </div>
        <div className="flex items-center gap-[16px]">
          <Button type="primary" loading={isDowloading} onClick={onDowloadFile}>
            Export
          </Button>

          <Button type="primary" onClick={onOpenUserModal}>
            Add new user
          </Button>
        </div>
      </div>
      {getAllUsersActionLoading ? (
        <Skeleton paragraph={{ rows: 4 }} />
      ) : (
        <TableWrapper>
          <SharedTable
            columns={columns as any}
            dataSource={users?.map((user, index) => {
              return {
                ...user,
                key: user?.id,
                no: index + 1,
                email: user?.email ?? `--`,
                name: user?.name ?? `--`,
                packageName: user?.packageName ? user?.packageName : `--`,
                price: user?.price ? user?.price + '$' : `--`,
                createdAt: user?.createdAt,
                status: LIST_STATUS_USER[user?.status as EUserStatus],
              }
            })}
            paginationProps={{
              total: usersTotalItems as any,
              pageSize: pageSize as any,
              current: localPage as any,
              showSizeChanger: false,
              onChange(page) {
                onSearchUsers(page)
                setLocalPage(page)
              },
              // onShowSizeChange(current, size) {
              //   setPageSize(size)
              // },
            }}
          />
        </TableWrapper>
      )}
      <AddUserModal
        open={openAddUserModal}
        onClose={onCloseUserModal}
        onSave={onAddUser}
        isLoading={isAddingUser}
      />
    </Card>
  )
}

const TableWrapper = styled.div`
  .ant-table-wrapper,
  .ant-table-content {
    padding: 0 !important;
    margin: 0 !important;
  }
`
