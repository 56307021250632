import { useEffect, useMemo, useState } from 'react'
import { message } from 'antd'
import { t } from 'i18next'
import styled from 'styled-components'
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons'

import { CommonTeacherContentWrapperStyled } from '../../styled'
import { Button, SharedStyledTableWrapper, SharedTable } from 'src/common'
import { HOW_TO_TABLE_COLUMNS } from './configs'
import { HowToContentModal } from './HowToContentModal'
import { HowToDetailModal } from './HowToDetailModal'
import {
  deleteHowToDetailByIdAction,
  getHowToTableAction,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { ETeacherLandingPageTabs } from '@configs'
import ConfirmDeleteModal from 'src/components/staff-and-role-management/ConfirmDeleteModal'

const HowToTableButton = ({
  onEditContent,
  onAddNew,
}: {
  onEditContent: () => void
  onAddNew: () => void
}) => {
  return (
    <HowToTableButtonStyled>
      <Button
        type="primary"
        size="small"
        className="!text-[16px] !bg-amber-500 !border-none"
        icon={<EditOutlined className="!text-[16px]" />}
        onClick={onEditContent}
      >
        {t('teacher:edit_content')}
      </Button>
      <Button
        type="primary"
        size="small"
        className="!text-[16px] !border-none"
        icon={<PlusOutlined className="!text-[16px]" />}
        onClick={onAddNew}
      >
        {t('teacher:add_new')}
      </Button>
    </HowToTableButtonStyled>
  )
}

const HowToTableButtonStyled = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
`

export const HowToTable = ({ activeTab }: { activeTab?: string }) => {
  const dispatch = useAppDispatch()

  const [openModalHowToContent, setOpenModalHowToContent] = useState(false)
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<number | null>(
    null
  )
  const [openHowToDetail, setOpenHowToDetail] = useState<
    boolean | number | string
  >(false)

  const { howToList, loadings } = useAppSelector(
    (state: RootState) => state.teacherLanding
  )
  const isGettingHowToTable = loadings['getHowToTableAction']
  const isDeletingHowToDetail = loadings['deleteHowToDetailByIdAction']

  const handleDeleteHowToDetail = async (id: number | string) => {
    try {
      const res = await dispatch(deleteHowToDetailByIdAction(id)).unwrap()
      if (res) {
        message.success(res?.message)
        dispatch(getHowToTableAction())
      }
    } catch (error: any) {
      message.error(error?.message)
    } finally {
      setIsOpenModalConfirm(null)
    }
  }

  const tableColumns = useMemo(
    () =>
      HOW_TO_TABLE_COLUMNS.map((column) => ({
        ...column,
        ...(column.key === 'action' && {
          render: (_: any, record: any) => (
            <div className="w-full flex items-center gap-4 justify-center">
              <EyeOutlined
                onClick={() => setOpenHowToDetail(record?.id)}
                className="!text-[16px] cursor-pointer"
              />
              <DeleteOutlined
                className="!text-[16px] cursor-pointer !text-red-600"
                onClick={() => setIsOpenModalConfirm(record?.id)}
              />
            </div>
          ),
        }),
      })),
    []
  )

  useEffect(() => {
    if (ETeacherLandingPageTabs.TEACHER_HOW_TO.includes(activeTab ?? '')) {
      dispatch(getHowToTableAction())
    }
  }, [activeTab, dispatch])

  return (
    <>
      {!!isOpenModalConfirm ? (
        <ConfirmDeleteModal
          open
          title={t('teacher:delete_how_to_detail')}
          content={t('teacher:delete_how_to_detail_confirmation')}
          isLoading={!!isDeletingHowToDetail}
          onDelete={() => handleDeleteHowToDetail(isOpenModalConfirm)}
          onClose={() => setIsOpenModalConfirm(null)}
        />
      ) : null}
      {!!openHowToDetail ? (
        <HowToDetailModal
          open={true}
          onClose={() => setOpenHowToDetail(false)}
          {...(typeof openHowToDetail === 'number' && {
            howToId: openHowToDetail,
          })}
        />
      ) : null}
      {openModalHowToContent ? (
        <HowToContentModal
          open={true}
          onClose={() => setOpenModalHowToContent(false)}
        />
      ) : null}
      <CommonTeacherContentWrapperStyled>
        <HowToTableButton
          onAddNew={() => setOpenHowToDetail(true)}
          onEditContent={() => setOpenModalHowToContent(true)}
        />
        <SharedStyledTableWrapper>
          <SharedTable
            columns={tableColumns}
            dataSource={howToList}
            hasIndexColumn={false}
            tableProps={{
              loading: { spinning: !!isGettingHowToTable, size: 'large' },
            }}
            sticky
          />
        </SharedStyledTableWrapper>
      </CommonTeacherContentWrapperStyled>
    </>
  )
}
