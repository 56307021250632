import React from 'react'
import BannerForm from './banner/BannerForm'

type TeacherBannerProps = {
  activeTab: string
}

export const TeacherBanner = (props: TeacherBannerProps) => {
  return (
    <div>
      <BannerForm {...props} />
    </div>
  )
}
