import {
  EAboutUsPageTabs,
  ECommonContentLayout,
  LAYOUT_OPTIONS,
} from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  createLandingAction,
  getLandingAction,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Skeleton, message } from 'antd'
import { t } from 'i18next'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import {
  ILandingFormData,
  IResponseGetLanding,
} from 'src/interfaces/aboutus-management'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import * as z from 'zod'
import SelectMedia from '../gallery/SelectMedia'
import SelectMediaModal from '../gallery/SelectMediaModal'
import { ShareSelectInput } from '../shared'
import queryString from 'query-string'
import { InputWrapperGridStyled } from '@theme'
interface IAboutUsPageTabItemProps {
  activeTab?: string
}

const LandingTab = ({ activeTab }: IAboutUsPageTabItemProps) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined)
  const [imageData, setImageData] = useState('')
  const [isUploading, setIsUploading] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const dispatch = useAppDispatch()
  const previousLandingDataRef = useRef<
    IResponseGetLanding | (undefined & { previousUrl: string })
  >()
  const [isChange, setIsChange] = useState(false)

  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)

  const createLandingActionLoading = useAppSelector(
    (state) => state.aboutUsManagement.loadings?.createLandingActionLoading
  )
  const landingUploadImageLoading = useAppSelector(
    (state) => state.aboutUsManagement.loadings?.uploadLandingImageActionLoading
  )
  const getLandingActionLoading = useAppSelector(
    (state) => state.aboutUsManagement.loadings?.getLandingActionLoading
  )

  const landingData = useAppSelector((state) => state.aboutUsManagement.landing)

  const [imageSrc, setImageSrc] = useState(landingData?.media?.url ?? '')
  const [imageMobileSrc, setImageMobileSrc] = useState(
    landingData?.mediaMobile?.url ?? ''
  )

  const isDisabledSaveButton = useMemo(() => {
    return (
      createLandingActionLoading || landingUploadImageLoading || isUploading
    )
  }, [createLandingActionLoading, landingUploadImageLoading, isUploading])

  const landingSchema = z.object({
    title: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(100, {
        message: t('error:landing_title_length_error') as string,
      }),
    titleInDutch: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(100, {
        message: t('error:landing_title_in_dutch_length_error') as string,
      }),
    mediaDescription: z
      .string()
      .trim()
      .max(255, {
        message: t('error:landing_description_length_error') as string,
      }),
    mediaDescriptionInDutch: z
      .string()
      .trim()
      .max(255, {
        message: t('error:landing_description_length_error') as string,
      }),
    mediaId: z.number({
      required_error: t('error:field_required') as string,
    }),
    mediaMobileId: z.number({
      required_error: t('error:field_required') as string,
    }),
    layout: z.string(),
    textColor: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(10, {
        message: t('error:text_color_length_error') as string,
      }),
  })

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
    reset,
    clearErrors,
    setError,
    getValues,
    trigger,
  } = useForm<ILandingFormData>({
    defaultValues: {
      title: landingData?.title ?? '',
      titleInDutch: landingData?.titleInDutch ?? '',
      mediaDescription: landingData?.mediaDescription ?? '',
      mediaDescriptionInDutch: landingData?.mediaDescriptionInDutch ?? '',
      mediaId: landingData?.media?.id ?? 0,
      mediaMobileId: landingData?.mediaMobile?.id ?? 0,
      textColor: '',
      layout: ECommonContentLayout.LAYOUT_ONE,
    },
    resolver: zodResolver(landingSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: ILandingFormData) => {
    try {
      const responseCreateLanding = await dispatch(
        createLandingAction(data)
      ).unwrap()

      if (responseCreateLanding.success) {
        message.success({
          content: responseCreateLanding?.message ?? t('common:save_success'),
        })
      }
    } catch (error: any) {
      message.error({
        content: error?.message ?? t('error:failed_to_save_landing'),
      })
    }
  }

  const onSave = () => {
    handleSubmit(onSubmit)()
    setIsChange(false)
  }

  const onPreview = (data: ILandingFormData) => {
    const payload = {
      title: data.title,
      url: imageSrc,
      urlMobile: imageMobileSrc,
      type: 'banner',
      textColor: data.textColor,
      mediaDescription: data.mediaDescription,
      layout: data.layout,
    }
    const stringified = queryString.stringify(payload)

    const URL = `${process.env.REACT_APP_PUBLIC_WEB_DOMAIN}/preview-content?${stringified}`
    window.open(URL, '_blank')
  }

  const onCancel = () => {
    reset({
      mediaDescription: previousLandingDataRef.current?.mediaDescription,
      mediaDescriptionInDutch:
        previousLandingDataRef.current?.mediaDescriptionInDutch,
      mediaId: previousLandingDataRef.current?.media.id,
    })
    // setImageData(previousLandingDataRef.current?.media?.url ?? '')
    setImageSrc(landingData?.media?.url ?? '')
    setImageMobileSrc(landingData?.mediaMobile?.url ?? '')
    setIsChange(false)
  }

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setImageSrc(data?.baseUrl ?? '')
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
    } else if (selectedField === 'mobile') {
      setImageMobileSrc(data?.baseUrl ?? '')
      setValue('mediaMobileId', data?.id ?? 0)
      onChangeMobileRef.current(data?.id ?? 0)
    }

    setShowModalSelectMedia(false)
    setIsChange(true)
  }
  useEffect(() => {
    if (activeTab === EAboutUsPageTabs.LANDING) {
      const getLandings = async () => {
        try {
          const responseGetLanding = await dispatch(getLandingAction()).unwrap()

          if (responseGetLanding.success) {
            const landingData = responseGetLanding.data

            previousLandingDataRef.current = landingData

            setValue('mediaDescription', landingData?.mediaDescription)
            setValue('title', landingData?.title)
            setValue('titleInDutch', landingData?.titleInDutch)
            setValue(
              'mediaDescriptionInDutch',
              landingData?.mediaDescriptionInDutch
            )
            setValue('mediaId', landingData?.media?.id)
            setValue('mediaMobileId', landingData?.mediaMobile?.id)
            setValue('textColor', landingData?.metaData?.textColor)
            setValue('layout', landingData?.metaData?.layout)

            setImageSrc(landingData?.media?.url ?? '')
            setImageMobileSrc(landingData?.mediaMobile?.url ?? '')
            if (landingData?.media?.url) {
              setImageData(() => landingData?.media?.url)
            }
            setIsChange(false)
          }
        } catch (error: any) {
          message.error({
            content: error.message ?? t('error:failed_to_get_landing'),
          })
        }
      }
      getLandings()
    }
  }, [activeTab])

  return (
    <Fragment>
      <div className="bg-white p-5 pt-[60px]">
        {getLandingActionLoading ? (
          <>
            <Skeleton
              paragraph={{ rows: 4 }}
              style={{
                marginTop: 10,
              }}
            />
          </>
        ) : (
          <>
            <InputWrapperGridStyled>
              <Controller
                name="title"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      alignment="col"
                      label="Title"
                      required
                      size="middle"
                      value={value}
                      onChange={(e) => {
                        onChange(e?.target?.value)
                        setIsChange(true)
                      }}
                      errors={error?.message}
                    />
                  )
                }}
              />

              <Controller
                name="titleInDutch"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      alignment="col"
                      label="Title in Netherlands"
                      required
                      size="middle"
                      value={value}
                      onChange={(e) => {
                        onChange(e?.target?.value)
                        setIsChange(true)
                      }}
                      errors={error?.message}
                    />
                  )
                }}
              />
              <Controller
                name="mediaDescription"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      alignment="col"
                      label="Description"
                      size="middle"
                      value={value}
                      onChange={(e) => {
                        onChange(e?.target?.value)
                        setIsChange(true)
                      }}
                      errors={error?.message}
                    />
                  )
                }}
              />
              <Controller
                name="mediaDescriptionInDutch"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      alignment="col"
                      label="Description in Netherlands"
                      size="middle"
                      value={value}
                      onChange={(e) => {
                        onChange(e?.target?.value)
                        setIsChange(true)
                      }}
                      errors={error?.message}
                    />
                  )
                }}
              />

              <Controller
                name="layout"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <ShareSelectInput
                      containerClassName="flex flex-col gap-[10px] !min-w-0 w-[30%]"
                      inlineLabelClassName="!text-start !block !m-0"
                      data={LAYOUT_OPTIONS}
                      onChange={(value: string) => {
                        setIsChange(true)
                        onChange(value)
                      }}
                      label={'Layout'}
                      errors={error?.message}
                      value={value}
                    />
                  )
                }}
              />

              <Controller
                name="textColor"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Text color"
                      alignment="col"
                      name="textColor"
                      type="color"
                      required
                      onChange={(e) => {
                        onChange(e?.target?.value)
                        setIsChange(true)
                      }}
                      value={value}
                      errors={error?.message}
                      isDisableShadow
                    />
                  )
                }}
              />
              <div className="flex flex-col gap-1 items-end">
                <Controller
                  name="mediaId"
                  control={control}
                  render={({ field: { onChange }, fieldState: { error } }) => {
                    onChangeDesktopRef.current = onChange
                    return (
                      <CustomDragger
                        label="Image"
                        name="mediaId"
                        id="mediaId"
                        containerClassName="mt-3"
                        alignment="col"
                        required
                        onLoadEnd={(data) => {
                          setValue('mediaId', data?.id ?? 0)
                          onChange(data?.id ?? 0)
                          setIsChange(true)
                          setImageSrc((prev) => data.original ?? prev)
                        }}
                        errors={error?.message}
                        initResource={imageSrc}
                        reset={getValues('mediaId') === 0}
                        limitFileSize={5}
                        allowFileTypes={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg',
                        ]}
                        changeLoading={setIsModalLoading}
                        uploadType={EMediaType.IMAGE as any}
                        uploadCategory={EMediaCategory.CONTENT}
                      />
                    )
                  }}
                />
                <SelectMedia
                  isNotGrid
                  onClickSelectMedia={() => {
                    setSelectedField((pre) => 'desktop')
                    setShowModalSelectMedia(true)
                  }}
                />
              </div>

              <div className="flex flex-col gap-1 items-end mb-4">
                <Controller
                  name={'mediaMobileId'}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    onChangeMobileRef.current = onChange
                    return (
                      <>
                        <CustomDragger
                          label="Image mobile"
                          containerClassName="mt-[10px]"
                          alignment="col"
                          onLoadEnd={(data) => {
                            setValue('mediaMobileId', data?.id ?? 0)
                            onChange(data?.id ?? 0)
                            setIsChange(true)
                          }}
                          name="mediaMobileId"
                          id="mediaMobileId"
                          required
                          errors={error?.message}
                          multiple={false}
                          initResource={imageMobileSrc}
                          reset={getValues('mediaMobileId') === 0}
                          allowFileTypes={[
                            'image/png',
                            'image/jpeg',
                            'image/jpg',
                          ]}
                          limitFileSize={5}
                          changeLoading={setIsModalLoading}
                          uploadType={EMediaType.IMAGE as any}
                          uploadCategory={EMediaCategory.CONTENT}
                        />
                      </>
                    )
                  }}
                />
                <SelectMedia
                  isNotGrid
                  onClickSelectMedia={() => {
                    setSelectedField((pre) => 'mobile')
                    setShowModalSelectMedia(true)
                  }}
                />
              </div>
            </InputWrapperGridStyled>

            <div className="landing-image-wrapper mt-6">
              <div className="landing-image-action mt-5">
                <div className="landing-save-cancel flex items-center justify-end">
                  <Button
                    className="mr-5"
                    type="primary"
                    onClick={handleSubmit(onPreview)}
                  >
                    Preview
                  </Button>
                  {/* <Button
                    className="mr-5"
                    onClick={onCancel}
                    disabled={isModalLoading || !isChange}
                  >
                    Reset
                  </Button> */}
                  <Button
                    type="primary"
                    onClick={onSave}
                    loading={isDisabledSaveButton || isModalLoading}
                    disabled={
                      isDisabledSaveButton || isModalLoading || !isChange
                    }
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
        {showModalSelectMedia ? (
          <>
            <SelectMediaModal
              isOpen={showModalSelectMedia}
              onClose={onCloseModalSelectMedia}
              mediaType={EMediaType.IMAGE}
              category={EMediaCategory.CONTENT}
              onSelectMedia={onSelectMedia}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </Fragment>
  )
}

export default LandingTab
