import { DiffOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { ETermPoliciesTabs, TERM_POLICIES_PAGE } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { Skeleton, Tabs, message } from 'antd'
import { t } from 'i18next'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Text } from 'src/common'
import {
  ITermAndPoliciesFormData,
  ITermAndPolicyDetail,
} from 'src/interfaces/content-management'
import { EMediaCategory } from 'src/interfaces/gallery'
import ContentTab from 'src/pages/blog/content/ContentTab'
import MetaTab from 'src/pages/blog/content/MetaTab'
import {
  createTermAndPolicies,
  getTermAndPolicies,
} from 'src/redux/actions/content-management'
import styled from 'styled-components'
import { z } from 'zod'

interface ITermAndPolicyPageProps {
  activeTabParent?: string
}

const TermAndPolicyPage = ({ activeTabParent }: ITermAndPolicyPageProps) => {
  const [isUploading, setIsUploading] = useState(false)

  const [activeTab, setActiveTab] = useState<string>(ETermPoliciesTabs.META)
  const [dataSubmit, setDataSubmit] = useState<ITermAndPoliciesFormData>(
    {} as ITermAndPoliciesFormData
  )
  const [detailTermAndPolicy, setDetailTermAndPolicy] =
    useState<ITermAndPolicyDetail>()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const dispatch = useAppDispatch()

  const isGetTermAndPoliciesActionLoading = useAppSelector(
    (state: RootState) => state?.content?.loadings?.GET_TERM_AND_POLICY_LOADING
  )

  const currentTermAndPolicy = useAppSelector(
    (state: RootState) => state?.content?.termAndPolicies
  )

  const termPoliciesSchema = z.object({
    // Content
    content: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(50000, {
        message: t('error:field_max_50k') as string,
      }),
    contentInDutch: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(50000, {
        message: t('error:field_max_50k') as string,
      }),
    // Title
    metaTitle: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(100, {
        message: t('error:metaTitle_length_error') as string,
      }),
    // Description
    metaDescription: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaDescription_length_error') as string,
      }),
    canonical: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:canonical_length_error') as string,
      }),
    schemaMarkup: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaDescription_length_error') as string,
      }),
    // Keywords
    keywords: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaKeyword_length_error') as string,
      }),
    // Image
    metaImage: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
  })

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    clearErrors,
    setError,
    getValues,
    reset,
  } = useForm<ITermAndPoliciesFormData>({
    defaultValues: {
      content: currentTermAndPolicy?.content || '',
      contentInDutch: currentTermAndPolicy?.contentInDutch || '',
      metaTitle: currentTermAndPolicy?.metaData?.metaTitle || '',
      metaDescription: currentTermAndPolicy?.metaData?.metaDescription || '',
      canonical: currentTermAndPolicy?.metaData?.canonical || '',
      schemaMarkup:
        currentTermAndPolicy?.metaData?.schemaMarkup || 'schema mark up',
      keywords: currentTermAndPolicy?.metaData?.keywords || '',
      metaImage: currentTermAndPolicy?.metaData?.metaImage || '',
    },
    resolver: zodResolver(termPoliciesSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  // Check if there is any error in each tab
  const isContentTabError = useMemo(() => {
    return (
      !!errors?.content || !!errors.content?.message || !!errors?.content?.type
    )
  }, [errors?.content])

  const isContentInDutchTabError = useMemo(() => {
    return (
      !!errors?.contentInDutch ||
      !!errors.contentInDutch?.message ||
      !!errors?.contentInDutch?.type
    )
  }, [errors?.contentInDutch])

  const isMetaTabError = useMemo(() => {
    return (
      !!errors?.metaTitle ||
      !!errors?.metaDescription ||
      !!errors?.keywords ||
      !!errors?.metaImage ||
      !!errors?.canonical
    )
  }, [
    errors?.metaTitle,
    errors?.metaDescription,
    errors?.keywords,
    errors?.metaImage,
    errors?.canonical,
  ])
  // End check if there is any error in each tab

  const tabItems = [
    {
      label: (
        <Text
          className="blog-content-label"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: `${isContentTabError ? '#B91C1C' : '#009BB7'}`,
            // padding: '16px 0',
          }}
        >
          <div className="label-wrapper-content">
            {ETermPoliciesTabs.CONTENT_IN_ENGLISH}
          </div>
        </Text>
      ),
      key: ETermPoliciesTabs.CONTENT_IN_ENGLISH,
      children: (
        <ContentTab
          activeTab={activeTab}
          control={control}
          setValue={setValue}
          clearErrors={clearErrors}
          setError={setError}
          errors={errors}
          getValues={getValues}
          contentName="content"
          value={detailTermAndPolicy?.content}
        />
      ),
    },
    // Content vs Content in Netherlands
    {
      label: (
        <Text
          className="blog-content-label"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: `${isContentInDutchTabError ? '#B91C1C' : '#009BB7'}`,
            // padding: '16px 0',
          }}
        >
          <div className="label-wrapper-content">
            {ETermPoliciesTabs.CONTENT_IN_NETHERLANDS}
          </div>
        </Text>
      ),
      key: ETermPoliciesTabs.CONTENT_IN_NETHERLANDS,
      children: (
        <ContentTab
          activeTab={activeTab}
          control={control}
          setValue={setValue}
          clearErrors={clearErrors}
          setError={setError}
          errors={errors}
          getValues={getValues}
          contentName="contentInDutch"
          value={detailTermAndPolicy?.contentInDutch}
        />
      ),
    },
    {
      label: (
        <Text
          className="blog-meta-label"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: `${isMetaTabError ? '#B91C1C' : '#009BB7'}`,
            // padding: '16px 0',
          }}
        >
          <div className="label-wrapper-content">{ETermPoliciesTabs.META}</div>
        </Text>
      ),
      key: ETermPoliciesTabs.META,
      children: (
        <MetaTab
          activeTab={activeTab}
          control={control}
          getValues={getValues}
          setValue={setValue}
          setIsUploading={setIsUploading}
        />
      ),
    },
  ]

  const onSubmit = async (data: ITermAndPoliciesFormData) => {
    try {
      const response = await dispatch(createTermAndPolicies(data)).unwrap()

      if (response.success) {
        message.success({
          content: response?.message ?? '',
        })
      }
    } catch (error: any) {
      console.log('error ->', error)
      message.error({
        content: error?.message ?? '',
      })
    }
  }

  const handleClickSave = () => {
    handleSubmit(onSubmit)()
  }
  const handleClickCancel = () => {
    console.log('Cancel')
    reset()
  }

  const getTermAndPolicy = async () => {
    try {
      const response = await dispatch(getTermAndPolicies()).unwrap()
      if (response.success) {
        const { data } = response
        setValue('content', data?.content ?? '')
        setValue('contentInDutch', data?.contentInDutch ?? '')
        setValue('metaTitle', data?.metaData?.metaTitle ?? '')
        setValue('metaDescription', data?.metaData?.metaDescription ?? '')
        setValue('keywords', data?.metaData?.keywords ?? '')
        setValue('canonical', data?.metaData?.canonical ?? '')
        // setValue('schemaMarkup', data?.metaData?.schemaMarkup ?? '')
        setValue('metaImage', data?.metaData?.metaImage ?? '')
        setDetailTermAndPolicy(() => response?.data)
      }
    } catch (error: any) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (activeTabParent === TERM_POLICIES_PAGE) {
      getTermAndPolicy()
    }
  }, [activeTabParent])

  useEffect(() => {
    if (activeTabParent !== TERM_POLICIES_PAGE) {
      reset()
    }
  }, [activeTabParent])

  return (
    <Fragment>
      {isGetTermAndPoliciesActionLoading ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <TermAndPolicyPageStyled className="blog-content-m-left intro-y col-span-12 lg:col-span-8">
          <div className="intro-y box mt-5 overflow-hidden ">
            <Tabs
              destroyInactiveTabPane={true}
              defaultActiveKey={ETermPoliciesTabs.META}
              type="card"
              size={'small'}
              items={tabItems}
              activeKey={activeTab}
              onChange={(e) => {
                setActiveTab(e)
              }}
            />
          </div>

          <div className="term-policy-action mt-5 flex items-center justify-end gap-6">
            {/* <Button
              type="default"
              size="small"
              id="blog-form"
              onClick={() => {
                handleClickCancel()
              }}
            >
              Cancel
            </Button> */}
            <Button
              type="primary"
              size="small"
              id="blog-form"
              onClick={() => {
                handleClickSave()
              }}
            >
              Save
            </Button>
          </div>
        </TermAndPolicyPageStyled>
      )}
    </Fragment>
  )
}

export default TermAndPolicyPage

const TermAndPolicyPageStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },
  }
})
