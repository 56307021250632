import { Card, Col, Empty, Row, Typography } from 'antd'
import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { z } from 'zod'

import moment from 'moment'
import { Button, Input, Loading, TextArea } from 'src/common'
import CommentItem from 'src/components/post-management/CommentItem'
import {
  PATH_POST_MANAGEMENT,
  VIDEO_FILTER,
  thunkActionLoading,
} from 'src/configs'
import { RouterParams } from 'src/interfaces'
import { TCommentItem, TPostDetail } from 'src/interfaces/post-management'
import { useAppDispatch, useAppSelector } from 'src/redux'
import { selectPostLoading } from 'src/redux/Slices/post-management'
import { getAllComments, getPostById } from 'src/redux/actions/post-management'
import { ShareSelectInput } from '@components'

type Props = {}

const replySchema = z.object({
  content: z.string(),
})

const PostDetailScreen = (props: Props) => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const { postId } = useParams<RouterParams['PostDetail']>()

  const [postDetail, setPostDetail] = useState<TPostDetail>()
  const [page, setPage] = useState<number>(1)
  const [comments, setComments] = useState<{
    page: number
    total: number
    items: TCommentItem[]
  }>({
    page: 1,
    total: 0,
    items: [],
  })

  const isHasMore = (comments?.items?.length ?? 0) < (comments?.total ?? 0)

  const tagOptions =
    postDetail?.tags?.map((item) => {
      return {
        value: String(item?.id),
        label: item?.name,
      }
    }) ?? []

  const tagValues =
    postDetail?.tags?.map((tag) => String(tag?.id)).filter((it) => !!it) ?? []

  const onGoBack = () => {
    navigate(PATH_POST_MANAGEMENT)
  }

  const loadingComments = useAppSelector((state) =>
    selectPostLoading(state, thunkActionLoading.GET_COMMENTS)
  )

  const loadingDetails = useAppSelector((state) =>
    selectPostLoading(state, thunkActionLoading.GET_PACKAGE_POST_DETAIL_LOADING)
  )

  const getDetail = async () => {
    if (postId) {
      try {
        const response = await dispatch(
          getPostById({ id: Number(postId) })
        ).unwrap()
        setPostDetail(response)
      } catch (error: any) {
        console.log(error)
        toast.error(error?.data?.message)
      }
    }
  }

  const getCommentsActions = async (page = 1) => {
    if (postId) {
      try {
        const response: any = await dispatch(
          getAllComments({ id: Number(postId), page })
        ).unwrap()
        if (response) {
          setComments((prev) => {
            if (page === 1) {
              return response
            }
            return {
              ...response,
              items: [...prev?.items, ...response?.items],
            }
          })
          setPage(response?.page)
        }
      } catch (error: any) {
        console.log(error)
        toast.error(error?.data?.message)
      }
    }
  }

  useEffect(() => {
    getDetail()
    getCommentsActions()
  }, [postId])

  return (
    <Card
      style={{
        borderRadius: '20px',
        paddingRight: '100px',
        paddingLeft: '100px',
        minHeight: '600px',
      }}
    >
      <div className="w-full  flex flex-col items-center ">
        <Typography className="text-[24px] font-bold text-black">
          Post detail
        </Typography>
        {loadingDetails ? (
          <div className="w-full flex items-center justify-center mt-[24px]">
            <Loading />
          </div>
        ) : (
          <>
            <Row gutter={64} className="w-full">
              <Col span={12}>
                <TextArea
                  label="Content EN"
                  value={postDetail?.content ?? ''}
                  disabled={true}
                  autoSize={{ minRows: 8, maxRows: 16 }}
                  labelClassName="mb-[12px] inline-block font-semibold"
                />
                <ShareSelectInput
                  label="Tags"
                  data={tagOptions}
                  value={tagValues}
                  disabled={true}
                  containerClassName="mt-[24px] "
                  labelClassName="font-semibold"
                  mode="multiple"
                />
              </Col>
              <Col span={12}>
                <TextArea
                  label="Content NL"
                  value={postDetail?.contentInDutch ?? ''}
                  disabled={true}
                  autoSize={{ minRows: 8, maxRows: 16 }}
                  labelClassName="mb-[12px] inline-block font-semibold"
                />
              </Col>
            </Row>
            <Row gutter={64} className="mt-[24px] w-full">
              <Col span={24}>
                <Typography className="text-[14px] text-black font-semibold">
                  Photos and videos
                </Typography>
                <div className="flex items-center justify-start gap-[16px] flex-wrap mt-[12px]">
                  {postDetail?.mediaLists?.map((image, index) => {
                    const isVideo = VIDEO_FILTER.test(image?.type)
                    return isVideo ? (
                      <div className="w-[200px] h-[200px] rounded-[8px] flex items-center justify-center rounded-[8px] relative border">
                        <ReactPlayer
                          url={image?.url}
                          loop
                          controls={true}
                          style={{
                            width: '100%',
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            borderRadius: '8px',
                          }}
                          height={200}
                          width={200}
                          className="react-player !rounded-[8px] !overflow-hidden"
                        />
                      </div>
                    ) : (
                      <div
                        key={image?.id}
                        className="border rounded-[8px] relative overflow-hidden"
                      >
                        <img
                          src={image?.url}
                          alt="iamge"
                          className="w-[200px] h-[200px] object-contain"
                          key={index}
                        />
                      </div>
                    )
                  })}
                </div>
              </Col>
            </Row>

            <Row
              gutter={64}
              className="w-full flex items-center justify-start gap-[60px] mt-[24px]"
            >
              <Col span={24}>
                <Row className="gap-[24px]">
                  <Typography className="text-[14px] font-semibold text-black">
                    {`${postDetail?.reactionNumber?.total} ${
                      (postDetail?.reactionNumber?.total ?? 0) > 1
                        ? 'reactions'
                        : 'reaction'
                    }`}
                  </Typography>
                  <Typography className="text-[14px] font-semibold text-black">
                    {`${postDetail?.shareNumber} ${
                      (postDetail?.shareNumber ?? 0) > 1 ? 'people' : 'person'
                    } shared this post`}
                  </Typography>
                </Row>
              </Col>
            </Row>

            {!postDetail?.published && postDetail?.publishedDate && (
              <Row gutter={64} className="w-full mt-[24px]">
                <div className="flex items-center  gap-1 w-full px-[32px]">
                  <span className="text-[16px] ">{`This post will be posted on `}</span>
                  <span className="text-[16px] font-medium">
                    {`${moment(postDetail?.publishedDate).format(
                      'YYYY-MM-DD HH:mm'
                    )}`}
                  </span>
                </div>
              </Row>
            )}
            <Row gutter={64} className="w-full mt-[24px]">
              <Col span={24}>
                <Typography className="font-semibold text-black text-[14px]">
                  Comments
                </Typography>
                <div className="w-full max-h-[1000px] overflow-y-scroll">
                  {comments?.total === 0 && !loadingComments && <Empty />}
                  {!!comments?.total &&
                    comments?.items?.map((comment) => {
                      return (
                        <CommentItem
                          key={comment?.id}
                          item={comment}
                          isParentComments={true}
                        />
                      )
                    })}
                  {loadingComments && (
                    <div className="w-full flex items-center justify-start mt-[24px]">
                      <Loading />
                    </div>
                  )}
                  {isHasMore && !loadingComments && (
                    <div
                      className="w-full flex items-center justify-start mt-[24px] cursor-pointer"
                      onClick={() => {
                        getCommentsActions(page + 1)
                      }}
                    >
                      <Typography className="text-black text-[14px] font-medium ">{`View more comment`}</Typography>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row
              gutter={64}
              className="w-full mt-[24px] flex items-center justify-center"
            >
              <div className="flex items-center justify-center gap-[24px]">
                <Button
                  type="primary"
                  size="middle"
                  className="submit__btn login-btn"
                  onClick={onGoBack}
                >
                  Back
                </Button>

                <Button
                  type="primary"
                  size="middle"
                  className="submit__btn login-btn"
                  onClick={() => {
                    navigate(`/community-management/create-new-post/${postId}`)
                  }}
                >
                  Edit
                </Button>
              </div>
            </Row>
          </>
        )}
      </div>
    </Card>
  )
}

export default PostDetailScreen
