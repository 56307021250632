import { HOME_SEO } from '@configs'
import { zodResolver } from '@hookform/resolvers/zod'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { message, Skeleton } from 'antd'
import { t } from 'i18next'
import { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Button } from 'src/common'
import { EMediaCategory } from 'src/interfaces/gallery'
import { ISeoConfigDetail } from 'src/interfaces/seo-config'
import MetaTab from 'src/pages/blog/content/MetaTab'
import {
  getSeoConfigByCourse,
  updateSeoConfigByCourseId,
} from 'src/redux/actions/seo-config'
import * as z from 'zod'

interface IHomeSeoPageProps {
  activeTab?: string
}

const CourseSeoConfigScreen = ({ activeTab }: IHomeSeoPageProps) => {
  const [isUploading, setIsUploading] = useState(false)

  const dispatch = useAppDispatch()

  const detailSeoConfig = useAppSelector((state: RootState) => state.seoConfig)

  const isGetSeoConfigLoading = useAppSelector(
    (state: RootState) =>
      state.seoConfig?.loadings?.getSeoConfigDetailActionLoading
  )

  const isUpdateSeoConfigLoading = useAppSelector(
    (state: RootState) => state.seoConfig?.loadings?.UPDATE_SEO_CONFIG_LOADING
  )

  const homeSeoSchema = z.object({
    metaTitle: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(100, {
        message: t('error:metaTitle_length_error') as string,
      }),
    metaDescription: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaDescription_length_error') as string,
      }),
    canonical: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:canonical_length_error') as string,
      }),
    metaImage: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    keywords: z
      .string({
        invalid_type_error: t('error:field_required') as string,
      })
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaKeyword_length_error') as string,
      }),
  })

  const { id } = useParams()
  const courseId: any = id

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { isDirty },
  } = useForm<ISeoConfigDetail>({
    defaultValues: {
      metaTitle: '',
      metaDescription: '',
      canonical: '',
      metaImage: '',
      keywords: '',
    },
    resolver: zodResolver(homeSeoSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onSubmit = async (data: any) => {
    try {
      const seoConfigData = {
        metaData: {
          ...data,
        },
        id: courseId,
      }
      const response = await dispatch(
        updateSeoConfigByCourseId(seoConfigData)
      ).unwrap()

      if (response.success) {
        message.success('Update seo config successfully!')
      }
    } catch (error: any) {
      message.success('Something went wrong!')
    }
  }

  const handleClickCancel = () => {
    setValue('metaTitle', detailSeoConfig?.seoConfig?.metaData?.metaTitle || '')
    setValue(
      'metaDescription',
      detailSeoConfig?.seoConfig?.metaData?.metaDescription || ''
    )
    setValue('canonical', detailSeoConfig?.seoConfig?.metaData?.canonical || '')
    setValue('metaImage', detailSeoConfig?.seoConfig?.metaData?.metaImage || '')
    setValue('keywords', detailSeoConfig?.seoConfig?.metaData?.keywords || '')
  }

  const fetchSeoConfigData = async () => {
    try {
      const response = await dispatch(getSeoConfigByCourse(courseId)).unwrap()
      if (response.success) {
        setValue('metaTitle', response.data?.metaData?.metaTitle || '')
        setValue(
          'metaDescription',
          response.data?.metaData?.metaDescription || ''
        )
        setValue('canonical', response.data?.metaData?.canonical || '')
        setValue('metaImage', response.data?.metaData?.metaImage || '')
        setValue('keywords', response.data?.metaData?.keywords || '')
      }
    } catch (error: any) {
      message.error(error?.message ?? '')
    }
  }

  useEffect(() => {
    if (activeTab === HOME_SEO) {
      fetchSeoConfigData()
    }
  }, [activeTab])

  return (
    <Fragment>
      {isGetSeoConfigLoading ? (
        <>
          <Skeleton
            paragraph={{ rows: 4 }}
            style={{
              marginTop: 10,
            }}
          />
        </>
      ) : (
        <div className="bg-white rounded-lg">
          <MetaTab
            activeTab={activeTab}
            control={control}
            getValues={getValues}
            setValue={setValue}
            setIsUploading={setIsUploading}
            isShowSelectMedia
            category={EMediaCategory.COURSE}
          />

          <div className="term-policy-action mr-5 pb-5 flex items-center justify-end gap-6">
            {/* <Button
              type="default"
              size="small"
              id="blog-form"
              onClick={handleClickCancel}
            >
              Reset
            </Button> */}
            <Button
              type="primary"
              size="small"
              id="blog-form"
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty}
              loading={isUpdateSeoConfigLoading}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default CourseSeoConfigScreen
