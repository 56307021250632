import { MAX_LENGTH_TEXT } from '@configs'
import { themes } from '@theme'
import { formatTextWithMaxLength } from '@utils'
import { Typography } from 'antd'
import styled from 'styled-components'

export type DescriptionDonutProps = {
  title: string
  bgColor: string
  revenue: string | number
  percentage: number
}

export const DescriptionDonut = (props: DescriptionDonutProps) => {
  const { title, bgColor, revenue, percentage } = props
  return (
    <DescriptionWrapper>
      <DescriptionItemStyled>
        <DescriptionSubItemStyled>
          <DotStyled $bgColor={bgColor} />
          <TextWrapper className="!text-[14px] !font-medium">
            {formatTextWithMaxLength(title, MAX_LENGTH_TEXT)}
          </TextWrapper>
        </DescriptionSubItemStyled>
        <DescriptionSubItemStyled>
          <Typography.Title level={3} className="!text-[14px] !m-0">
            {revenue}
          </Typography.Title>
          <PercentageWrapper>{percentage}%</PercentageWrapper>
        </DescriptionSubItemStyled>
      </DescriptionItemStyled>
    </DescriptionWrapper>
  )
}

const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`
const DotStyled = styled.div<{ $bgColor?: string }>`
  width: 14px;
  height: 14px;
  background-color: ${(p) => p.$bgColor || `${themes.theme.light.colors.body}`};
  border-radius: 999px;
`

const DescriptionItemStyled = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const DescriptionSubItemStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const PercentageWrapper = styled.span`
  width: fit-content;
  height: fit-content;
  min-width: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 8px;
  background-color: ${themes.theme.dark.colors.miniDashboard
    .lightYellowSecondary};
  color: ${themes.theme.dark.colors.miniDashboard.darkYellowSecondary};
`

const TextWrapper = styled(Typography.Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
`
