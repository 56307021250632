import React, { useMemo } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { themes } from '@theme'
import { formatCurrency } from '@utils'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

type MultiBarChartComponentProps = {
  data: {
    labels: string[]
    datasets: {
      label: string
      data: number[]
      backgroundColor: string
    }[]
  }
  isPriceChart?: boolean
}

export const MultiBarChartComponent = (props: MultiBarChartComponentProps) => {
  const { data, isPriceChart = false } = props

  const options: ChartOptions<'bar'> = useMemo(() => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          align: 'center',
          fullSize: true,
          maxHeight: 150,
          labels: {
            boxWidth: 13,
            boxHeight: 13,
            borderRadius: 999,
            usePointStyle: true,
            pointStyle: 'circle',
          },
          onHover: function (e: any) {
            e.native.target.style.cursor = 'pointer'
          },
        },
        tooltip: {
          backgroundColor: themes.theme.light.colors.body,
          borderColor: themes.theme.light.colors.primary,
          borderWidth: 1,
          titleColor: themes.theme.light.colors.text,
          bodyColor: themes.theme.light.colors.text,
          callbacks: {
            label: function (context: any) {
              return isPriceChart
                ? formatCurrency(context.dataset.data[context.dataIndex])
                : context.dataset.data[context.dataIndex].toString()
            },
          },
        },
      },
      scales: {
        x: {
          border: {
            display: false,
          },
          grid: {
            display: false,
            drawTicks: false,
          },
          ticks: {
            padding: 24,
          },
        },
      },
    }
  }, [isPriceChart])
  
  return <Bar options={options} data={data} />
}
