import { memo } from 'react'
import { t } from 'i18next'
import { message, Skeleton, Typography } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import styled from 'styled-components'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { AppModal } from '@components'
import { Button, Input } from 'src/common'
import { ButtonWrapper } from 'src/pages/teacher-request-detail/fill-in-teacher-information/style'
import { HOW_TO_CONTENT_DEFAULT_VALUES, howToContentSchema } from './configs'
import {
  createEditHowToContentAction,
  getHowToContentAction,
  RootState,
  useAppSelector,
  useAppDispatch,
} from '@redux'
import { IHowToContentInput } from '@interfaces'

interface IProps {
  open: boolean
  onClose: () => void
}

export const HowToContentModal = memo(({ open, onClose }: IProps) => {
  const dispatch = useAppDispatch()

  const { howToContent, loadings } = useAppSelector(
    (state: RootState) => state.teacherLanding
  )
  const isSubmitting = loadings['createEditHowToContentAction']
  const isGettingHowToContent = loadings['getHowToContentAction']

  const { control, handleSubmit, reset } = useForm<IHowToContentInput>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: zodResolver(howToContentSchema),
    defaultValues: HOW_TO_CONTENT_DEFAULT_VALUES,
  })

  const getHowToContent = async () => {
    await dispatch(getHowToContentAction())

    if (!!Object.keys(howToContent ?? {})?.length) {
      reset({
        ...howToContent,
        subTitle: howToContent?.subTitle ?? '',
        subTitleInDutch: howToContent?.subTitleInDutch ?? '',
        button: howToContent?.metaData?.button ?? '',
        buttonInDutch: howToContent?.metaData?.buttonInDutch ?? '',
      })
    }
  }

  const handleSubmitHowToContent = async (params: IHowToContentInput) => {
    try {
      const res = await dispatch(createEditHowToContentAction(params)).unwrap()
      if (res?.commonContent) {
        message.success({
          content: res?.message ?? t('common:save_success'),
        })
      }
    } catch (error: any) {
      message.error(error?.message)
    } finally {
      onClose()
    }
  }

  useDeepCompareEffect(() => {
    getHowToContent()
  }, [dispatch, howToContent])

  return (
    <AppModal
      open={open}
      onClose={onClose}
      haveCloseIcon
      title={
        <Typography.Title level={3} className="!m-0 !text-[32px]">
          {t('teacher:how_to_content')}
        </Typography.Title>
      }
      contentClassName="!min-w-[800px]"
    >
      {!!isGettingHowToContent ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <HowToContentWrapper>
          <Controller
            name="title"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Title"
                  value={value}
                  onChange={onChange}
                  required
                  label="Title"
                  alignment="col"
                  errors={error?.message}
                />
              )
            }}
          />
          <Controller
            name="titleInDutch"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Title in Netherlands"
                  value={value}
                  onChange={onChange}
                  required
                  label="Title in Netherlands"
                  alignment="col"
                  errors={error?.message}
                />
              )
            }}
          />
          <Controller
            name="subTitle"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Sub-title"
                  value={value}
                  onChange={onChange}
                  required
                  label="Sub-title"
                  alignment="col"
                  errors={error?.message}
                />
              )
            }}
          />
          <Controller
            name="subTitleInDutch"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="Sub-title in Netherlands"
                  value={value}
                  onChange={onChange}
                  required
                  label="Sub-title in Netherlands"
                  alignment="col"
                  errors={error?.message}
                />
              )
            }}
          />

          <Controller
            name="button"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="CTA button name"
                  value={value}
                  onChange={onChange}
                  required
                  label="CTA button name"
                  alignment="col"
                  errors={error?.message}
                />
              )
            }}
          />
          <Controller
            name="buttonInDutch"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <Input
                  placeholder="CTA button name Netherlands"
                  value={value}
                  onChange={onChange}
                  required
                  label="CTA button name Netherlands"
                  alignment="col"
                  errors={error?.message}
                />
              )
            }}
          />

          <ButtonWrapper className="w-full col-span-2 !justify-center !items-center">
            <Button
              size="small"
              className="!text-[16px] !text-white !bg-gray-400 !border-none"
              onClick={onClose}
            >
              {t('common:cancel')}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              className="!text-[16px] !text-white !border-none"
              onClick={handleSubmit(handleSubmitHowToContent)}
              loading={isSubmitting}
            >
              {t('common:save')}
            </Button>
          </ButtonWrapper>
        </HowToContentWrapper>
      )}
    </AppModal>
  )
})

const HowToContentWrapper = styled.form`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
`
