import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'

import { IEditContent } from '@interfaces'
import {
  TEACHER_EDIT_FAQ_CONTENT_DEFAULT_VALUES,
  teacherEditFaqContentSchema,
} from '@pages'
import { Button, Input } from 'src/common'
import { AppModal } from 'src/components/AppModal'
import { XCloseIcon } from 'src/components/Icon'
import { getTeacherFAQContentAction, useAppDispatch } from '@redux'
import { useEffect } from 'react'

type Props = {
  open: boolean
  isLoading?: boolean
  onClose: () => void
  onSave: (data: IEditContent) => void
}

export const EditContentModal = ({
  open,
  onClose,
  onSave,
  isLoading,
}: Props) => {
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<IEditContent>({
    defaultValues: TEACHER_EDIT_FAQ_CONTENT_DEFAULT_VALUES,
    resolver: zodResolver(teacherEditFaqContentSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(getTeacherFAQContentAction()).unwrap()
      if (response) {
        setValue('title', response.title)
        setValue('titleInDutch', response.titleInDutch)
        setValue('subTitle', response.subTitle)
        setValue('subTitleInDutch', response.subTitleInDutch)
      }
    }
    fetchData()
  }, [])

  return (
    <AppModal open={open} onClose={onClose}>
      <div className="flex items-center justify-between ">
        <div>
          <h1 className="m-0 text-[20px]">FAQ content</h1>
        </div>
        <div className="hover:opacity-75 cursor-pointer">
          <XCloseIcon width={16} height={16} onClick={onClose} />
        </div>
      </div>
      <div className="mt-6 w-[800px]">
        <form>
          <div className="blog-content-main intro-y mt-5">
            <div className="grid grid-cols-12 gap-4">
              <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
                <div className="col-span-6">
                  <Controller
                    name="title"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <Input
                          name="title"
                          placeholder="Title"
                          value={value}
                          onChange={(event) => {
                            onChange(event?.target?.value)
                          }}
                          required
                          label="Title"
                          alignment="col"
                          errors={error?.message}
                          className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                        />
                      )
                    }}
                  />
                </div>

                <div className="col-span-6">
                  <Controller
                    name="titleInDutch"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <Input
                          placeholder="Title in Netherlands"
                          value={value}
                          onChange={onChange}
                          required
                          label="Title in Netherlands"
                          alignment="col"
                          errors={error?.message}
                          className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                        />
                      )
                    }}
                  />
                </div>
              </div>

              <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
                <div className="col-span-6">
                  <Controller
                    name="subTitle"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <Input
                          name="subTitle"
                          placeholder="Sub title"
                          value={value}
                          onChange={(event) => {
                            onChange(event?.target?.value)
                          }}
                          required
                          label="Sub title"
                          alignment="col"
                          errors={error?.message}
                          className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                        />
                      )
                    }}
                  />
                </div>

                <div className="col-span-6">
                  <Controller
                    name="subTitleInDutch"
                    control={control}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => {
                      return (
                        <Input
                          placeholder="Sub title in Netherlands"
                          value={value}
                          onChange={onChange}
                          required
                          label="Sub title in Netherlands"
                          alignment="col"
                          errors={error?.message}
                          className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                        />
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <div className="flex items-center mt-5 justify-end">
              <Button
                type="ghost"
                size="middle"
                className="submit__btn login-btn mr-10"
                onClick={onClose}
              >
                {'Cancel'}
              </Button>
              <Button
                htmlType="button"
                type="primary"
                size="middle"
                className="submit__btn login-btn"
                loading={isLoading}
                onClick={handleSubmit(onSave)}
                disabled={!isDirty}
              >
                {'Save'}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </AppModal>
  )
}
