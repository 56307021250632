import * as z from 'zod'
import { t } from 'i18next'

import {
  ECourseDifficulty,
  ECourseType,
  ENationalLanguage,
  ERequest,
  MIN_PRICE,
} from '@configs'

export enum ECourseMiniDashboardKey {
  TOTAL_COURSE = 'TOTAL_COURSE',
  TOTAL_PUBLISHED_ACTIVE_COURSE = 'TOTAL_PUBLISHED_ACTIVE_COURSE',
  TOTAL_INACTIVE_PUBLISHED_COURSE = 'TOTAL_INACTIVE_PUBLISHED_COURSE',
  TOTAL_INACTIVE_PUBLISHED_REQUEST = 'TOTAL_INACTIVE_PUBLISHED_REQUEST',
}

const validateHtmlContentLength = (value: string, minLength: number) => {
  if (!value) return false

  const parser = new DOMParser()
  const doc = parser.parseFromString(value, 'text/html')
  const parsedText = doc.body.textContent || ''

  return parsedText.length >= minLength
}

export const courseSchema = z
  .object({
    title: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(150, {
        message: t('error:blog_title_too_long') as string,
      }),
    titleInDutch: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(150, {
        message: t('error:blog_title_too_long') as string,
      }),
    userId: z.number({
      required_error: t('error:field_required') as string,
    }),
    isActived: z.boolean({
      required_error: t('error:field_required') as string,
    }),
    isDrafted: z.boolean({
      required_error: t('error:field_required') as string,
    }),
    slug: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:slug_length_error') as string,
      }),
    description: z
      .string()
      .nonempty({ message: t('error:field_required') as string })
      .refine((value) => validateHtmlContentLength(value, 50), {
        message: t('error:min_length_50') as string,
      }),
    descriptionInDutch: z
      .string()
      .nonempty({ message: t('error:field_required') as string })
      .refine((value) => validateHtmlContentLength(value, 50), {
        message: t('error:min_length_50') as string,
      }),
    type: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    eurPrice: z.number({
      required_error: t('error:field_required') as string,
    }),
    usdPrice: z.number({
      required_error: t('error:field_required') as string,
    }),
    difficulty: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    thumbnailId: z.number().min(1, {
      message: t('error:field_required') as string,
    }),
    demoVideoId: z.number().min(1, {
      message: t('error:field_required') as string,
    }),
    categoriesIds: z.array(z.number()).nonempty({
      message: t('error:field_required') as string,
    }),
    subCategoriesIds: z.array(z.number()).optional(),
    nationalLanguage: z.string().nonempty({
      message: t('error:field_required') as string,
    }),
    subTitle: z.string().nullable().optional(),
    hasFinalTest: z.boolean().optional(),
    targetUser: z.boolean().optional(),
    isBestSeller: z.boolean().optional(),
    packageIds: z.array(z.number()),
    userCreationFrom: z.string().optional().nullable(),
    userCreationTo: z.string().optional().nullable(),
  })
  .refine(
    (schema) => {
      return !(schema.targetUser && schema.packageIds.length <= 0)
    },
    {
      path: ['packageIds'],
      message: t('error:field_required') as string,
    }
  )
  .transform((data) => ({
    ...data,
    userCreationFrom: data.userCreationFrom
      ? new Date(data.userCreationFrom)
      : undefined,
    userCreationTo: data.userCreationTo
      ? new Date(data.userCreationTo)
      : undefined,
  }))
  .refine(
    (data) => {
      if (data.userCreationFrom && data.userCreationTo) {
        return data.userCreationFrom < data.userCreationTo
      }
      return true
    },
    {
      path: ['userCreationTo'],
      message: t('error:to_date_greater') as string,
    }
  )
  .refine(
    (data) => {
      if (
        [ECourseType.PREMIUM, ECourseType.MEMBER_COURSE].includes(
          data.type as ECourseType
        )
      ) {
        return data.eurPrice > 0
      }
      return true
    },
    {
      path: ['eurPrice'],
      message: t('error:min_price_0.5') as string,
    }
  )
  .refine(
    (data) => {
      if (
        [ECourseType.PREMIUM, ECourseType.MEMBER_COURSE].includes(
          data.type as ECourseType
        )
      ) {
        return data.usdPrice > 0
      }
      return true
    },
    {
      path: ['usdPrice'],
      message: t('error:min_price_0.5') as string,
    }
  )

export const RESPONSE_STATUS_CONTENT: Record<
  ERequest,
  { color: string; responseTitle: string }
> = {
  [ERequest.INACTIVE]: {
    color: '#DE3B40FF',
    responseTitle: 'Status: Request Inactive',
  },
  [ERequest.ACTIVE]: {
    color: '#FE763EFF',
    responseTitle: 'Status: Request Active',
  },
  [ERequest.PUBLISH]: {
    color: '#1DD75BFF',
    responseTitle: '',
  },
}

export const COURSE_TYPE_OPTIONS = [
  {
    value: ECourseType.FREE,
    label: t('courseManagement:free'),
  },
  {
    value: ECourseType.PREMIUM,
    label: t('courseManagement:premium'),
  },
  {
    value: ECourseType.MEMBER_COURSE,
    label: t('courseManagement:member_course'),
  },
]

export const FORM_FIELDS = {
  TITLE: 'title',
  TITLE_IN_DUTCH: 'titleInDutch',
  DESCRIPTION: 'description',
  DESCRIPTION_IN_DUTCH: 'descriptionInDutch',
  SLUG: 'slug',
  USER_ID: 'userId',
  TYPE: 'type',
  EURO_PRICE: 'eurPrice',
  USD_PRICE: 'usdPrice',
  IS_ACTIVED: 'isActived',
  IS_DRAFTED: 'isDrafted',
  DIFFICULTY: 'difficulty',
  CATEGORY_IDS: 'categoriesIds',
  SUB_CATEGORY_IDS: 'subCategoriesIds',
  THUMBNAIL_ID: 'thumbnailId',
  DEMO_VIDEO_ID: 'demoVideoId',
  NATIONAL_LANGUAGE: 'nationalLanguage',
  SUB_TITLE: 'subTitle',
  HAS_FINAL_TEST: 'hasFinalTest',
  TARGET_USER: 'targetUser',
  IS_BEST_SELLER: 'isBestSeller',
  PACKAGE_IDS: 'packageIds',
  USER_CREATION_FROM: 'userCreationFrom',
  USER_CREATION_TO: 'userCreationTo',
  AUTHOR_NAME: 'authorName',
}

export const DEFAULT_FORM_FIELD_VALUES = {
  [FORM_FIELDS.TITLE]: '',
  [FORM_FIELDS.TITLE_IN_DUTCH]: '',
  [FORM_FIELDS.DESCRIPTION]: '',
  [FORM_FIELDS.DESCRIPTION_IN_DUTCH]: '',
  [FORM_FIELDS.SLUG]: '',
  [FORM_FIELDS.USER_ID]: undefined,
  [FORM_FIELDS.TYPE]: ECourseType.FREE,
  [FORM_FIELDS.EURO_PRICE]: 0,
  [FORM_FIELDS.USD_PRICE]: 0,
  [FORM_FIELDS.IS_ACTIVED]: false,
  [FORM_FIELDS.IS_DRAFTED]: false,
  [FORM_FIELDS.DIFFICULTY]: ECourseDifficulty.BEGINNER,
  [FORM_FIELDS.CATEGORY_IDS]: [],
  [FORM_FIELDS.SUB_CATEGORY_IDS]: [],
  [FORM_FIELDS.THUMBNAIL_ID]: 0,
  [FORM_FIELDS.DEMO_VIDEO_ID]: 0,
  [FORM_FIELDS.NATIONAL_LANGUAGE]: ENationalLanguage.ENGLISH,
  [FORM_FIELDS.SUB_TITLE]: null,
  [FORM_FIELDS.HAS_FINAL_TEST]: false,
  [FORM_FIELDS.TARGET_USER]: false,
  [FORM_FIELDS.IS_BEST_SELLER]: false,
  [FORM_FIELDS.PACKAGE_IDS]: [],
  [FORM_FIELDS.USER_CREATION_FROM]: '',
  [FORM_FIELDS.USER_CREATION_TO]: '',
  [FORM_FIELDS.AUTHOR_NAME]: '',
}
