import moment from "moment";

// eslint-disable-next-line @typescript-eslint/ban-types
export const LogApp = (key: any, value?: number | string | object, ...optionalParams: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    // value ? console.log(key, value, ...optionalParams) : console.log(key);
    return;
  }
};

export enum EConvertStartDateType {
  START = 'start',
  END = 'end',
}

export const convertStartEndDate = (
  time: string,
  type: EConvertStartDateType
) => {
  if (!time || !type) return

  let convertedTimeInMoment: moment.Moment
  if (type === EConvertStartDateType.START) {
    convertedTimeInMoment = moment(time).startOf('day')
  } else {
    convertedTimeInMoment = moment(time).endOf('day')
  }

  return convertedTimeInMoment.toISOString()
}

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
  }).format(value)
}

export const formatTextWithMaxLength = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`
  }
  return text
}
