import { Card, Skeleton } from 'antd'
import { Button, SharedTable } from 'src/common'
import ConfirmDeleteModal from '../../staff-and-role-management/ConfirmDeleteModal'
import MotiveTabDetailModal from './MotiveTabDetailModal'
import { useMotiveViewModel } from './MotiveTabViewModel'

type Props = { activeTab: string }

const MotiveTab = ({ activeTab }: Props) => {
  const {
    columns,
    onDelete,
    no,
    apiLoading,
    motive,
    onHide,
    onOpen,
    isModalOpen,
    onSubmit,
    showDeleteModal,
    onCloseConfirmModal,
    isModalLoading,
    setIsModalLoading,
    selectedMotive,
  } = useMotiveViewModel(activeTab)

  return (
    <Card>
      <div className="flex">
        <div className="flex-1" />
        <Button
          htmlType="submit"
          type="primary"
          size="middle"
          className="submit__btn loginselected-btn"
          onClick={onOpen}
          style={{
            alignSelf: 'flex-end',
          }}
        >
          {'Add new'}
        </Button>
      </div>
      {apiLoading ? (
        <Skeleton
          paragraph={{ rows: 4 }}
          style={{
            marginTop: 10,
          }}
        />
      ) : (
        <SharedTable
          columns={columns as any}
          dataSource={motive?.map((item) => {
            return {
              ...item,
              key: item?.id,
              no: item?.no,
              title: item?.title ?? `--`,
              content: item?.content ?? `--`,
              action: item,
            }
          })}
          sticky
          hasIndexColumn={false}
        />
      )}
      {isModalOpen && (
        <MotiveTabDetailModal
          open={isModalOpen}
          onClose={onHide}
          isLoading={isModalLoading}
          changeLoading={setIsModalLoading}
          onSave={onSubmit as any}
          item={selectedMotive as any}
          noDefault={no + 1 || 1}
        />
      )}
      {showDeleteModal && (
        <ConfirmDeleteModal
          title="Delete motive"
          content="Are you sure to delete this motive?"
          open={showDeleteModal}
          isLoading={!!isModalLoading}
          onDelete={onDelete}
          onClose={onCloseConfirmModal}
        />
      )}
    </Card>
  )
}

export default MotiveTab
