import common from './common.json'
import login from './login.json'
import dashboard from './dashboard.json'
import error from './error.json'
import plan from './plan.json'
import user from './user.json'
import emailContent from './email-content.json'
import habit from './habit.json'
import section from './section.json'
import finalTest from './final-test.json'
import testManagement from './test-management.json'
import teacher from './teacher.json'
import courseManagement from './course-management.json'
import auth from './auth.json'
import staffAndRole from './staff-and-role.json'
import teacherRequest from './teacher-request-management.json'
import courseRequest from './course-request-management.json'
import notification from './notification.json'

export const en = {
  common,
  login,
  dashboard,
  error,
  plan,
  user,
  emailContent,
  habit,
  section,
  finalTest,
  testManagement,
  teacher,
  courseManagement,
  auth,
  staffAndRole,
  teacherRequest,
  courseRequest,
  notification,
}
