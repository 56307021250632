import * as z from 'zod'
import { t } from 'i18next'

import { CONTENT_MANAGEMENT_VALIDATION } from '@configs'

export const teacherPricingSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  subTitle: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_SUBTITLE, {
      message: t('error:max_length_100') as string,
    }),
  subTitleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_SUBTITLE, {
      message: t('error:max_length_100') as string,
    }),
  description: z
    .string()
    .trim()
    .min(CONTENT_MANAGEMENT_VALIDATION.MIN_LENGTH_DESCRIPTION, {
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_DESCRIPTION, {
      message: t('error:max_length_500') as string,
    }),
  descriptionInDutch: z
    .string()
    .trim()
    .min(CONTENT_MANAGEMENT_VALIDATION.MIN_LENGTH_DESCRIPTION, {
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_DESCRIPTION, {
      message: t('error:max_length_500') as string,
    }),
})

export const teacherBecomeInstructorSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  subTitle: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_SUBTITLE, {
      message: t('error:max_length_100') as string,
    }),
  subTitleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_SUBTITLE, {
      message: t('error:max_length_100') as string,
    }),
  ctaButtonName: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_CTA_BUTTON_NAME, {
      message: t('error:max_length_100') as string,
    }),
  ctaButtonNameInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_CTA_BUTTON_NAME, {
      message: t('error:max_length_100') as string,
    }),
  mediaId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
  mediaMobileId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
})

export const TeacherSEOConfigureSchema = z.object({
  metaTitle: z
    .string()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  keyWords: z
    .string()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  metaDescription: z
    .string()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_DESCRIPTION, {
      message: t('error:max_length_500') as string,
    }),
  canonical: z
    .string()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  metaImage: z.any(),
})

export const teacherAddFaqSchema = z.object({
  question: z
    .string()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  questionInDutch: z
    .string()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  answer: z
    .string()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_DESCRIPTION, {
      message: t('error:max_length_500') as string,
    }),
  answerInDutch: z
    .string()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_DESCRIPTION, {
      message: t('error:max_length_500') as string,
    }),
})

export const teacherEditFaqContentSchema = z.object({
  title: z
    .string()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  titleInDutch: z
    .string()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  subTitle: z
    .string()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_SUBTITLE, {
      message: t('error:max_length_100') as string,
    }),
  subTitleInDutch: z
    .string()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_SUBTITLE, {
      message: t('error:max_length_100') as string,
    }),
})

export const TEACHER_PRICING_DEFAULT_VALUES = {
  title: '',
  titleInDutch: '',
  subTitle: '',
  subTitleInDutch: '',
  description: '',
  descriptionInDutch: '',
}

export const TEACHER_BECOME_INSTRUCTOR_DEFAULT_VALUES = {
  title: '',
  titleInDutch: '',
  subTitle: '',
  subTitleInDutch: '',
  ctaButtonName: '',
  ctaButtonNameInDutch: '',
}

export const TEACHER_SEO_CONFIGURE_DEFAULT_VALUES = {
  metaTitle: '',
  keyWords: '',
  metaDescription: '',
  canonical: '',
  metaImage: '',
}

export const TEACHER_ADD_FAQ_DEFAULT_VALUES = {
  question: '',
  questionInDutch: '',
  answer: '',
  answerInDutch: '',
}

export const TEACHER_EDIT_FAQ_CONTENT_DEFAULT_VALUES = {
  title: '',
  titleInDutch: '',
  subTitle: '',
  subTitleInDutch: '',
}
