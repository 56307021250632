import { CONTENT_MANAGEMENT_VALIDATION, ECommonContentLayout } from '@configs'
import { t } from 'i18next'
import * as z from 'zod'

export const DEFAULT_TEACHER_BANNER_VALUES = {
  title: '',
  titleInDutch: '',
  mediaDescription: '',
  mediaDescriptionInDutch: '',
  mediaId: 0,
  mediaMobileId: 0,
  textColor: '#ffffff',
  layout: ECommonContentLayout.LAYOUT_ONE,
}

export const teacherBannerSchema = z.object({
  title: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  titleInDutch: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  mediaDescription: z
    .string()
    .trim()
    .min(CONTENT_MANAGEMENT_VALIDATION.MIN_LENGTH_DESCRIPTION, {
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_DESCRIPTION, {
      message: t('error:max_length_500') as string,
    }),
  mediaDescriptionInDutch: z
    .string()
    .trim()
    .min(CONTENT_MANAGEMENT_VALIDATION.MIN_LENGTH_DESCRIPTION, {
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_DESCRIPTION, {
      message: t('error:max_length_500') as string,
    }),
  mediaId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
  mediaMobileId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
  layout: z.string(),
  textColor: z
    .string()
    .trim()
    .nonempty({
      message: t('error:field_required') as string,
    })
    .max(10, {
      message: t('error:text_color_length_error') as string,
    }),
})
