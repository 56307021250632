import React from 'react'

import { HowToTable } from './how-to/HowToTable'

type TeacherHowToProps = {
  activeTab: string
}

export const TeacherHowTo = (props: TeacherHowToProps) => {
  return (
    <div>
      <HowToTable {...props} />
    </div>
  )
}
