import { themes } from '@theme'
import { formatCurrency } from '@utils'
import {
  ArcElement,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  Tooltip,
} from 'chart.js'
import { useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

type PieChartComponentProps = {
  data: any
  centerData: any
  isLoading?: boolean
  isPriceChart?: boolean
}

export const PieChartComponent = (props: PieChartComponentProps) => {
  const { data, isPriceChart = false } = props

  const doughnutChartConfig: ChartOptions<'doughnut'> = useMemo(() => {
    return {
      responsive: true,
      cutout: '75%',
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: themes.theme.light.colors.body,
          borderColor: themes.theme.light.colors.primary,
          titleColor(ctx, options) {
            return '#000'
          },
          bodyColor(ctx, options) {
            return '#000'
          },
          borderWidth: 1,
          callbacks: {
            label: function (context) {
              return isPriceChart
                ? formatCurrency(context.dataset.data[context.dataIndex])
                : context.dataset.data[context.dataIndex].toString()
            },
          },
        },
      },
    }
  }, [isPriceChart])

  return <Doughnut redraw data={data} options={doughnutChartConfig} />
}
