import { Tabs } from 'antd'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { Text } from 'src/common'
import {
  notificationActions,
  RootState,
  selectNotificationLoading,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { ENotificationTab } from './configs'
import { NotificationList } from 'src/pages/notification-screen/notification-list/NotificationList'

interface Props {}

export const NotificationScreen = ({}: Props) => {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const notification = useAppSelector((state) => state.notification)
  const getListNotificationLoading = useSelector((state: RootState) =>
    selectNotificationLoading(state, 'getListNotificationsAction')
  )

  const [size, setSize] = useState<SizeType>('small')
  const [activeTab, setActiveTab] = useState<ENotificationTab | string>(
    searchParams.get('tab') || ENotificationTab.NOTIFICATION_ALL
  )

  useEffect(() => {
    return () => {
      dispatch(notificationActions.clearListNotifications())
    }
  }, [])

  const items = [
    {
      label: <Text>All</Text>,
      key: ENotificationTab.NOTIFICATION_ALL,
      children: (
        <NotificationList activeTab={ENotificationTab.NOTIFICATION_ALL} />
      ),
      disabled: !!getListNotificationLoading,
    },

    {
      label: (
        <TabWrapper>
          <Text>Unread</Text>
          {notification.unreadNotifications > 0 ? (
            <div className="noti-indicator">
              {notification.unreadNotifications <= 99
                ? notification.unreadNotifications
                : '99+'}
            </div>
          ) : null}
        </TabWrapper>
      ),
      key: ENotificationTab.NOTIFICATION_UNREAD,
      children: (
        <NotificationList activeTab={ENotificationTab.NOTIFICATION_UNREAD} />
      ),
      disabled: !!getListNotificationLoading,
    },
  ]

  return (
    <HomePageStyled>
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey={ENotificationTab.NOTIFICATION_ALL}
        type="card"
        size={size}
        items={items}
        defaultChecked={true}
        activeKey={activeTab}
        onChange={(e) => {
          setActiveTab(e)
          dispatch(notificationActions.setCurrentPage(1))
        }}
      />
    </HomePageStyled>
  )
}

const HomePageStyled = styled('div')(
  ({ isDisabled }: { isDisabled?: boolean }) => {
    return {
      '.ant-tabs-nav': {
        marginBottom: 0,
        paddingLeft: '1.25rem',

        '& .ant-tabs-tab': {
          borderRadius: '0.75rem 0.75rem 0 0 !important',

          '&.ant-tabs-tab-active': {
            span: {
              color: '#009BB7',
              fontWeight: '500',
            },
          },

          ...(isDisabled && {
            '&.ant-tabs-tab-disabled': {
              '& .ant-tabs-tab-btn': {
                '& .ant-typography': {
                  color: '#b8b8b8',
                },
              },
            },
          }),
        },
      },

      '.ant-tabs-content-holder': {
        backgroundColor: '#ffffff',
        padding: '34px 24px',
        borderRadius: '0.25rem',
        minHeight: '82dvh',
      },
    }
  }
)

const TabWrapper = styled.div`
  .noti-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: -4px;
    min-width: 16px;
    max-height: 16px;
    width: max-content;
    height: max-content;
    border-radius: 999px;
    padding: 0 4px;
    background: red;
    color: white;
    font-size: 10px;
  }
`
