import { INITIAL_PAGINATION_SiZE } from '@configs'
import {
  ICreateEditTeacherPackage,
  IGetTeacherPackageParams,
} from '@interfaces'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { message } from 'antd'

import { TeacherPackageManagementApi } from 'src/api/teacher-package-management'

export const getAllTeacherPackagesAction = createAsyncThunk(
  'teacherPackages/getAllTeacherPackagesAction',
  async (params: IGetTeacherPackageParams) => {
    try {
      const localParams = params
        ? params
        : {
            page: 1,
            limit: INITIAL_PAGINATION_SiZE,
          }
      const res = await TeacherPackageManagementApi.getListTeacherPackages(
        localParams
      )
      return res.data.teacherPackages
    } catch (error) {
      throw error
    }
  }
)

export const getTeacherPackageDetailsByIdAction = createAsyncThunk(
  'teacherPackages/getTeacherPackageDetailsByIdAction',
  async (id: number) => {
    try {
      const res = await TeacherPackageManagementApi.getTeacherPackageById(id)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const createTeacherPackageAction = createAsyncThunk(
  'teacherPackages/createTeacherPackageAction',
  async (data: Partial<ICreateEditTeacherPackage>) => {
    try {
      const res = await TeacherPackageManagementApi.createTeacherPackage(data)
      return res.data
    } catch (error: any) {
      message.error({
        content:
          error.errors?.length && error.errors !== error.message
            ? error.errors[0]
            : error.message,
      })
      throw error
    }
  }
)

export const updateTeacherPackageByIdAction = createAsyncThunk(
  'teacherPackages/updateTeacherPackageByIdAction',
  async (data: { id: number; payload: Partial<ICreateEditTeacherPackage> }) => {
    try {
      const res = await TeacherPackageManagementApi.updateTeacherPackageById(
        data.id,
        data.payload
      )
      return res.data
    } catch (error: any) {
      message.error({
        content:
          error.errors?.length && error.errors
            ? error.errors[0]
            : error.message,
      })
      throw error
    }
  }
)

export const deleteTeacherPackageByIdAction = createAsyncThunk(
  'teacherPackages/deleteTeacherPackageByIdAction',
  async (data: { id: number; replacePackageId: number }) => {
    try {
      const res = await TeacherPackageManagementApi.deleteTeacherPackageById(
        data.id,
        data.replacePackageId
      )
      return res || {}
    } catch (error) {
      throw error
    }
  }
)
