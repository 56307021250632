import React, { useMemo } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { themes } from '@theme'
import { formatCurrency } from '@utils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

type MultiLineChartComponentProps = {
  data: any
  isPriceChart?: boolean
}

export const MultiLineChartComponent = (
  props: MultiLineChartComponentProps
) => {
  const { data, isPriceChart } = props

  const options: ChartOptions<'line'> = useMemo(() => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          align: 'center',
          fullSize: true,
          maxHeight: 150,
          labels: {
            boxWidth: 13,
            boxHeight: 13,
            borderRadius: 999,
            usePointStyle: true,
            pointStyle: 'circle',
          },
          onHover: function (e: any) {
            e.native.target.style.cursor = 'pointer'
          },
        },
        tooltip: {
          backgroundColor: themes.theme.light.colors.body,
          borderColor: themes.theme.light.colors.primary,
          borderWidth: 1,
          titleColor: themes.theme.light.colors.text,
          bodyColor: themes.theme.light.colors.text,
          callbacks: {
            label: function (context: any) {
              return isPriceChart
                ? formatCurrency(context.dataset.data[context.dataIndex])
                : context.dataset.data[context.dataIndex].toString()
            },
          },
        },
      },
    }
  }, [isPriceChart])

  return <Line options={options} data={data} />
}
