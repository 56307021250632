import styled from 'styled-components'
import { Spin, SpinProps } from 'antd'

export const StyledSpin = styled((props: SpinProps) => {
  return <Spin {...props} />
})<{}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;

  animation-name: FadeIn;
  animation-duration: 0.5s;
  transition-timing-function: linear;

  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`
