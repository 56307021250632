import { zodResolver } from '@hookform/resolvers/zod'
import { Skeleton, Switch, Typography } from 'antd'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import queryString from 'query-string'

import { ShareSelectInput } from '@components'
import { CONTENT_MANAGEMENT_VALIDATION } from '@configs'
import {
  DEFAULT_CREATE_POPUP_VALUES,
  LIST_POPUP_TYPE,
  PopupSchema,
  TPopupType,
} from '@pages'
import { useAppDispatch } from '@redux'
import { Button, Input, TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'

type CreatePopupStepOneProps = {
  listPopupCourses: {
    value: number
    label: string
  }[]
  popupDetail: TPopupType | null
  setPopupDetail: (data: TPopupType) => void
  handleNextStep: () => void
  popupId: string | undefined
  handleSavePopup: (body: TPopupType) => void
  isLoading?: boolean
}

export const CreatePopupStepOne = (props: CreatePopupStepOneProps) => {
  const {
    listPopupCourses,
    popupDetail,
    setPopupDetail,
    handleNextStep,
    popupId,
    handleSavePopup,
    isLoading,
  } = props

  const dispatch = useAppDispatch()

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    setError,
    watch,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: DEFAULT_CREATE_POPUP_VALUES,
    resolver: zodResolver(PopupSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (popupDetail) {
      setValue('name', popupDetail.name ?? '')
      setValue('nameInDutch', popupDetail.nameInDutch ?? '')
      setValue('title', popupDetail.title ?? '')
      setValue('titleInDutch', popupDetail.titleInDutch ?? '')
      setValue('description', popupDetail.description ?? '')
      setValue('descriptionInDutch', popupDetail.descriptionInDutch ?? '')
      setValue('ctaButtonName', popupDetail.ctaButtonName ?? '')
      setValue('ctaButtonNameInDutch', popupDetail.ctaButtonNameInDutch ?? '')
      setValue('type', `${popupDetail.type ?? ''}`)
      setValue('courseId', popupDetail.courseId ?? 0)
      setValue('photoId', popupDetail.photoId ?? 0)
      setValue('mobilePhotoId', popupDetail.mobilePhotoId ?? 0)
      setPhotoSrc(popupDetail.photoUrl || '')
      setPhotoMobileSrc(popupDetail.mobilePhotoUrl || '')
    }
  }, [popupDetail])

  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)

  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [photoSrc, setPhotoSrc] = useState('')
  const [mobilePhotoSrc, setPhotoMobileSrc] = useState('')

  const handleConfirmSubmit = async (data: TPopupType) => {
    const mergeData = {
      ...popupDetail,
      name: data.name,
      nameInDutch: data.nameInDutch,
      title: data.title,
      titleInDutch: data.titleInDutch,
      description: data.description,
      descriptionInDutch: data.descriptionInDutch,
      ctaButtonName: data.ctaButtonName,
      ctaButtonNameInDutch: data.ctaButtonNameInDutch,
      type: data.type,
      courseId: data.courseId,
      photoId: data.photoId,
      mobilePhotoId: data.mobilePhotoId,
      photoUrl: photoSrc,
      mobilePhotoUrl: mobilePhotoSrc,
    }

    setPopupDetail(mergeData)
    popupId ? handleSavePopup(mergeData) : handleNextStep()
  }

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setValue('photoId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
      setPhotoSrc(data?.baseUrl ?? '')
    } else if (selectedField === 'mobile') {
      setValue('mobilePhotoId', data?.id ?? 0)
      onChangeMobileRef.current(data?.id ?? 0)
      setPhotoMobileSrc(data?.baseUrl ?? '')
    }
    setShowModalSelectMedia(false)
  }

  const onClickPreview = handleSubmit((value) => {
    const payload = {
      isPopup: true,
      name: value?.name,
      title: value?.title,
      url: photoSrc,
      urlMobile: mobilePhotoSrc,
      description: value?.description,
      button: value?.ctaButtonName,
      type: value?.type,
    }
    const stringified = queryString.stringify(payload)
    const URL = `${process.env.REACT_APP_PUBLIC_WEB_DOMAIN}/preview-content?${stringified}`
    window.open(URL, '_blank')
  })

  return (
    <div className="blog-content-m-right col-span-12 lg:col-span-4 rounded-lg">
      <div>
        {isLoading ? (
          <Skeleton paragraph={{ rows: 20 }} />
        ) : (
          <div>
            <div className="intro-y box p-5 bg-white rounded-lg">
              {/* Header */}

              {popupId && (
                <div className="border rounded pl-2">
                  <div className="blog-content-top intro-y mt-2 flex flex-col items-center sm:flex-row">
                    <h2 className="blog-content-top mr-auto text-lg font-medium flex items-center justify-between">
                      {t('common:state')}: {popupDetail?.state}
                    </h2>
                  </div>

                  <div className="blog-content-top intro-y mt-2 flex flex-col items-center sm:flex-row">
                    <h2 className="blog-content-top mr-auto text-lg font-medium flex items-center justify-between">
                      {t('common:status')}:{' '}
                      <span
                        style={{
                          color: popupDetail?.isActive ? 'green' : 'red',
                          paddingLeft: '5px',
                          paddingRight: '5px',
                        }}
                      >
                        {popupDetail?.isActive ? 'Active' : 'Inactive'}
                      </span>
                      <Switch
                        checked={popupDetail?.isActive}
                        onChange={(status) => {
                          setPopupDetail({
                            ...popupDetail,
                            isActive: status,
                          })
                        }}
                      />
                    </h2>
                  </div>
                </div>
              )}

              <div className="blog-content-top intro-y mt-2 flex flex-col items-center sm:flex-row">
                <h2 className="blog-content-top mr-auto text-lg font-medium flex items-center justify-between">
                  Popup content
                </h2>
              </div>

              {/* Content */}

              <div className="blog-content-main intro-y mt-5">
                <div className="grid grid-cols-12 gap-4">
                  <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
                    <div className="col-span-6">
                      <Controller
                        name="title"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              placeholder="Title"
                              value={value}
                              onChange={(event) => {
                                onChange(event?.target?.value)
                              }}
                              required
                              label="Popup Title"
                              alignment="col"
                              errors={error?.message}
                              className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                            />
                          )
                        }}
                      />
                    </div>

                    <div className="col-span-6">
                      <Controller
                        name="titleInDutch"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              placeholder="Popup Title in Netherlands"
                              value={value}
                              onChange={onChange}
                              required
                              label="Popup Title in Netherlands"
                              alignment="col"
                              errors={error?.message}
                              className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                            />
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
                    <div className="col-span-6">
                      <Controller
                        name="name"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              name="name"
                              placeholder="Popup Name"
                              value={value}
                              onChange={(event) => {
                                onChange(event?.target?.value)
                              }}
                              required
                              label="Popup Name"
                              alignment="col"
                              errors={error?.message}
                              className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                            />
                          )
                        }}
                      />
                    </div>

                    <div className="col-span-6">
                      <Controller
                        name="nameInDutch"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              placeholder="Popup Name in Netherlands"
                              value={value}
                              onChange={onChange}
                              required
                              label="Popup Name in Netherlands"
                              alignment="col"
                              errors={error?.message}
                              className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                            />
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
                    <ShareSelectInput
                      label={'Popup Type'}
                      required
                      data={LIST_POPUP_TYPE}
                      value={getValues('type')}
                      placeholder="Popup Type"
                      containerClassName="col-span-12"
                      style={{
                        height: '46px',
                        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
                      }}
                      onChange={(value) => {
                        setValue('type', value)
                        clearErrors('courseId')
                      }}
                      // errors={errors?.courseId?.message}
                    />
                  </div>

                  {getValues('type') === 'COURSE' && (
                    <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
                      <ShareSelectInput
                        label={'Course'}
                        required
                        options={listPopupCourses as any}
                        showSearch
                        value={
                          listPopupCourses
                            ?.map((item) => item?.value)
                            ?.includes(getValues('courseId'))
                            ? getValues('courseId')
                            : popupDetail?.course?.title
                        }
                        placeholder="Course"
                        containerClassName="col-span-12"
                        style={{
                          height: '46px',
                          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
                        }}
                        onChange={(value) => {
                          setValue('courseId', Number(value))
                          clearErrors('courseId')
                        }}
                        errors={errors?.courseId?.message}
                      />
                    </div>
                  )}

                  <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
                    <div className="col-span-6">
                      <Controller
                        name="description"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <TextArea
                              placeholder="Description"
                              value={value}
                              onChange={(event) => {
                                onChange(event?.target?.value)
                              }}
                              required
                              label="Description"
                              alignment="col"
                              errors={error?.message}
                              className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                            />
                          )
                        }}
                      />
                    </div>

                    <div className="col-span-6">
                      <Controller
                        name="descriptionInDutch"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <TextArea
                              placeholder="Description in Netherlands"
                              value={value}
                              onChange={onChange}
                              required
                              label="Description in Netherlands"
                              alignment="col"
                              errors={error?.message}
                              className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                            />
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-full grid grid-cols-12 blog-content-m-left intro-y col-span-12 gap-4">
                    <div className="w-full col-span-6 flex flex-col gap-1 items-end">
                      <Controller
                        name="photoId"
                        control={control}
                        render={({
                          field: { onChange },
                          fieldState: { error },
                        }) => {
                          onChangeDesktopRef.current = onChange
                          return (
                            <CustomDragger
                              label="Image"
                              name="photoId"
                              id="photoId"
                              containerClassName="w-full mt-3"
                              labelClassName="w-full"
                              alignment="col"
                              required
                              onLoadEnd={(data) => {
                                setValue('photoId', data?.id ?? 0)
                                onChange(data?.id ?? 0)
                                setPhotoSrc((prev) => data.original ?? prev)
                              }}
                              errors={error?.message}
                              initResource={photoSrc}
                              reset={getValues('photoId') === 0}
                              limitFileSize={
                                CONTENT_MANAGEMENT_VALIDATION.MAX_IMAGE_SIZE
                              }
                              allowFileTypes={[
                                'image/png',
                                'image/jpeg',
                                'image/jpg',
                              ]}
                              changeLoading={setIsModalLoading}
                              uploadType={EMediaType.IMAGE as any}
                              uploadCategory={EMediaCategory.CONTENT}
                            />
                          )
                        }}
                      />
                      <SelectMedia
                        isNotGrid
                        buttonClassName="!text-blue-500"
                        onClickSelectMedia={() => {
                          setSelectedField((pre) => 'desktop')
                          setShowModalSelectMedia(true)
                        }}
                      />
                    </div>

                    <div className="w-full col-span-6 flex flex-col gap-1 items-end mb-4">
                      <Controller
                        name={'mobilePhotoId'}
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          onChangeMobileRef.current = onChange
                          return (
                            <>
                              <CustomDragger
                                label="Image mobile"
                                containerClassName="w-full mt-[10px]"
                                labelClassName="w-full"
                                alignment="col"
                                onLoadEnd={(data) => {
                                  setValue('mobilePhotoId', data?.id ?? 0)
                                  onChange(data?.id ?? 0)
                                  setPhotoMobileSrc(
                                    (prev) => data.original ?? prev
                                  )
                                }}
                                name="mobilePhotoId"
                                id="mobilePhotoId"
                                required
                                errors={error?.message}
                                multiple={false}
                                initResource={mobilePhotoSrc}
                                reset={getValues('mobilePhotoId') === 0}
                                allowFileTypes={[
                                  'image/png',
                                  'image/jpeg',
                                  'image/jpg',
                                ]}
                                limitFileSize={
                                  CONTENT_MANAGEMENT_VALIDATION.MAX_IMAGE_SIZE
                                }
                                changeLoading={setIsModalLoading}
                                uploadType={EMediaType.IMAGE as any}
                                uploadCategory={EMediaCategory.CONTENT}
                              />
                            </>
                          )
                        }}
                      />
                      <SelectMedia
                        isNotGrid
                        buttonClassName="!text-blue-500"
                        onClickSelectMedia={() => {
                          setSelectedField((pre) => 'mobile')
                          setShowModalSelectMedia(true)
                        }}
                      />
                    </div>

                    <div className="col-span-6">
                      <Controller
                        name="ctaButtonName"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              placeholder="CTA Button Name"
                              value={value}
                              onChange={(event) => {
                                onChange(event?.target?.value)
                              }}
                              required
                              label="CTA Button Name"
                              alignment="col"
                              errors={error?.message}
                              className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                            />
                          )
                        }}
                      />
                    </div>

                    <div className="col-span-6">
                      <Controller
                        name="ctaButtonNameInDutch"
                        control={control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => {
                          return (
                            <Input
                              placeholder="CTA Button Name NTL"
                              value={value}
                              onChange={onChange}
                              required
                              label="CTA Button Name NTL"
                              alignment="col"
                              errors={error?.message}
                              className="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&amp;[readonly]]:bg-slate-100 [&amp;[readonly]]:cursor-not-allowed [&amp;[readonly]]:dark:bg-darkmode-800/50 [&amp;[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 intro-y !box intro-y !box px-4 py-3 pr-10 h-[46px]"
                            />
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="blog-content-top item-right-wrapper flex items-center justify-center gap-2 mt-5 pb-8">
              <Button
                type="primary"
                size="small"
                id="blog-form"
                onClick={onClickPreview}
                className=" !bg-amber-500 !border-none"
              >
                {t('common:preview')}
              </Button>

              <Button
                disabled={false}
                type="primary"
                size="small"
                id="blog-form"
                onClick={() => {
                  handleSubmit(handleConfirmSubmit)()
                }}
                loading={false}
              >
                {popupId ? t('common:save') : t('common:nextStep')}
              </Button>
            </div>

            {showModalSelectMedia ? (
              <>
                <SelectMediaModal
                  isOpen={showModalSelectMedia}
                  onClose={onCloseModalSelectMedia}
                  mediaType={EMediaType.IMAGE}
                  category={EMediaCategory.CONTENT}
                  onSelectMedia={onSelectMedia}
                />
              </>
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}
