import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { ETypeStatic, StatisticItem } from './StatisticItem'
import {
  getStatisticChartIncome,
  getStatisticChartUSer,
  getSubscriberDataAction,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { EStepChart } from '@configs'
import { getCurrentYear, getPreviousMonthDate } from '@utils'
import { dashboardAPI } from '@api'
import { handleAPIError } from 'src/utils/handleApiError'

export const Statistic = () => {
  const dispatch = useAppDispatch()

  const [data, setData] = useState({
    users: 0,
    orders: 0,
    subscribers: 0,
    newSubscribers: 0,
    income: '0',
    totalUser: 0,
  })

  const getData = async () => {
    try {
      const subscriberStatus = await dispatch(
        getSubscriberDataAction()
      ).unwrap()
      const userChart = await dispatch(
        getStatisticChartUSer(getCurrentYear())
      ).unwrap()
      const incomeChart = await dispatch(
        getStatisticChartIncome(getCurrentYear())
      ).unwrap()

      if (userChart || incomeChart || subscriberStatus) {
        const finalTotalUser = userChart?.reduce(
          (accumulator, currentValue) => {
            return accumulator + currentValue.total
          },
          0
        )

        setData({
          users: userChart?.[userChart?.length - 1]?.total ?? 0,
          orders: 0,
          subscribers: subscriberStatus?.totalSubscribers ?? 12,
          newSubscribers: subscriberStatus?.newSubscribers ?? 13,
          income:
            (
              incomeChart?.[incomeChart?.length - 1]?.total ?? 0
            ).toLocaleString() + '€',
          totalUser: finalTotalUser,
        })
      }
    } catch (error: any) {
      handleAPIError(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="grid grid-cols-8 gap-3">
      <StatisticItem
        data={data.users}
        totalUser={data.totalUser}
        type={ETypeStatic.USERS}
      />
      <StatisticItem
        data={data.newSubscribers}
        type={ETypeStatic.SUBSCRIBERS}
        totalUser={data.subscribers}
      />
      {/* <StatisticItem data={data.orders} type={ETypeStatic.ORDERS} /> */}
      <StatisticItem
        data={data.income}
        type={ETypeStatic.INCOME}
        totalUser={data.income}
      />
    </div>
  )
}
