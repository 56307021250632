import { zodResolver } from '@hookform/resolvers/zod'
import { Avatar, Card, Skeleton, message } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { ShareSelectInput } from '@components'
import {
  EUserManagementTabs,
  EUserStatus,
  PATH_USER_MANAGEMENT,
  PLACEHOLDER_IMAGE_AVATAR,
  TwoPartOfRecoveryModalContent,
} from '@configs'
import { RouterParams, TUpdateUserData } from '@interfaces'
import {
  RootState,
  deleteUserByIdAction,
  getUserByIdAction,
  recoverUserByIdAction,
  selectUsersLoading,
  updateUserByIdAction,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { themes } from '@theme'
import { Button, Input } from 'src/common'
import RecoveryModal from 'src/components/user-management/RecoveryModal'
import { getListPackageAction } from 'src/redux/actions/package-management'
import { addUserSchema } from './config'

interface IUserInfoTabProps {
  activeTab?: string
}

export const UserInfoTab = ({ activeTab }: IUserInfoTabProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const { userId } = useParams<RouterParams['UserDetail']>()
  const { user } = useAppSelector((state: RootState) => state.users)

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<TUpdateUserData>({
    defaultValues: {
      email: '',
      name: '',
      packageId: undefined,
      phone: '',
      status: 'active',
      username: '',
    },
    resolver: zodResolver(addUserSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const isEditable = true

  const { t } = useTranslation(['common', 'user', 'error'])
  const { packages } = useAppSelector((state: RootState) => state.package)
  let options: { value: string; label: string }[] = [
    {
      value: EUserStatus.ACTIVE,
      label: t('user:active'),
    },
    {
      value: EUserStatus.INACTIVE,
      label: t('user:inactive'),
    },
    {
      value: EUserStatus.TEMP_DELETED,
      label: t('user:delete_can_recover'),
    },
    {
      value: EUserStatus.TOTAL_DELETED,
      label: t('user:delete_not_recover'),
    },
  ]

  const optionsFiltered = options.filter((item, index) => {
    if (user?.status === EUserStatus.TEMP_DELETED) {
      return (
        item.value !== EUserStatus.INACTIVE && item.value !== EUserStatus.ACTIVE
      )
    }

    return true
  })

  const getUserByIdActionLoading = useSelector((state: RootState) =>
    selectUsersLoading(state, 'getUserByIdAction')
  )
  const packagesOptions = packages
    ?.filter((pkg) => pkg?.isActive)
    .map((packageItem) => {
      return {
        label: packageItem?.name,
        value: packageItem?.id?.toString(),
        note: packageItem.type === 'private' ? 'private' : '',
      }
    })

  const getUserById = () => {
    const id = userId
    id && dispatch(getUserByIdAction(id))
  }

  const onClose = () => {
    setOpen(false)
  }

  const onCancel = () => {
    navigate(PATH_USER_MANAGEMENT)
  }

  const onSave = async (data: TUpdateUserData) => {
    const { status, email, packageId, ...passData } = data

    if (
      status === EUserStatus.TEMP_DELETED ||
      status === EUserStatus.TOTAL_DELETED
    ) {
      try {
        const isSoft = status === EUserStatus.TEMP_DELETED ? true : false
        const response = await dispatch(
          deleteUserByIdAction({
            id: Number(userId),
            isSoft: isSoft,
          })
        ).unwrap()
        if (response.message) {
          dispatch(getUserByIdAction(userId as string))
          message.success({
            content: response.message,
          })
          navigate(PATH_USER_MANAGEMENT)
        }
      } catch (error: any) {
        message.error({
          content: error.message,
        })
      }

      return
    }

    let payload: any = {
      id: Number(userId),
      ...passData,
      status: status?.toLowerCase(),
    }
    if (packageId) {
      payload.packageId = +packageId
    }

    try {
      const response = await dispatch(updateUserByIdAction(payload)).unwrap()

      if (response.message) {
        message.success({
          content: response.message,
        })
        navigate(PATH_USER_MANAGEMENT)
      }
    } catch (error: any) {
      message.error({
        content: error.message,
      })
    }
  }

  const onRecovery = async () => {
    try {
      const response = await dispatch(
        recoverUserByIdAction({
          id: Number(userId),
        })
      ).unwrap()

      if (response?.success) {
        response.message &&
          message.success({
            content: response?.message,
          })
        navigate(PATH_USER_MANAGEMENT)
      }
    } catch (error: any) {
      message.error({
        content: error.message,
      })
    } finally {
      setOpen(false)
    }
  }

  const isDisableDropdown = user?.status === EUserStatus.TOTAL_DELETED

  const recoveryPeriod = moment().diff(user?.deletedAt, 'days', false)

  const ContentModal = () => {
    return (
      <div>
        <span>{t(`common:${TwoPartOfRecoveryModalContent.PART_1}`)}</span>
        <b>
          {' '}
          {recoveryPeriod} {t('common:days')}
        </b>
        <span>{t(`common:${TwoPartOfRecoveryModalContent.PART_2}`)}</span>
      </div>
    )
  }

  useEffect(() => {
    dispatch(getListPackageAction({ page: 1, limit: 10000 }))
  }, [dispatch])

  useEffect(() => {
    activeTab === EUserManagementTabs.USER_INFO && getUserById()
  }, [dispatch, activeTab])

  useEffect(() => {
    if (user) {
      setValue('name', user.name ?? '')
      setValue('username', user.username ?? '')
      setValue('email', user.email ?? '')
      setValue('phone', user.phone || undefined)
      setValue('status', user.status ?? 'active')

      const activePackage = user.packages?.find(
        (item) => item.PackagesSubscribed?.isActive
      )
      if (activePackage) {
        setValue('packageId', `${activePackage.id}` as any)
      }
    }
  }, [setValue, user])
  return (
    <Card>
      {getUserByIdActionLoading ? (
        <Skeleton paragraph={{ rows: 4 }} />
      ) : (
        <div className="pl-4 pr-4">
          <div className="flex justify-center">
            <Avatar
              size={{ xs: 32, sm: 40, md: 64, lg: 80, xl: 100, xxl: 110 }}
              src={user?.profilePhoto?.url ?? PLACEHOLDER_IMAGE_AVATAR}
            />
          </div>
          <div className="flex justify-between max:[640px]:flex-col sm:flex-row">
            <div className="flex-1 sm:flex-[0_0_40%] sm:max-w-[40%] block relative min-h-[1px]">
              <div className="mb-4">
                <Controller
                  name="name"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        disabled={!isEditable}
                        alignment="col"
                        label={t('common:full_name')}
                        name="name"
                        className="input"
                        value={value}
                        onChange={onChange}
                        type="text"
                        style={{
                          cursor: 'default',
                          color: themes.theme.light.colors.text,
                        }}
                        errors={error?.message}
                        required
                      />
                    )
                  }}
                />
              </div>
              <div className="">
                <Controller
                  name="email"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        disabled={true}
                        alignment="col"
                        label={t('common:email')}
                        name="email"
                        className="input"
                        value={value}
                        onChange={onChange}
                        type="text"
                        style={{
                          cursor: 'default',
                          color: themes.theme.light.colors.text,
                        }}
                        errors={error?.message}
                        required
                      />
                    )
                  }}
                />
              </div>
            </div>

            <div className="flex-1 sm:flex-[0_0_40%] sm:max-w-[40%] block relative min-h-[1px]">
              <div className="mb-4">
                <Controller
                  name="phone"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        disabled={!isEditable}
                        alignment="col"
                        label={t('common:phone_number')}
                        name="phone"
                        className="input"
                        value={value}
                        onChange={(e) => {
                          if (e.target.value) {
                            onChange(e.target.value)
                            return
                          }
                          onChange(undefined)
                        }}
                        type="text"
                        style={{
                          cursor: 'default',
                          color: themes.theme.light.colors.text,
                        }}
                        errors={error?.message}
                      />
                    )
                  }}
                />
              </div>
              <div className="mb-4">
                <Input
                  disabled={true}
                  alignment="col"
                  label={t('common:creation_time')}
                  name="pacingPulse"
                  className="input"
                  value={
                    moment(user?.createdAt).format('DD MMM YY - HH:mm:ss') ??
                    new Date()
                  }
                  type="text"
                  style={{
                    cursor: 'default',
                    color: themes.theme.light.colors.text,
                  }}
                />
              </div>
            </div>
          </div>
          <div className=" flex flex-row justify-between">
            <div className="flex-1 sm:flex-[0_0_40%] sm:max-w-[40%] block relative min-h-[1px]">
              <Controller
                name="username"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      disabled={!isEditable}
                      alignment="col"
                      label={t('common:user_name')}
                      name="username"
                      className="input"
                      value={value}
                      onChange={onChange}
                      type="text"
                      style={{
                        cursor: 'default',
                        color: themes.theme.light.colors.text,
                      }}
                      errors={error?.message}
                      required
                    />
                  )
                }}
              />
            </div>
            <div className="flex-1 sm:flex-[0_0_40%] sm:max-w-[40%] block relative min-h-[1px]">
              <Input
                disabled={true}
                alignment="col"
                label={t('common:last_time_active')}
                name="name"
                className="input "
                value={
                  moment(user?.lastActiveTime).format('DD MMM YY - HH:mm:ss') ??
                  new Date()
                }
                type="text"
                style={{
                  cursor: 'default',
                  color: themes.theme.light.colors.text,
                }}
              />
            </div>
          </div>

          <div className=" flex flex-row justify-between mt-4">
            <div className="flex-1 sm:flex-[0_0_40%] sm:max-w-[40%] block relative min-h-[1px]">
              <Controller
                name="status"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <ShareSelectInput
                      data={optionsFiltered}
                      onChange={onChange}
                      label={t('common:status')}
                      value={value}
                      className="h-[38px]"
                      placeholder="Select an status"
                      disabled={isDisableDropdown || !isEditable}
                    />
                  )
                }}
              />
            </div>
            <div className="flex-1 sm:flex-[0_0_40%] sm:max-w-[40%] block relative min-h-[1px]">
              <Controller
                name="packageId"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <ShareSelectInput
                      disabled={!isEditable}
                      value={value}
                      data={packagesOptions as any}
                      label="Package"
                      placeholder="Choose package"
                      onChange={(data) => {
                        onChange(data)
                      }}
                    />
                  )
                }}
              />
            </div>
          </div>

          <div className="flex justify-end items-center">
            <Button
              type="text"
              disabled={!Boolean(user?.deletedAt) || isDisableDropdown}
              size="middle"
              className="submit__btn login-btn mr-4 mt-5"
              onClick={() => {
                setOpen(true)
              }}
            >
              {t('common:recover')}
            </Button>

            <div className="modify-user-btn flex items-center mt-5 justify-end">
              <Button
                type="ghost"
                size="middle"
                className="submit__btn login-btn mr-2"
                onClick={onCancel}
              >
                {t('common:cancel')}
              </Button>
              <Button
                disabled={!isEditable}
                htmlType="submit"
                type="primary"
                size="middle"
                className="submit__btn loginselected-btn"
                onClick={handleSubmit(onSave)}
              >
                {t('common:save')}
              </Button>
            </div>
          </div>

          <RecoveryModal
            isLoading={false}
            onClose={onClose}
            open={open}
            title={t('common:recovery_modal_header')}
            content={<ContentModal />}
            onClick={onRecovery}
          />
        </div>
      )}
    </Card>
  )
}
