import { useEffect, useRef, useState } from 'react'
import { Skeleton, Typography, message } from 'antd'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import {
  CONTENT_MANAGEMENT_VALIDATION,
  ETeacherLandingPageTabs,
} from '@configs'
import {
  createEditVideoIntroAction,
  getVideoIntroAction,
  RootState,
  useAppDispatch,
  useAppSelector,
} from '@redux'
import { Button, HookFormTextArea, Input } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { EMediaCategory, EMediaType, IMediaItem } from 'src/interfaces/gallery'
import { InputWrapperGridStyled } from '@theme'
import SelectMedia from 'src/components/gallery/SelectMedia'
import SelectMediaModal from 'src/components/gallery/SelectMediaModal'
import { DEFAULT_TEACHER_VIDEO_INTRO, teacherVideoIntroSchema } from './configs'
import { CommonTeacherContentWrapperStyled } from '../../styled'
import { ButtonWrapper } from 'src/pages/teacher-request-detail/fill-in-teacher-information/style'
import { IVideoIntroInput } from '@interfaces'

interface IProps {
  activeTab?: string
}

const VideoIntro = ({ activeTab }: IProps) => {
  const dispatch = useAppDispatch()

  const [isModalLoading, setIsModalLoading] = useState(false)
  const [isModalMobileLoading, setIsModalMobileLoading] = useState(false)
  const [showModalSelectMedia, setShowModalSelectMedia] = useState(false)
  const [selectMediaType, setSelectMediaType] = useState<EMediaType>(
    EMediaType.VIDEO
  )
  const [selectedField, setSelectedField] = useState<
    'desktop' | 'mobile' | null
  >(null)
  // state for upload images
  const onChangeDesktopRef = useRef<any>(null)
  const onChangeMobileRef = useRef<any>(null)
  const [videoSrc, setVideoSrc] = useState('')
  const [videoMobileSrc, setVideoMobileSrc] = useState('')
  const [thumbnailSrc, setThumbnailSrc] = useState('')
  const [thumbnailMobileSrc, setThumbnailMobileSrc] = useState('')

  const { videoIntro, loadings } = useAppSelector(
    (state: RootState) => state.teacherLanding
  )
  const isGettingVideoIntro = loadings['getVideoIntroAction']
  const isEditingVideoIntro = loadings[`createEditVideoIntroAction`]

  const { handleSubmit, control, setValue, reset, getValues } =
    useForm<IVideoIntroInput>({
      defaultValues: DEFAULT_TEACHER_VIDEO_INTRO,
      resolver: zodResolver(teacherVideoIntroSchema),
      mode: 'onSubmit',
      reValidateMode: 'onChange',
    })

  const onSubmit = async (data: IVideoIntroInput) => {
    try {
      const response = await dispatch(createEditVideoIntroAction(data)).unwrap()

      if (response) {
        message.success({
          content: response?.message ?? t('common:save_success'),
        })
      }
    } catch (error: any) {
      message.error({
        content: error?.message,
      })
    }
  }

  const onCloseModalSelectMedia = () => {
    setShowModalSelectMedia(false)
  }

  const onSelectVideo = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setValue('mediaId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
      setVideoSrc(data?.baseUrl ?? '')
    } else if (selectedField === 'mobile') {
      setValue('mediaMobileId', data?.id ?? 0)
      onChangeMobileRef.current(data?.id ?? 0)
      setVideoMobileSrc(data?.baseUrl ?? '')
    }
  }

  const onSelectThumbnail = (data: IMediaItem) => {
    if (selectedField === 'desktop') {
      setValue('thumbnailId', data?.id ?? 0)
      onChangeDesktopRef.current(data?.id ?? 0)
      setThumbnailSrc(data?.baseUrl ?? '')
    } else if (selectedField === 'mobile') {
      setValue('thumbnailMobileId', data?.id ?? 0)
      onChangeMobileRef.current(data?.id ?? 0)
      setThumbnailMobileSrc(data?.baseUrl ?? '')
    }
  }

  const onSelectMedia = (data: IMediaItem) => {
    if (selectMediaType === EMediaType.VIDEO) {
      onSelectVideo(data)
      return
    }

    onSelectThumbnail(data)
    setShowModalSelectMedia(false)
  }

  const onReset = () => {
    if (!videoIntro || !Object.keys(videoIntro)?.length) {
      reset()
      return
    }

    reset({
      ...videoIntro,
      subTitle: videoIntro?.subTitle ?? '',
      subTitleInDutch: videoIntro?.subTitleInDutch ?? '',
      mediaId: videoIntro?.media?.id ?? 0,
      mediaMobileId: videoIntro?.mediaMobile?.id ?? 0,
      thumbnailId: videoIntro?.thumbnail?.id ?? 0,
      thumbnailMobileId: videoIntro?.thumbnailMobile?.id ?? 0,
    })
    setVideoSrc(videoIntro?.media?.url ?? '')
    setVideoMobileSrc(videoIntro?.mediaMobile?.url ?? '')
    setThumbnailSrc(videoIntro?.thumbnail?.url ?? '')
    setThumbnailMobileSrc(videoIntro?.thumbnailMobile?.url ?? '')
  }

  useEffect(() => {
    if (ETeacherLandingPageTabs.TEACHER_VIDEO_INTRO.includes(activeTab ?? '')) {
      dispatch(getVideoIntroAction())
    }
  }, [activeTab, dispatch])

  useEffect(() => {
    onReset()

    return () => {
      reset()
      setVideoSrc('')
      setVideoMobileSrc('')
    }
  }, [videoIntro, reset])

  return (
    <CommonTeacherContentWrapperStyled>
      {isGettingVideoIntro ? (
        <>
          <Skeleton
            paragraph={{ rows: 4 }}
            style={{
              marginTop: 10,
            }}
          />
        </>
      ) : (
        <>
          <InputWrapperGridStyled>
            <Controller
              name="title"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label="Title"
                    required
                    size="middle"
                    value={value}
                    onChange={(e) => {
                      onChange(e?.target?.value)
                    }}
                    errors={error?.message}
                  />
                )
              }}
            />

            <Controller
              name="titleInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    alignment="col"
                    label="Title in Netherlands"
                    required
                    size="middle"
                    value={value}
                    onChange={(e) => {
                      onChange(e?.target?.value)
                    }}
                    errors={error?.message}
                  />
                )
              }}
            />

            <Controller
              name="subTitle"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    required
                    label="Sub-title"
                    alignment="col"
                    errors={error?.message}
                  />
                )
              }}
            />
            <Controller
              name="subTitleInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    value={value}
                    onChange={onChange}
                    required
                    label="Sub-title in Netherlands"
                    alignment="col"
                    errors={error?.message}
                  />
                )
              }}
            />

            <HookFormTextArea
              control={control}
              controlName={`mediaDescription`}
              label="Description"
              rows={5}
              required
            />

            <HookFormTextArea
              control={control}
              controlName={`mediaDescriptionInDutch`}
              label="Description in Netherlands"
              rows={5}
              required
            />

            <div className="flex flex-col gap-1 items-end">
              <Controller
                name="mediaId"
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => {
                  onChangeDesktopRef.current = onChange
                  return (
                    <CustomDragger
                      label="Video"
                      name="mediaId"
                      id="mediaId"
                      containerClassName="mt-3"
                      alignment="col"
                      required
                      onLoadEnd={(data) => {
                        setValue('mediaId', data?.dataValues.id ?? 0)
                        onChange(data?.dataValues.id ?? 0)
                        setVideoSrc((prev) => data.original ?? prev)
                      }}
                      errors={error?.message}
                      initResource={videoSrc}
                      reset={getValues('mediaId') === 0}
                      allowFileTypes={[
                        'video/mp4',
                        'video/webm',
                        'video/m4v',
                        'video/mov',
                        'video/qt',
                      ]}
                      limitFileSize={
                        CONTENT_MANAGEMENT_VALIDATION.MAX_VIDEO_SIZE
                      }
                      minFileSize={CONTENT_MANAGEMENT_VALIDATION.MIN_VIDEO_SIZE}
                      changeLoading={setIsModalLoading}
                      uploadType={EMediaType.VIDEO as any}
                      uploadCategory={EMediaCategory.CONTENT}
                    />
                  )
                }}
              />
              <SelectMedia
                isNotGrid
                buttonClassName="!text-blue-500"
                onClickSelectMedia={() => {
                  setSelectedField((pre) => 'desktop')
                  setShowModalSelectMedia(true)
                  setSelectMediaType(EMediaType.VIDEO)
                }}
              />
            </div>

            <div className="flex flex-col gap-1 items-end">
              <Controller
                name="thumbnailId"
                control={control}
                render={({ field: { onChange }, fieldState: { error } }) => {
                  onChangeDesktopRef.current = onChange
                  return (
                    <CustomDragger
                      label="Thumbnail"
                      name="thumbnailId"
                      id="thumbnailId"
                      containerClassName="mt-3"
                      alignment="col"
                      required
                      onLoadEnd={(data) => {
                        setValue('thumbnailId', data?.id ?? 0)
                        onChange(data?.id ?? 0)
                        setThumbnailSrc((prev) => data.original ?? prev)
                      }}
                      errors={error?.message}
                      initResource={thumbnailSrc}
                      reset={getValues('thumbnailId') === 0}
                      allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                      limitFileSize={
                        CONTENT_MANAGEMENT_VALIDATION.MAX_IMAGE_SIZE
                      }
                      changeLoading={setIsModalLoading}
                      uploadType={EMediaType.IMAGE as any}
                      uploadCategory={EMediaCategory.CONTENT}
                    />
                  )
                }}
              />
              <Typography.Text type="danger">
                {t('common:imageResolutionStandard')}
              </Typography.Text>
              <SelectMedia
                isNotGrid
                buttonClassName="!text-blue-500"
                onClickSelectMedia={() => {
                  setSelectedField((pre) => 'desktop')
                  setShowModalSelectMedia(true)
                  setSelectMediaType(EMediaType.IMAGE)
                }}
              />
            </div>

            <div className="flex flex-col gap-1 items-end mb-4">
              <Controller
                name={'mediaMobileId'}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  onChangeMobileRef.current = onChange
                  return (
                    <>
                      <CustomDragger
                        label="Video Mobile"
                        containerClassName="mt-[10px]"
                        alignment="col"
                        onLoadEnd={(data) => {
                          setValue('mediaMobileId', data?.dataValues.id ?? 0)
                          onChange(data?.dataValues.id ?? 0)
                          setVideoMobileSrc((prev) => data.original ?? prev)
                        }}
                        name="mediaMobileId"
                        id="mediaMobileId"
                        required
                        errors={error?.message}
                        multiple={false}
                        initResource={videoMobileSrc}
                        reset={getValues('mediaMobileId') === 0}
                        allowFileTypes={[
                          'video/mp4',
                          'video/webm',
                          'video/m4v',
                          'video/mov',
                          'video/qt',
                        ]}
                        limitFileSize={
                          CONTENT_MANAGEMENT_VALIDATION.MAX_VIDEO_SIZE
                        }
                        minFileSize={
                          CONTENT_MANAGEMENT_VALIDATION.MIN_VIDEO_SIZE
                        }
                        changeLoading={setIsModalMobileLoading}
                        uploadType={EMediaType.VIDEO as any}
                        uploadCategory={EMediaCategory.CONTENT}
                      />
                    </>
                  )
                }}
              />
              <SelectMedia
                isNotGrid
                buttonClassName="!text-blue-500"
                onClickSelectMedia={() => {
                  setSelectedField((pre) => 'mobile')
                  setShowModalSelectMedia(true)
                  setSelectMediaType(EMediaType.VIDEO)
                }}
              />
            </div>

            <div className="flex flex-col gap-1 items-end mb-4">
              <Controller
                name={'thumbnailMobileId'}
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  onChangeMobileRef.current = onChange
                  return (
                    <>
                      <CustomDragger
                        label="Thumbnail Mobile"
                        containerClassName="mt-[10px]"
                        alignment="col"
                        onLoadEnd={(data) => {
                          setValue('thumbnailMobileId', data?.id ?? 0)
                          onChange(data?.id ?? 0)
                          setThumbnailMobileSrc((prev) => data.original ?? prev)
                        }}
                        name="thumbnailMobileId"
                        id="thumbnailMobileId"
                        required
                        errors={error?.message}
                        multiple={false}
                        initResource={thumbnailMobileSrc}
                        reset={getValues('thumbnailMobileId') === 0}
                        allowFileTypes={[
                          'image/png',
                          'image/jpeg',
                          'image/jpg',
                        ]}
                        limitFileSize={
                          CONTENT_MANAGEMENT_VALIDATION.MAX_IMAGE_SIZE
                        }
                        changeLoading={setIsModalMobileLoading}
                        uploadType={EMediaType.IMAGE as any}
                        uploadCategory={EMediaCategory.CONTENT}
                      />
                    </>
                  )
                }}
              />
              <Typography.Text type="danger">
                {t('common:imageMobileResolutionStandard')}
              </Typography.Text>
              <SelectMedia
                isNotGrid
                buttonClassName="!text-blue-500"
                onClickSelectMedia={() => {
                  setSelectedField((pre) => 'mobile')
                  setShowModalSelectMedia(true)
                  setSelectMediaType(EMediaType.IMAGE)
                }}
              />
            </div>
          </InputWrapperGridStyled>

          <ButtonWrapper className="w-full col-span-2 !justify-center !items-center">
            {/* <Button
              size="small"
              className="!text-[16px] !text-white !bg-gray-400 !border-none"
              onClick={() => {}}
            >
              {t('common:cancel')}
            </Button> */}
            <Button
              htmlType="submit"
              type="primary"
              size="small"
              className="!text-[16px] !text-white !border-none"
              onClick={handleSubmit(onSubmit)}
              loading={
                isModalLoading || isModalMobileLoading || isEditingVideoIntro
              }
            >
              {t('common:save')}
            </Button>
          </ButtonWrapper>
        </>
      )}
      {showModalSelectMedia ? (
        <>
          <SelectMediaModal
            isOpen={showModalSelectMedia}
            onClose={onCloseModalSelectMedia}
            mediaType={selectMediaType}
            category={EMediaCategory.CONTENT}
            onSelectMedia={onSelectMedia}
          />
        </>
      ) : (
        <></>
      )}
    </CommonTeacherContentWrapperStyled>
  )
}

export default VideoIntro
