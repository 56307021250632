import { createAsyncThunk } from '@reduxjs/toolkit'

import { teacherLandingAPI } from '@api'
import { ILandingFormData } from 'src/interfaces/aboutus-management'
import {
  IHowToContentInput,
  IReasonDetailInputParams,
  IVideoIntroInput,
  IWhyUsContentInputParams,
} from '@interfaces'

export const getTeacherBannerAction = createAsyncThunk(
  'teacher/getTeacherBannerAction',
  async () => {
    try {
      const res = await teacherLandingAPI.getBanner()
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const createEditTeacherBannerAction = createAsyncThunk(
  'teacher/createEditTeacherBannerAction',
  async (params: ILandingFormData) => {
    try {
      const res = await teacherLandingAPI.createEditBanner(params)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getWhyUsTableAction = createAsyncThunk(
  'teacher/getWhyUsTableAction',
  async () => {
    try {
      const res = await teacherLandingAPI.getWhyUsTable()
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getWhyUsContentAction = createAsyncThunk(
  'teacher/getWhyUsContentAction',
  async () => {
    try {
      const res = await teacherLandingAPI.getWhyUsContent()
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const createEditWhyUsContentAction = createAsyncThunk(
  'teacher/createEditWhyUsContentAction',
  async (params: IWhyUsContentInputParams) => {
    try {
      const res = await teacherLandingAPI.createEditWhyUsContent(params)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getReasonDetailByIdAction = createAsyncThunk(
  'teacher/getReasonDetailByIdAction',
  async (id: number | string) => {
    try {
      const res = await teacherLandingAPI.getReasonDetailById(id)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const createReasonDetailAction = createAsyncThunk(
  'teacher/createReasonDetailAction',
  async (params: IReasonDetailInputParams) => {
    try {
      const res = await teacherLandingAPI.createReasonDetail(params)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const editReasonDetailByIdAction = createAsyncThunk(
  'teacher/editReasonDetailByIdAction',
  async (params: {
    payload: IReasonDetailInputParams
    id: number | string
  }) => {
    try {
      const res = await teacherLandingAPI.editReasonDetailById(
        params.id,
        params.payload
      )
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const deleteReasonByIdAction = createAsyncThunk(
  'teacher/deleteReasonByIdAction',
  async (id: number | string) => {
    try {
      const res = await teacherLandingAPI.deleteReasonDetailById(id)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getVideoIntroAction = createAsyncThunk(
  'teacher/getVideoIntroAction',
  async () => {
    try {
      const res = await teacherLandingAPI.getVideoIntro()
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const createEditVideoIntroAction = createAsyncThunk(
  'teacher/createEditVideoIntroAction',
  async (params: IVideoIntroInput) => {
    try {
      const res = await teacherLandingAPI.createEditVideoIntro(params)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getHowToTableAction = createAsyncThunk(
  'teacher/getHowToTableAction',
  async () => {
    try {
      const res = await teacherLandingAPI.getHowToTable()
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getHowToContentAction = createAsyncThunk(
  'teacher/getHowToContentAction',
  async () => {
    try {
      const res = await teacherLandingAPI.getHowToContent()
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const createEditHowToContentAction = createAsyncThunk(
  'teacher/createEditHowToContentAction',
  async (params: IHowToContentInput) => {
    try {
      const res = await teacherLandingAPI.createEditHowToContent(params)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getHowToDetailByIdAction = createAsyncThunk(
  'teacher/getHowToDetailByIdAction',
  async (id: number | string) => {
    try {
      const res = await teacherLandingAPI.getHowToDetailById(id)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const createHowToDetailAction = createAsyncThunk(
  'teacher/createHowToDetailAction',
  async (params: IReasonDetailInputParams) => {
    try {
      const res = await teacherLandingAPI.createHowToDetail(params)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const editHowToDetailByIdAction = createAsyncThunk(
  'teacher/editHowToDetailByIdAction',
  async (params: {
    payload: IReasonDetailInputParams
    id: number | string
  }) => {
    try {
      const res = await teacherLandingAPI.editHowToDetailById(
        params.id,
        params.payload
      )
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const deleteHowToDetailByIdAction = createAsyncThunk(
  'teacher/deleteHowToDetailIdAction',
  async (id: number | string) => {
    try {
      const res = await teacherLandingAPI.deleteHowToDetailById(id)
      return res.data
    } catch (error) {
      throw error
    }
  }
)
