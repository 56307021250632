import { Typography } from 'antd'

import { ButtonWrapper } from 'src/pages/teacher-request-detail/fill-in-teacher-information/style'
import { Button } from 'src/common'
import { AppModal } from '@components'

export const ModalConfirmPublish = ({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}) => {
  return (
    <AppModal
      open={open}
      onClose={onClose}
      haveCloseIcon
      childrenClassName="!mt-2"
    >
      <Typography.Title level={3}>Publish pop up</Typography.Title>
      <Typography.Text className="mt-4">
        Are you sure you want to publish this pop up? This action can not be
        undone
      </Typography.Text>
      <ButtonWrapper className="!justify-end mt-6">
        <Button type="default" size="small" id="blog-form" onClick={onClose}>
          Cancel
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          size="small"
          id="blog-form"
          onClick={() => {
            onConfirm()
            onClose()
          }}
        >
          Confirm
        </Button>
      </ButtonWrapper>
    </AppModal>
  )
}
