import { DeleteOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextArea } from 'src/common'
import { CustomDragger } from 'src/common/upload/CustomDragger'
import { EMediaCategory, EMediaType } from 'src/interfaces/gallery'
import { IUploadMediaResponse } from 'src/interfaces/media'

interface IProps {
  index: number
  id: string
  remove: (index?: number | number[]) => void
}

export default function PhotoCommentForm({ index, id, remove }: IProps) {
  const { t } = useTranslation(['testManagement'])
  const { setValue, control, watch } = useFormContext()

  const [isUploading, setIsUploading] = useState(false)
  const [canSetValue, setCanSetValue] = useState(false)
  const [uploadImageData, setUploadImageData] =
    useState<IUploadMediaResponse | null>(null)

  useEffect(() => {
    if (!canSetValue) return
    setValue(`feedback.${index}.photoImage`, uploadImageData?.original || '')
    setValue(`feedback.${index}.mediaId`, uploadImageData?.id || '')
    setCanSetValue(false)
  }, [
    canSetValue,
    index,
    setValue,
    uploadImageData?.id,
    uploadImageData?.original,
  ])

  return (
    <div className="answer-item">
      <div className="flex flex-row relative answer-item answer mb-5 row gap-4 item-center">
        <div className="flex flex-col flex-[1]">
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 500,
              marginBottom: 8,
            }}
          >
            {t('testManagement:photoComment')}
            <DeleteOutlined
              style={{
                marginLeft: 8,
                color: '#ff0000',
                ...(isUploading && {
                  cursor: 'not-allowed',
                  opacity: 0.5,
                }),
              }}
              onClick={() => {
                if (isUploading) return
                remove(index)
              }}
            />
          </Typography>

          <Controller
            name={`feedback.${index}.photoImage` as any}
            control={control}
            render={({ field: { onChange }, fieldState: { error } }) => {
              return (
                <CustomDragger
                  name={`feedback.${id}.photoImage`}
                  id={`feedback.${id}.photoImage`}
                  containerClassName="mt-3"
                  required={false}
                  onLoadEnd={async (data) => {
                    setUploadImageData(data as IUploadMediaResponse)
                  }}
                  changeLoading={(isLoading) => {
                    setCanSetValue(true)
                    setIsUploading(isLoading)
                  }}
                  errors={error?.message}
                  initResource={watch(`feedback.${index}.photoImage`)}
                  limitFileSize={5}
                  allowFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                  uploadType={EMediaType.IMAGE as any}
                  uploadCategory={EMediaCategory.CONTENT}
                  disabled={isUploading}
                />
              )
            }}
          />
        </div>

        <div className="flex flex-col flex-[2]">
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 500,
              marginBottom: 20,
            }}
          >
            {t('testManagement:photoCaption')}
          </Typography>

          <div>
            <Controller
              name={`feedback.${index}.photoCaption` as any}
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <TextArea
                    name={`feedback.${index}.photoCaption`}
                    required
                    onChange={onChange}
                    value={value}
                    errors={error?.message}
                    style={{ minHeight: 197 }}
                  />
                )
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
