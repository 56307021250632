import {
  MultiBarChartComponent,
  MultiLineChartComponent,
  PieChartComponent,
  ShareDateSelect,
  StyledEmpty,
} from '@components'
import {
  IRevenueTeacherPackage,
  IWithdrawalAmountItem,
  RevenueByAuthor,
  RevenueByCourseType,
} from '@interfaces'
import { DescriptionDonut } from '@pages'
import {
  getRevenueByCoursePurchase,
  getTeacherRevenueByPackage,
  getTeacherWithdrawalAmount,
  useAppDispatch,
} from '@redux'
import { themes } from '@theme'
import { formatCurrency, formatDate, getCurrentYear } from '@utils'
import { Skeleton, Typography } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'

type TeacherRevenueProps = {
  activeTab: string
}

export const TeacherRevenue = (props: TeacherRevenueProps) => {
  const dispatch = useAppDispatch()
  const [selectedYear, setSelectedYear] = useState<string>(getCurrentYear())
  const [selectedYearWithdrawalAmount, setSelectedYearWithdrawalAmount] =
    useState<string>(getCurrentYear())

  const [
    selectedYearTeacherPackageRevenue,
    setSelectedYearTeacherPackageRevenue,
  ] = useState<string>(getCurrentYear())

  const [
    isLoadingRevenueByCoursePurchase,
    setIsLoadingRevenueByCoursePurchase,
  ] = useState(false)
  const [
    isLoadingTeacherWithdrawalAmount,
    setIsLoadingTeacherWithdrawalAmount,
  ] = useState(false)

  const [isLoadingTeacherPackageRevenue, setIsLoadingTeacherPackageRevenue] =
    useState<boolean>(false)

  const [totalRevenue, setTotalRevenue] = useState<number>(0)
  const [totalRevenueTeacherPackage, setTotalRevenueTeacherPackage] =
    useState<number>(0)
  const [revenueByAuthor, setRevenueByAuthor] =
    useState<RevenueByAuthor | null>(null)
  const [revenueByCourseType, setRevenueByCourseType] =
    useState<RevenueByCourseType | null>(null)

  const [withdrawalAmount, setWithdrawalAmount] = useState<
    IWithdrawalAmountItem[]
  >([])

  const [teacherPackageRevenue, setTeacherPackageRevenue] =
    useState<IRevenueTeacherPackage | null>(null)

  const fetchRevenueByCoursePurchase = async () => {
    setIsLoadingRevenueByCoursePurchase(true)
    const response = await dispatch(
      getRevenueByCoursePurchase({
        year: selectedYear,
      })
    ).unwrap()
    setIsLoadingRevenueByCoursePurchase(false)
    if (response) {
      setTotalRevenue(response.totalRevenue)
      setRevenueByAuthor(response.revenueByAuthor)
      setRevenueByCourseType(response.revenueByCourseType)
    }
  }

  const fetchTeacherWithdrawalAmount = async () => {
    setIsLoadingTeacherWithdrawalAmount(true)
    const response = await dispatch(
      getTeacherWithdrawalAmount({
        year: selectedYearWithdrawalAmount,
      })
    ).unwrap()
    setIsLoadingTeacherWithdrawalAmount(false)
    if (response) {
      setWithdrawalAmount(response)
    }
  }

  const fetchTeacherPackageRevenue = async () => {
    setIsLoadingTeacherPackageRevenue(true)
    const response = await dispatch(
      getTeacherRevenueByPackage({
        year: selectedYearTeacherPackageRevenue,
      })
    ).unwrap()
    setIsLoadingTeacherPackageRevenue(false)
    if (response) {
      setTeacherPackageRevenue(response)
      setTotalRevenueTeacherPackage(response.totalRevenue)
    }
  }

  useEffect(() => {
    fetchTeacherPackageRevenue()
  }, [selectedYearTeacherPackageRevenue])

  useEffect(() => {
    fetchRevenueByCoursePurchase()
  }, [selectedYear])

  useEffect(() => {
    fetchTeacherWithdrawalAmount()
  }, [selectedYearWithdrawalAmount])

  const formatRevenueByCourseType = useMemo(() => {
    return {
      labels: Object.values(revenueByCourseType?.overview || {}).map(
        (item) => item.title
      ),
      datasets: [
        {
          data: Object.values(revenueByCourseType?.overview || {}).map(
            (item) => item.value
          ),
          backgroundColor: Object.values(
            revenueByCourseType?.overview || {}
          ).map((item) => item.bgColor),
          borderWidth: 1,
        },
      ],
    }
  }, [revenueByCourseType])

  const formatRevenueByAuthor = useMemo(() => {
    return {
      labels: Object.values(revenueByAuthor?.overview || {}).map(
        (item) => item.title
      ),
      datasets: [
        {
          data: Object.values(revenueByAuthor?.overview || {}).map(
            (item) => item.value
          ),
          backgroundColor: Object.values(revenueByAuthor?.overview || {}).map(
            (item) => item.bgColor
          ),
          borderWidth: 1,
        },
      ],
    }
  }, [revenueByAuthor])

  const formatRevenueByCourseTypeStatistics = useMemo(() => {
    return {
      labels:
        revenueByCourseType?.statistic.map((item) =>
          formatDate(item.month, 'MM/YYYY')
        ) || [],
      datasets: [
        {
          data:
            revenueByCourseType?.statistic.map(
              (item) => item.memberCourseRevenue
            ) || [],
          label: 'Member course',
          backgroundColor: themes.theme.dark.colors.miniDashboard.lightPurple,
        },
        {
          data:
            revenueByCourseType?.statistic.map((item) => item.premiumRevenue) ||
            [],
          label: 'Premium',
          backgroundColor: themes.theme.dark.colors.miniDashboard.darkBlue,
        },
      ],
    }
  }, [revenueByCourseType])

  const formatRevenueByAuthorStatistics = useMemo(() => {
    return {
      labels:
        revenueByAuthor?.statistic.map((item) =>
          formatDate(item.month, 'MM/YYYY')
        ) || [],
      datasets: [
        {
          data: revenueByAuthor?.statistic.map((item) => item.llcRevenue) || [],
          label: 'LLC',
          backgroundColor: themes.theme.dark.colors.miniDashboard.darkBlue,
        },
        {
          data:
            revenueByAuthor?.statistic.map((item) => item.registeredRevenue) ||
            [],
          label: 'Registered',
          backgroundColor: themes.theme.dark.colors.miniDashboard.lightPurple,
        },
      ],
    }
  }, [revenueByAuthor])

  const formatWithdrawalAmount = useMemo(() => {
    return {
      labels: withdrawalAmount?.map((item) =>
        formatDate(item.month, 'MM/YYYY')
      ),
      datasets: [
        {
          data: withdrawalAmount?.map((item) => item.totalRevenue),
          label: 'Total revenue',
          backgroundColor: themes.theme.dark.colors.miniDashboard.darkBlue,
        },
        {
          data: withdrawalAmount?.map((item) => item.totalWithdrawal),
          label: 'Total withdrawal',
          backgroundColor: themes.theme.dark.colors.miniDashboard.lightPurple,
        },
      ],
    }
  }, [withdrawalAmount])

  const isFormatWithdrawalAmountHasData = useMemo(() => {
    return withdrawalAmount.some(
      (item) => item.totalRevenue > 0 || item.totalWithdrawal > 0
    )
  }, [withdrawalAmount])

  const formatTeacherPackageRevenue = useMemo(() => {
    return {
      labels: Object.values(teacherPackageRevenue?.overview || {}).map(
        (item: any) => item.title
      ),
      datasets: [
        {
          data: Object.values(teacherPackageRevenue?.overview || {}).map(
            (item: any) => item.value
          ),
          backgroundColor: Object.values(
            teacherPackageRevenue?.overview || {}
          ).map((item: any) => item.bgColor),
          borderWidth: 1,
        },
      ],
    }
  }, [teacherPackageRevenue])

  const formatTeacherPackageRevenueStatistics = useMemo(() => {
    return {
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      datasets: get(teacherPackageRevenue, 'statistic', []).map((item) => {
        return {
          label: item?.title || '',
          data: item?.dataByMonth.map((i: any) => i.value || 0),
          backgroundColor: item.bgColor || '',
          borderColor: item.bgColor || '',
        }
      }),
    }
  }, [teacherPackageRevenue])

  return (
    <div>
      <div>
        {isLoadingRevenueByCoursePurchase ? (
          <Skeleton className="bg-white py-4 px-6" paragraph={{ rows: 10 }} />
        ) : (
          <div className="bg-white rounded px-8 py-4">
            <div className="flex justify-between items-center">
              <Typography.Text className="font-semibold text-2xl">
                Revenue from course purchase
              </Typography.Text>
              <ShareDateSelect
                type="year"
                placeholder="To"
                format={'YYYY'}
                onChange={(date, dateString) => {
                  setSelectedYear(dateString)
                }}
                maxDate={new Date()}
                defaultValue={moment(selectedYear, 'YYYY')}
                allowClear={false}
              />
            </div>
            {totalRevenue && totalRevenue > 0 ? (
              <div>
                {/* Revenue course type */}
                <div className="mt-4">
                  <div className=" mt-10 grid grid-cols-12 gap-8">
                    <div className="col-span-3 mt-3 ">
                      <Typography.Text className="font-semibold text-xl">
                        Revenue by course type
                      </Typography.Text>

                      <div className="mt-3 relative">
                        <PieChartComponent
                          isPriceChart
                          data={formatRevenueByCourseType}
                          centerData={totalRevenue}
                        />
                        <div className="flex flex-col items-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                          <Typography.Text className="text-center text-xl">
                            Total revenue
                          </Typography.Text>
                          <Typography.Text className="text-center text-xl font-bold">
                            {formatCurrency(totalRevenue)}
                          </Typography.Text>
                        </div>
                      </div>

                      <div className="col-span-5 flex flex-col justify-center ">
                        {Object.values(revenueByCourseType?.overview || {}).map(
                          (item) => (
                            <DescriptionDonut
                              title={item.title}
                              bgColor={item.bgColor}
                              revenue={formatCurrency(item.value)}
                              percentage={item.percentage}
                            />
                          )
                        )}
                      </div>
                    </div>

                    <div className="col-span-9 border p-4 rounded">
                      <div className="flex items-start justify-between">
                        <Typography.Text className="font-semibold text-xl">
                          Statistics
                        </Typography.Text>
                      </div>
                      <MultiBarChartComponent
                        isPriceChart
                        data={formatRevenueByCourseTypeStatistics}
                      />
                    </div>
                  </div>
                </div>

                {/* Revenue by author */}

                <div className="mt-8">
                  <div className=" mt-10 grid grid-cols-12 gap-8">
                    <div className="col-span-3 mt-3">
                      <Typography.Text className="font-semibold text-xl">
                        Revenue by author
                      </Typography.Text>

                      <div className="mt-3 relative">
                        <PieChartComponent
                          isPriceChart
                          data={formatRevenueByAuthor}
                          centerData={totalRevenue}
                        />
                        <div className="flex flex-col items-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                          <Typography.Text className="text-center text-xl">
                            Total revenue
                          </Typography.Text>
                          <Typography.Text className="text-center text-xl font-bold">
                            {formatCurrency(totalRevenue)}
                          </Typography.Text>
                        </div>
                      </div>

                      <div className="col-span-5 flex flex-col justify-center ">
                        {Object.values(revenueByAuthor?.overview || {}).map(
                          (item) => (
                            <DescriptionDonut
                              title={item.title}
                              bgColor={item.bgColor}
                              revenue={formatCurrency(item.value)}
                              percentage={item.percentage}
                            />
                          )
                        )}
                      </div>
                    </div>
                    <div className="col-span-9 border p-4 rounded">
                      <div className="flex items-start justify-between">
                        <Typography.Text className="font-semibold text-xl">
                          Statistics
                        </Typography.Text>
                      </div>
                      <MultiBarChartComponent
                        isPriceChart
                        data={formatRevenueByAuthorStatistics}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <StyledEmpty />
            )}
          </div>
        )}

        {/* Revenue by teacher package */}

        <div className="mt-8 bg-white rounded px-8 py-4">
          <div>
            <div className="flex justify-between items-center">
              <Typography.Text className="font-semibold text-2xl">
                Revenue by teacher package
              </Typography.Text>
              <ShareDateSelect
                type="year"
                placeholder="To"
                format={'YYYY'}
                onChange={(date, dateString) => {
                  setSelectedYearTeacherPackageRevenue(dateString)
                }}
                maxDate={new Date()}
                defaultValue={moment(selectedYearTeacherPackageRevenue, 'YYYY')}
                allowClear={false}
              />
            </div>

            {!isLoadingTeacherPackageRevenue ? (
              <>
                {totalRevenueTeacherPackage &&
                totalRevenueTeacherPackage > 0 ? (
                  <div className=" mt-10 grid grid-cols-12 gap-8">
                    <div className="col-span-3">
                      <div className="mt-3 relative">
                        <PieChartComponent
                          isPriceChart
                          data={formatTeacherPackageRevenue}
                          centerData={totalRevenueTeacherPackage}
                        />
                        <div className="flex flex-col items-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                          <Typography.Text className="text-center text-xl">
                            Total revenue
                          </Typography.Text>
                          <Typography.Text className="text-center text-xl font-bold">
                            {formatCurrency(totalRevenueTeacherPackage)}
                          </Typography.Text>
                        </div>
                      </div>

                      <div className="col-span-5 flex flex-col justify-center ">
                        {Object.values(
                          teacherPackageRevenue?.overview || {}
                        ).map((item: any) => (
                          <DescriptionDonut
                            title={item.title}
                            bgColor={item.bgColor}
                            revenue={formatCurrency(item.value)}
                            percentage={item.percentage}
                          />
                        ))}
                      </div>
                    </div>

                    <div className="col-span-9 border p-4 rounded">
                      <MultiLineChartComponent
                        isPriceChart
                        data={formatTeacherPackageRevenueStatistics}
                      />
                    </div>
                  </div>
                ) : (
                  <StyledEmpty />
                )}
              </>
            ) : (
              <Skeleton paragraph={{ rows: 10 }} />
            )}
          </div>
        </div>

        {/* Withdrawal amount */}

        <div className="mt-8 bg-white rounded px-8 py-4 mb-4">
          <div className="flex justify-between items-center">
            <Typography.Text className="font-semibold text-2xl">
              Withdrawal amount
            </Typography.Text>
            <ShareDateSelect
              type="year"
              placeholder="To"
              format={'YYYY'}
              onChange={(date, dateString) => {
                setSelectedYearWithdrawalAmount(dateString)
              }}
              maxDate={new Date()}
              defaultValue={moment(selectedYearWithdrawalAmount, 'YYYY')}
              allowClear={false}
            />
          </div>

          <div className=" mt-10 grid grid-cols-12 gap-8">
            <div className="col-span-12 p-4 rounded">
              {isFormatWithdrawalAmountHasData ? (
                <MultiBarChartComponent
                  isPriceChart
                  data={formatWithdrawalAmount}
                />
              ) : (
                <StyledEmpty />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
