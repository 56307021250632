import { createAsyncThunk } from '@reduxjs/toolkit'

import { notificationAPI } from '@api'
import { INITIAL_PAGINATION_SiZE } from '@configs'
import { IGetNotificationListRequestParam } from '@interfaces'

export const getListNotificationsAction = createAsyncThunk(
  'notification/getListNotificationsAction',
  async (params?: IGetNotificationListRequestParam) => {
    try {
      const localParams = params ?? {
        page: 1,
        limit: INITIAL_PAGINATION_SiZE,
      }
      const res = await notificationAPI.getListNotifications(localParams)
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const getNotificationUnreadAmountAction = createAsyncThunk(
  'notification/getNotificationUnreadAmountAction',
  async () => {
    try {
      const res = await notificationAPI.getNotificationUnreadAmount()
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const markNotificationAsReadByIdAction = createAsyncThunk(
  'notification/markNotificationAsReadByIdAction',
  async (notificationId: number) => {
    try {
      const res = await notificationAPI.markNotificationAsReadById(
        notificationId
      )
      return res.data
    } catch (error) {
      throw error
    }
  }
)

export const markAllNotificationsAsReadAction = createAsyncThunk(
  'notification/markAllNotificationsAsReadAction',
  async () => {
    try {
      const res = await notificationAPI.markAllNotificationsAsRead()
      return res.data
    } catch (error) {
      throw error
    }
  }
)
