import styled from 'styled-components'

export const SharedStyledTableWrapper = styled.div`
  .ant-table-wrapper {
    margin: 0 !important;
    margin-top: 16px !important;
  }

  .ant-table-content {
    margin-right: 0 !important;
  }

  // sticky header
  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 50;
  }
`
