import {
  PieChartComponent,
  MultiBarChartComponent,
  ShareDateSelect,
  StyledEmpty,
} from '@components'
import { IPackage, ITeacherGrowthItem } from '@interfaces'
import { DescriptionDonut } from '@pages'
import { getTeacherGrowth, getTeacherOverview, useAppDispatch } from '@redux'
import { themes } from '@theme'
import { formatDate, getCurrentYear } from '@utils'
import { Card, Skeleton, Typography } from 'antd'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'

type TeacherInsightProps = {
  activeTab: string
}

export const TeacherInsight = (props: TeacherInsightProps) => {
  const dispatch = useAppDispatch()
  const [isLoadingTeacherOverview, setIsLoadingTeacherOverview] =
    useState<boolean>(false)

  const [isTeacherGrowthLoading, setIsTeacherGrowthLoading] =
    useState<boolean>(false)
  const [selectedYear, setSelectedYear] = useState<string>(getCurrentYear())
  const [teacherGrowth, setTeacherGrowth] = useState<ITeacherGrowthItem[]>([])

  const [teacherOverview, setTeacherOverview] = useState<IPackage[]>([])
  const [teacherOverviewTotalCourse, setTeacherOverviewTotalCourse] =
    useState<number>(0)

  const fetchTeacherOverview = async () => {
    setIsLoadingTeacherOverview(true)
    const response = await dispatch(getTeacherOverview()).unwrap()
    setIsLoadingTeacherOverview(false)
    if (response) {
      setTeacherOverviewTotalCourse(response.total)
      if (response.total > 0) {
        const formatTeacherOverviewData = Object.values(response.packages).map(
          (item: any) => {
            return {
              title: item.title,
              bgColor: item.bgColor,
              value: item.value,
              percentage: item.percentage,
            }
          }
        )
        setTeacherOverview(formatTeacherOverviewData)
      }
    }
  }

  const fetchTeacherGrowth = async () => {
    setIsTeacherGrowthLoading(true)
    const response = await dispatch(
      getTeacherGrowth({ year: selectedYear })
    ).unwrap()
    setIsTeacherGrowthLoading(false)
    if (response) {
      setTeacherGrowth(response)
    }
  }

  useEffect(() => {
    fetchTeacherGrowth()
  }, [selectedYear])

  useEffect(() => {
    fetchTeacherOverview()
  }, [])

  const formatTeacherOverviewData = useMemo(() => {
    return {
      labels: teacherOverview.map((item) => item.title),
      datasets: [
        {
          data: teacherOverview.map((item) => item.value),
          backgroundColor: teacherOverview.map((item) => item.bgColor),
          borderWidth: 1,
        },
      ],
    }
  }, [teacherOverview])

  const formatDataTeacherGrowth = useMemo(() => {
    return {
      labels: teacherGrowth.map((item) => formatDate(item.month, 'MM/YYYY')),
      datasets: [
        {
          label: 'Teacher growth',
          data: teacherGrowth.map((item) => item.value),
          backgroundColor: themes.theme.dark.colors.miniDashboard.darkBlue,
          borderWidth: 1,
          barThickness: 40,
        },
      ],
    }
  }, [teacherGrowth])

  const isFormatDataTeacherGrowthHasData = useMemo(() => {
    return teacherGrowth.some((item) => item.value > 0)
  }, [teacherGrowth])

  return (
    <div>
      
      <div>
        <div className=" grid grid-cols-12 gap-8 bg-white rounded px-8 py-4">
          <div className="col-span-6">
            <Typography.Text className="font-semibold text-2xl">
              Teacher subscription{' '}
            </Typography.Text>

            {isLoadingTeacherOverview ? (
              <Skeleton paragraph={{ rows: 8 }} />
            ) : (
              <div className="grid grid-cols-12 gap-8 mt-6">
                <div className="col-span-7 relative">
                  <PieChartComponent
                    data={formatTeacherOverviewData}
                    centerData={teacherOverviewTotalCourse}
                  />
                  <div className="flex flex-col items-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <Typography.Text className="text-center text-xl">
                      Total teacher
                    </Typography.Text>
                    <Typography.Text className="text-center text-xl font-bold">
                      {teacherOverviewTotalCourse}
                    </Typography.Text>
                  </div>
                </div>
                <div className="col-span-5 flex flex-col justify-center ">
                  {teacherOverview.map((item) => (
                    <DescriptionDonut
                      title={item.title}
                      bgColor={item.bgColor}
                      revenue={item.value}
                      percentage={item.percentage}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className=" mt-8 bg-white rounded px-8 py-4 mb-4">
        <div className="flex items-start justify-between p-4">
          <Typography.Text className="font-semibold text-2xl">
            Teacher growth
          </Typography.Text>
          <ShareDateSelect
            type="year"
            placeholder="To"
            format={'YYYY'}
            onChange={(date, dateString) => {
              setSelectedYear(dateString)
            }}
            maxDate={new Date()}
            defaultValue={moment(selectedYear, 'YYYY')}
            allowClear={false}
          />
        </div>

        {isTeacherGrowthLoading ? (
          <Skeleton paragraph={{ rows: 8 }} />
        ) : (
          <div>
            {!isFormatDataTeacherGrowthHasData ? (
              <StyledEmpty />
            ) : (
              <MultiBarChartComponent data={formatDataTeacherGrowth} />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
