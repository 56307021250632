import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  BookOutlined,
  FileExclamationOutlined,
  ExceptionOutlined,
  FileDoneOutlined,
} from '@ant-design/icons'

import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { EMiniDashBoardColor } from 'src/pages/course-request-management/config'
import Skeleton from 'src/components/skeleton/Skeleton'
import { ECourseMiniDashboardKey } from './configs'
import { getCoursesMiniDashboardAction } from 'src/redux/actions/course'

export const CourseManagementMiniDashboard = ({
  onClick,
}: {
  onClick: (key: ECourseMiniDashboardKey) => void
}) => {
  const { t } = useTranslation(['teacher'])
  const dispatch = useAppDispatch()

  const { courseMiniDashboard, loadings } = useAppSelector(
    (state: RootState) => state.course
  )

  const isGettingMiniDashboard = loadings['getCoursesMiniDashboardAction']

  const renderData = useMemo(() => {
    if (!Object?.keys(courseMiniDashboard)?.length) return []

    return [
      {
        title: t('teacher:total_course'),
        value: courseMiniDashboard?.totalCourses,
        bgColor: EMiniDashBoardColor.LIGHT_YELLOW,
        logo: <BookOutlined className="text-[28px] text-black" />,
        onClick: () => onClick(ECourseMiniDashboardKey.TOTAL_COURSE),
      },
      {
        title: t('teacher:total_published_active_course'),
        value: courseMiniDashboard?.totalPublishedCourses,
        bgColor: EMiniDashBoardColor.LIGHT_BLUE,
        logo: <FileDoneOutlined className="text-[28px] text-black" />,
        onClick: () =>
          onClick(ECourseMiniDashboardKey.TOTAL_PUBLISHED_ACTIVE_COURSE),
      },
      {
        title: t('teacher:total_inactive_published_course'),
        value: courseMiniDashboard?.totalInactiveCourses,
        bgColor: EMiniDashBoardColor.LIGHT_PINK,
        logo: <ExceptionOutlined className="text-[28px] text-black" />,
        onClick: () =>
          onClick(ECourseMiniDashboardKey.TOTAL_INACTIVE_PUBLISHED_COURSE),
      },
      {
        title: t('teacher:total_inactive_published_request'),
        value: courseMiniDashboard?.totalInactiveAndActiveRequests,
        bgColor: EMiniDashBoardColor.LIGHT_PURPLE,
        logo: <FileExclamationOutlined className="text-[28px] text-black" />,
        onClick: () =>
          onClick(ECourseMiniDashboardKey.TOTAL_INACTIVE_PUBLISHED_REQUEST),
      },
    ]
  }, [courseMiniDashboard, onClick, t])

  useEffect(() => {
    dispatch(getCoursesMiniDashboardAction())
  }, [dispatch])

  return (
    <MiniDashboardContainer>
      {isGettingMiniDashboard ? (
        <Skeleton row={1} column={4} style={{ height: 120 }} />
      ) : (
        <>
          {renderData?.map((item, index) => (
            <DashboardTag key={`${item.title}.${index}`} tag={item} />
          ))}
        </>
      )}
    </MiniDashboardContainer>
  )
}

const DashboardTag = ({
  tag,
}: {
  tag: {
    bgColor?: string
    logo: React.ReactNode
    title: string
    value: number
    onClick?: () => void
  }
}) => {
  const { bgColor, logo, title, value, onClick } = tag || {}
  return (
    <DashboardTagContainer
      $bgColor={bgColor}
      $clickAble={!!onClick}
      onClick={onClick}
    >
      <TitleWrapper>
        {logo}
        <Typography.Text className="text-[16px] text-black font-semibold">
          {title}
        </Typography.Text>
      </TitleWrapper>
      <Typography.Text className="text-[32px] text-black font-semibold mt-4">
        {value}
      </Typography.Text>
    </DashboardTagContainer>
  )
}

const MiniDashboardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  margin-bottom: 24px;
  /* justify-content: space-between; */
  gap: 32px;
`

const DashboardTagContainer = styled.div<{
  $bgColor?: string
  $clickAble?: boolean
}>`
  width: max-content;
  min-width: 320px;
  min-height: 110px;
  padding: 16px;
  border-radius: 6px;
  background-color: ${(props) => (!!props.$bgColor ? props.$bgColor : '#fff')};
  cursor: ${(props) => (props.$clickAble ? 'pointer' : 'default')};
`

const TitleWrapper = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
`
