import React from 'react'

import { WhyUsTable } from './why-us/WhyUsTable'

type TeacherWhyUsProps = {
  activeTab: string
}

export const TeacherWhyUs = (props: TeacherWhyUsProps) => {
  return (
    <div>
      <WhyUsTable {...props} />
    </div>
  )
}
