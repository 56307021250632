import { zodResolver } from '@hookform/resolvers/zod'
import { RootState, useAppDispatch, useAppSelector } from '@redux'
import { Skeleton, Tabs, message } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { EBlogNewsTabs } from '@configs'
import { Button, Input, Text } from 'src/common'
import { IBlogSeoData } from 'src/interfaces/content-management'
import {
  createUpdateBlogListContentAction,
  getBlogListContentAction,
} from 'src/redux/actions/content-management'
import styled from 'styled-components'
import MetaTab from './content/MetaTab'

type Props = {}

const BlogContent = (props: Props) => {
  const dispatch = useAppDispatch()
  const [isUploading, setIsUploading] = useState(false)
  const [activeTab, setActiveTab] = useState<string>(EBlogNewsTabs.CONTENT)

  const getCurrenBlogListContentLoading = useAppSelector(
    (state: RootState) =>
      state?.content?.loadings?.GET_BLOG_LIST_CONTENT_LOADING
  )

  const saveBlogListContentLoading = useAppSelector(
    (state: RootState) =>
      state?.content?.loadings?.CREATE_UPDATE_BLOG_LIST_CONTENT_LOADING
  )

  const currentBlogListContent = useAppSelector(
    (state: RootState) => state?.content?.blogContent
  )

  const blogListSeoSchema = z.object({
    description: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:description_blog_list_length_error') as string,
      }),
    descriptionInDutch: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:description_blog_list_length_error') as string,
      }),
    metaTitle: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(100, {
        message: t('error:metaTitle_length_error') as string,
      }),
    metaDescription: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaDescription_length_error') as string,
      }),
    canonical: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:canonical_length_error') as string,
      }),
    schemaMarkup: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaDescription_length_error') as string,
      }),
    metaImage: z
      .string()
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      }),
    keywords: z
      .string({
        invalid_type_error: t('error:field_required') as string,
      })
      .trim()
      .nonempty({
        message: t('error:field_required') as string,
      })
      .max(255, {
        message: t('error:metaKeyword_length_error') as string,
      }),
  })

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    clearErrors,
    setError,
    getValues,
    trigger,
    reset,
  } = useForm<IBlogSeoData>({
    defaultValues: {
      description: currentBlogListContent?.description || '',
      descriptionInDutch: currentBlogListContent?.descriptionInDutch || '',
      metaTitle: currentBlogListContent?.metaTitle || '',
      metaDescription: currentBlogListContent?.metaDescription || '',
      metaImage: currentBlogListContent?.metaImage || '',
      canonical: currentBlogListContent?.canonical || '',
      schemaMarkup: currentBlogListContent?.schemaMarkup || 'schema mark up',
      keywords: currentBlogListContent?.keywords || '',
    },
    resolver: zodResolver(blogListSeoSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const onCancel = () => {
    reset({
      description: currentBlogListContent?.description || '',
      descriptionInDutch: currentBlogListContent?.descriptionInDutch || '',
      metaTitle: currentBlogListContent?.metaTitle || '',
      metaDescription: currentBlogListContent?.metaDescription || '',
      metaImage: currentBlogListContent?.metaImage || '',
      canonical: currentBlogListContent?.canonical || '',
      schemaMarkup: 'schema mark up',
      keywords: currentBlogListContent?.keywords || '',
    })
  }
  // Check if there is any error in each tab
  const isContentTabError = useMemo(() => {
    return !!errors?.description || !!errors?.description?.type
  }, [errors?.description])

  const isMetaTabError = useMemo(() => {
    return (
      !!errors?.metaTitle ||
      !!errors?.metaDescription ||
      !!errors?.keywords ||
      !!errors?.metaImage ||
      !!errors?.canonical
    )
  }, [
    errors?.metaTitle,
    errors?.metaDescription,
    errors?.keywords,
    errors?.metaImage,
    errors?.canonical,
  ])

  const tabItems = [
    {
      label: (
        <Text
          className="blog-content-label"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: `${isContentTabError ? '#B91C1C' : '#009BB7'}`,
          }}
        >
          <div className="label-wrapper-content">{EBlogNewsTabs.CONTENT}</div>
        </Text>
      ),
      key: EBlogNewsTabs.CONTENT,
      children: (
        <div className="wrapper-content-tab bg-white p-5">
          <div className="px-5">
            <Controller
              name="description"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Description"
                    name="description"
                    required
                    onChange={onChange}
                    value={value}
                    style={{ marginTop: 10 }}
                    errors={error?.message}
                  />
                )
              }}
            />
          </div>
          <div className="px-5">
            <Controller
              name="descriptionInDutch"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Description in Netherlands"
                    name="descriptionInDutch"
                    required
                    onChange={onChange}
                    value={value}
                    style={{ marginTop: 10 }}
                    errors={error?.message}
                  />
                )
              }}
            />
          </div>
        </div>
      ),
    },
    {
      label: (
        <Text
          className="blog-meta-label"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: `${isMetaTabError ? '#B91C1C' : '#009BB7'}`,
            // padding: '16px 0',
          }}
        >
          <div className="label-wrapper-content">{EBlogNewsTabs.META}</div>
        </Text>
      ),
      key: EBlogNewsTabs.META,
      children: (
        <MetaTab
          // activeTab={activeTab}
          control={control}
          getValues={getValues}
          setValue={setValue}
          setIsUploading={setIsUploading}
          isShowSelectMedia
          // category={EMediaCategory.HOME_PAGE}
        />
      ),
    },
  ]

  const onSave = () => {
    handleSubmit(async (data) => {
      try {
        const response = await dispatch(
          createUpdateBlogListContentAction(data)
        ).unwrap()
        if (response?.success) {
          message.success(response?.message ?? '')
        }
      } catch (error: any) {
        console.log(error)
      }
    })()
  }

  useEffect(() => {
    const getBlogListContent = async () => {
      try {
        const respone = await dispatch(getBlogListContentAction()).unwrap()
        if (respone?.success) {
          setValue('description', respone?.data?.description)
          setValue('descriptionInDutch', respone?.data?.descriptionInDutch)
          setValue('metaTitle', respone?.data?.metaTitle)
          setValue('metaDescription', respone?.data?.metaDescription)
          setValue('metaImage', respone?.data?.metaImage)
          setValue('canonical', respone?.data?.canonical)
          // setValue('schemaMarkup', respone?.data?.schemaMarkup)
          setValue('keywords', respone?.data?.keywords)
        }
      } catch (error: any) {
        console.log(error)
      }
    }

    getBlogListContent()
  }, [])

  return (
    <div className="">
      {getCurrenBlogListContentLoading ? (
        <>
          <Skeleton paragraph={{ rows: 4 }} />
        </>
      ) : (
        <div>
          <form>
            <BlogListPageStyled className="blog-content-m-left intro-y col-span-12 lg:col-span-8">
              <div className="intro-y box mt-5 overflow-hidden ">
                <Tabs
                  destroyInactiveTabPane={true}
                  defaultActiveKey={EBlogNewsTabs.CONTENT}
                  type="card"
                  size={'small'}
                  items={tabItems}
                  activeKey={activeTab}
                  onChange={(e) => {
                    setActiveTab(e)
                  }}
                />
              </div>

              <div className="flex items-center mr-5 pb-5 mt-5 justify-end gap-6">
                {/* <Button
                  type="default"
                  size="middle"
                  className="submit__btn login-btn mr-2"
                  onClick={onCancel}
                >
                  Reset
                </Button> */}
                <Button
                  type="primary"
                  size="middle"
                  className="submit__btn login-btn"
                  loading={saveBlogListContentLoading}
                  onClick={onSave}
                >
                  Save
                </Button>
              </div>
            </BlogListPageStyled>
          </form>
        </div>
      )}
    </div>
  )
}

export default BlogContent

const BlogListPageStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },
  }
})
