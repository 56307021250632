import { CONTENT_MANAGEMENT_VALIDATION } from '@configs'
import { t } from 'i18next'
import { ICourse } from 'src/interfaces/course-request-management'
import * as z from 'zod'

export enum EPopupManagementTable {
  NO = 'No',
  POPUP_NAME = 'Popup Name',
  PUBLISHED_DATE = 'Published Date',
  STATUS = 'Status',
  STATE = 'State',
  EDIT = 'Edit',
  DELETE = 'Delete',
}

export const PopupSchema = z.object({
  name: z
    .string()
    .nonempty({ message: t('error:field_required') as string })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  nameInDutch: z
    .string()
    .nonempty({ message: t('error:field_required') as string })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE, {
      message: t('error:max_length_100') as string,
    }),
  description: z
    .string()
    .trim()
    .min(CONTENT_MANAGEMENT_VALIDATION.MIN_LENGTH_DESCRIPTION, {
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_DESCRIPTION, {
      message: t('error:max_length_500') as string,
    }),
  descriptionInDutch: z
    .string()
    .trim()
    .min(CONTENT_MANAGEMENT_VALIDATION.MIN_LENGTH_DESCRIPTION, {
      message: t('error:field_required') as string,
    })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_DESCRIPTION, {
      message: t('error:max_length_500') as string,
    }),
  title: z
    .string()
    .nonempty({ message: t('error:field_required') as string })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE_POPUP, {
      message: t('error:max_length_50') as string,
    }),
  titleInDutch: z
    .string()
    .nonempty({ message: t('error:field_required') as string })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_TITLE_POPUP, {
      message: t('error:max_length_50') as string,
    }),
  photoId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
  mobilePhotoId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
  ctaButtonName: z
    .string()
    .nonempty({ message: t('error:field_required') as string })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_CTA_BUTTON_POPUP, {
      message: t('error:max_length_20') as string,
    }),
  ctaButtonNameInDutch: z
    .string()
    .nonempty({ message: t('error:field_required') as string })
    .max(CONTENT_MANAGEMENT_VALIDATION.MAX_LENGTH_CTA_BUTTON_POPUP, {
      message: t('error:max_length_20') as string,
    }),
  courseId: z.number().min(1, {
    message: t('error:field_required') as string,
  }),
  type: z.string().nonempty({ message: t('error:field_required') as string }),
})

export const DEFAULT_CREATE_POPUP_VALUES = {
  name: '',
  nameInDutch: '',
  description: '',
  descriptionInDutch: '',
  title: '',
  titleInDutch: '',
  photoId: 0,
  mobilePhotoId: 0,
  ctaButtonName: '',
  ctaButtonNameInDutch: '',
  courseId: 0,
  type: 'COURSE',
}

export enum EPopupState {
  DRAFT = 'Draft',
  SCHEDULED = 'Scheduled',
  PUBLISHED = 'Published',
}

export enum EPopupStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export const LIST_POPUP_STATUS = [
  {
    label: 'All status',
    value: '',
  },
  {
    label: 'Active',
    value: 'true',
  },
  {
    label: 'Inactive',
    value: 'false',
  },
]

export const LIST_POPUP_STATE = [
  {
    label: 'All state',
    value: '',
  },
  {
    label: 'Draft',
    value: EPopupState.DRAFT,
  },
  {
    label: 'Scheduled',
    value: EPopupState.SCHEDULED,
  },
  {
    label: 'Published',
    value: EPopupState.PUBLISHED,
  },
]

export enum EPopupDisplayWeb {
  HOMEPAGE = 'HOMEPAGE',
  MEMBERSHIP_PAGE = 'MEMBERSHIP_PAGE',
  ACADEMY_PAGE = 'ACADEMY_PAGE',
  ABOUT_US_PAGE = 'ABOUT_US_PAGE',
  BLOG_PAGE = 'BLOG_PAGE',
  TEACH_WITH_US_PAGE = 'TEACH_WITH_US_PAGE',
}

export enum EPopupDisplayMobile {
  SOCIAL_SCREEN = 'SOCIAL_SCREEN',
  ACADEMY_SCREEN = 'ACADEMY_SCREEN',
  EVOLVE_SCREEN = 'EVOLVE_SCREEN',
}

export const LIST_WEB_SCREEN_POPUP_DISPLAY = [
  {
    label: 'Homepage',
    value: EPopupDisplayWeb.HOMEPAGE,
  },
  {
    label: 'Membership Page',
    value: EPopupDisplayWeb.MEMBERSHIP_PAGE,
  },
  {
    label: 'Academy Page',
    value: EPopupDisplayWeb.ACADEMY_PAGE,
  },
  {
    label: 'About Us Page',
    value: EPopupDisplayWeb.ABOUT_US_PAGE,
  },
  {
    label: 'Blog Page',
    value: EPopupDisplayWeb.BLOG_PAGE,
  },
  {
    label: 'Teach With Us Page',
    value: EPopupDisplayWeb.TEACH_WITH_US_PAGE,
  },
]

export const LIST_MOBILE_SCREEN_POPUP_DISPLAY = [
  {
    label: 'Social Screen',
    value: EPopupDisplayMobile.SOCIAL_SCREEN,
  },
  {
    label: 'Academy Screen',
    value: EPopupDisplayMobile.ACADEMY_SCREEN,
  },
  {
    label: 'Evolve Screen',
    value: EPopupDisplayMobile.EVOLVE_SCREEN,
  },
]

export const LIST_POPUP_TYPE = [
  {
    value: 'COURSE',
    label: 'Course',
  },
]

export enum EPopupScreenType {
  STEP1 = 0,
  STEP2,
}

export type TPopupType = {
  name?: string
  nameInDutch?: string
  title?: string
  titleInDutch?: string
  description?: string
  descriptionInDutch?: string
  ctaButtonName?: string
  ctaButtonNameInDutch?: string
  type?: string | number
  photoId?: number
  mobilePhotoId?: number
  courseId?: number
  isDisplayAllPage?: boolean
  displayOnWeb?: string[]
  displayOnMobile?: string[]
  scheduleDate?: string | null
  state?: string
  photoUrl?: string
  mobilePhotoUrl?: string
  isActive?: boolean
  course?: ICourse
}

export type TPopupBody = {
  name: string
  nameInDutch: string
  title: string
  titleInDutch: string
  description: string
  descriptionInDutch: string
  ctaButtonName: string
  ctaButtonNameInDutch: string
  type: string | number
  photoId: number
  mobilePhotoId: number
  courseId: number
  isDisplayAllPage?: boolean
  displayOnWeb?: string[]
  displayOnMobile?: string[]
  scheduleDate: string | null
  isActive?: boolean
  state?: string
}

export interface IPopupDetailType {
  id: number
  name: string
  nameInDutch: string
  title: string
  titleInDutch: string
  type: string
  description: string
  descriptionInDutch: string
  photo: Photo
  mobilePhoto: MobilePhoto
  ctaButtonName: string
  ctaButtonNameInDutch: string
  publishedAt: string
  scheduleDate: any
  isActive: boolean
  state: string
  courseId: number
  isDisplayAllPage: boolean
  displayOnWeb: any[]
  displayOnMobile: any[]
  createdAt: string
  updatedAt: string
  course: Course
}

export interface Photo {
  id: number
  url: string
  name: string
  path: string
  type: string
  bucket: string
  userId: number
  baseUrl: string
  category: string
  createdAt: string
  signedUrl: string
  updatedAt: string
  cloudfrontUrl: string
}

export interface MobilePhoto {
  id: number
  url: string
  name: string
  path: string
  type: string
  bucket: string
  userId: number
  baseUrl: string
  category: string
  createdAt: string
  signedUrl: string
  updatedAt: string
  cloudfrontUrl: string
}

export interface Course {
  isRated: boolean
  isSaved: boolean
  enrolled: number
  id: number
  userId: number
  title: string
  titleInDutch: string
  description: string
  descriptionInDutch: string
  type: string
  eurPrice: number
  usdPrice: number
  difficulty: string
  isActived: boolean
  isDrafted: boolean
  isTrial: boolean
  isHighlighted: boolean
  hasFinalTest: boolean
  targetUser: boolean
  userCreationFrom: any
  userCreationTo: any
  demoVideo: DemoVideo
  thumbnail: Thumbnail
  thumbnailId: number
  demoVideoId: number
  hasCertificated: any
  isBestSeller: boolean
  publishedAt: string
  averageRating: any
  totalReviews: number
  duration: number
  nationalLanguage: string
  subTitle: any
  finalSectionId: any
  createdAt: string
  updatedAt: string
  deletedAt: any
  final_section_id: any
}

export interface DemoVideo {
  id: number
  url: string
  name: string
  path: string
  type: string
  bucket: string
  userId: number
  baseUrl: string
  category: string
  createdAt: string
  signedUrl: string
  updatedAt: string
  cloudfrontUrl: string
}

export interface Thumbnail {
  id: number
  url: string
  name: string
  path: string
  type: string
  bucket: string
  userId: number
  baseUrl: string
  category: string
  createdAt: string
  signedUrl: string
  updatedAt: string
  cloudfrontUrl: string
}
