import { ETeacherLandingPageTabs } from '@configs'
import { Tabs } from 'antd'
import { useState } from 'react'
import { Text } from 'src/common'
import {
  TeacherBanner,
  TeacherBecomeInstructor,
  TeacherFAQ,
  TeacherHowTo,
  TeacherPricing,
  TeacherSEOConfigure,
  TeacherVideoIntro,
  TeacherWhyUs,
} from '@pages'
import styled from 'styled-components'

interface ITeacherLandingPageManagementProps {}

export const TeacherLandingPageManagement =
  ({}: ITeacherLandingPageManagementProps) => {
    const [activeTab, setActiveTab] = useState<string>(
      ETeacherLandingPageTabs.TEACHER_BANNER
    )

    const tabItems = [
      {
        label: <Text>{ETeacherLandingPageTabs.TEACHER_BANNER}</Text>,
        key: ETeacherLandingPageTabs.TEACHER_BANNER,
        children: <TeacherBanner activeTab={activeTab} />,
      },
      {
        label: <Text>{ETeacherLandingPageTabs.TEACHER_WHY_US}</Text>,
        key: ETeacherLandingPageTabs.TEACHER_WHY_US,
        children: <TeacherWhyUs activeTab={activeTab} />,
      },
      {
        label: <Text>{ETeacherLandingPageTabs.TEACHER_VIDEO_INTRO}</Text>,
        key: ETeacherLandingPageTabs.TEACHER_VIDEO_INTRO,
        children: <TeacherVideoIntro activeTab={activeTab} />,
      },
      {
        label: <Text>{ETeacherLandingPageTabs.TEACHER_HOW_TO}</Text>,
        key: ETeacherLandingPageTabs.TEACHER_HOW_TO,
        children: <TeacherHowTo activeTab={activeTab} />,
      },
      {
        label: <Text>{ETeacherLandingPageTabs.TEACHER_PRICING}</Text>,
        key: ETeacherLandingPageTabs.TEACHER_PRICING,
        children: <TeacherPricing activeTab={activeTab} />,
      },

      {
        label: <Text>{ETeacherLandingPageTabs.TEACHER_BECOME_INSTRUCTOR}</Text>,
        key: ETeacherLandingPageTabs.TEACHER_BECOME_INSTRUCTOR,
        children: <TeacherBecomeInstructor activeTab={activeTab} />,
      },

      {
        label: <Text>{ETeacherLandingPageTabs.TEACHER_FAQ}</Text>,
        key: ETeacherLandingPageTabs.TEACHER_FAQ,
        children: <TeacherFAQ activeTab={activeTab} />,
      },

      {
        label: <Text>{ETeacherLandingPageTabs.TEACHER_SEO}</Text>,
        key: ETeacherLandingPageTabs.TEACHER_SEO,
        children: <TeacherSEOConfigure activeTab={activeTab} />,
      },
    ]

    return (
      <AboutUsPageStyled>
        <Tabs
          destroyInactiveTabPane={true}
          defaultActiveKey={ETeacherLandingPageTabs.TEACHER_BANNER}
          type="card"
          size={'small'}
          items={tabItems}
          defaultChecked={true}
          activeKey={activeTab}
          onChange={(e) => {
            setActiveTab(e)
          }}
        />
      </AboutUsPageStyled>
    )
  }

const AboutUsPageStyled = styled('div')(() => {
  return {
    '.ant-tabs-nav': {
      marginBottom: 0,
      paddingLeft: '1.25rem',

      '& .ant-tabs-tab': {
        borderRadius: '0.75rem 0.75rem 0 0 !important',

        '&.ant-tabs-tab-active': {
          span: {
            color: '#009BB7',
            fontWeight: '500',
          },
        },
      },
    },
  }
})
