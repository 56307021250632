import { ApiClient } from 'src/api/axiosClient'
import {
  ICommonContentItem,
  IHowToContentInput,
  IReasonDetailInputParams,
  IVideoIntroInput,
  IWhyUsContentInputParams,
  IWhyUsContentItem,
  IWhyUsTableItem,
} from '@interfaces'
import { ILandingFormData } from 'src/interfaces/aboutus-management'

export const teacherLandingAPI = {
  getBanner: async () => {
    return await ApiClient.get<ICommonContentItem>(
      '/common-contents/academy-landing'
    )
  },

  createEditBanner: async (payload: ILandingFormData) => {
    return await ApiClient.post<{
      commonContent: ILandingFormData
      message?: string
    }>('/common-contents/academy-landing', payload)
  },

  getWhyUsTable: async () => {
    return await ApiClient.get<IWhyUsTableItem[]>(
      '/common-contents/academy-reason'
    )
  },

  getWhyUsContent: async () => {
    return await ApiClient.get<IWhyUsContentItem>(
      '/common-contents/academy-why-us'
    )
  },

  createEditWhyUsContent: async (params: IWhyUsContentInputParams) => {
    return await ApiClient.post<{
      commonContent: IWhyUsContentItem
      message: string
    }>('/common-contents/academy-why-us', params)
  },

  getReasonDetailById: async (id: number | string) => {
    return await ApiClient.get<ICommonContentItem>(
      `/common-contents/academy-reason/${id}`
    )
  },

  createReasonDetail: async (params: IReasonDetailInputParams) => {
    return await ApiClient.post<{
      commonContent: ICommonContentItem
      message: string
    }>('/common-contents/academy-reason', params)
  },

  editReasonDetailById: async (
    id: number | string,
    params: IReasonDetailInputParams
  ) => {
    return await ApiClient.put<{
      academyReason: ICommonContentItem
      message: string
    }>(`/common-contents/academy-reason/${id}`, params)
  },

  deleteReasonDetailById: async (id: number | string) => {
    return await ApiClient.delete<{ message: string }>(
      `/common-contents/academy-reason/${id}`
    )
  },

  getVideoIntro: async () => {
    return await ApiClient.get<ICommonContentItem>(
      '/common-contents/academy-video-intro'
    )
  },

  createEditVideoIntro: async (params: IVideoIntroInput) => {
    return await ApiClient.post<{
      commonContent: ICommonContentItem
      message: string
    }>('/common-contents/academy-video-intro', params)
  },

  getHowToTable: async () => {
    return await ApiClient.get<ICommonContentItem[]>(
      'common-contents/academy-how-to-detail'
    )
  },

  getHowToContent: async () => {
    return await ApiClient.get<ICommonContentItem>(
      '/common-contents/academy-how-to'
    )
  },

  createEditHowToContent: async (params: IHowToContentInput) => {
    return await ApiClient.post<{
      commonContent: ICommonContentItem
      message: string
    }>('/common-contents/academy-how-to', params)
  },

  getHowToDetailById: async (id: number | string) => {
    return await ApiClient.get<ICommonContentItem>(
      `/common-contents/academy-how-to-detail/${id}`
    )
  },

  createHowToDetail: async (params: IReasonDetailInputParams) => {
    return await ApiClient.post<{
      commonContent: ICommonContentItem
      message: string
    }>('/common-contents/academy-how-to-detail', params)
  },

  editHowToDetailById: async (
    id: number | string,
    params: IReasonDetailInputParams
  ) => {
    return await ApiClient.put<{
      academyHowToDetail: ICommonContentItem
      message: string
    }>(`/common-contents/academy-how-to-detail/${id}`, params)
  },

  deleteHowToDetailById: async (id: number | string) => {
    return await ApiClient.delete<{ message: string }>(
      `/common-contents/academy-how-to-detail/${id}`
    )
  },
}
